/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var models_pb = require('./models_pb.js');
goog.exportSymbol('proto.app.BannerItems', null, global);
goog.exportSymbol('proto.app.Banners', null, global);
goog.exportSymbol('proto.app.EmptyRequest', null, global);
goog.exportSymbol('proto.app.EventBySlugRequest', null, global);
goog.exportSymbol('proto.app.EventListRequest', null, global);
goog.exportSymbol('proto.app.EventListResponse', null, global);
goog.exportSymbol('proto.app.EventRequest', null, global);
goog.exportSymbol('proto.app.EventResponse', null, global);
goog.exportSymbol('proto.app.HomepageResponse', null, global);
goog.exportSymbol('proto.app.MovieBySlugRequest', null, global);
goog.exportSymbol('proto.app.MovieListRequest', null, global);
goog.exportSymbol('proto.app.MovieListResponse', null, global);
goog.exportSymbol('proto.app.MovieRequest', null, global);
goog.exportSymbol('proto.app.MovieResponse', null, global);
goog.exportSymbol('proto.app.RecipeBySlugRequest', null, global);
goog.exportSymbol('proto.app.RecipeListRequest', null, global);
goog.exportSymbol('proto.app.RecipeListResponse', null, global);
goog.exportSymbol('proto.app.RecipeRequest', null, global);
goog.exportSymbol('proto.app.RecipeResponse', null, global);
goog.exportSymbol('proto.app.ReviewsResponse', null, global);
goog.exportSymbol('proto.app.ShowtimeListRequest', null, global);
goog.exportSymbol('proto.app.ShowtimeListResponse', null, global);
goog.exportSymbol('proto.app.TownListResponse', null, global);
goog.exportSymbol('proto.app.VenueBySlugRequest', null, global);
goog.exportSymbol('proto.app.VenueListRequest', null, global);
goog.exportSymbol('proto.app.VenueListResponse', null, global);
goog.exportSymbol('proto.app.VenueRequest', null, global);
goog.exportSymbol('proto.app.VenueResponse', null, global);
goog.exportSymbol('proto.app.VenueTypeListResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EmptyRequest.displayName = 'proto.app.EmptyRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EmptyRequest}
 */
proto.app.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EmptyRequest;
  return proto.app.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EmptyRequest}
 */
proto.app.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.BannerItems = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.BannerItems, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.BannerItems.displayName = 'proto.app.BannerItems';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.BannerItems.prototype.toObject = function(opt_includeInstance) {
  return proto.app.BannerItems.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.BannerItems} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.BannerItems.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.BannerItems}
 */
proto.app.BannerItems.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.BannerItems;
  return proto.app.BannerItems.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.BannerItems} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.BannerItems}
 */
proto.app.BannerItems.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.BannerItems.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.BannerItems.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.BannerItems} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.BannerItems.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Type = 1;
 * @return {string}
 */
proto.app.BannerItems.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.BannerItems.prototype.setType = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ID = 2;
 * @return {string}
 */
proto.app.BannerItems.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.app.BannerItems.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.Banners = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.Banners.repeatedFields_, null);
};
goog.inherits(proto.app.Banners, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.Banners.displayName = 'proto.app.Banners';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.Banners.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.Banners.prototype.toObject = function(opt_includeInstance) {
  return proto.app.Banners.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.Banners} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.Banners.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.app.BannerItems.toObject, includeInstance),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    models_pb.Event.toObject, includeInstance),
    moviesList: jspb.Message.toObjectList(msg.getMoviesList(),
    models_pb.Movie.toObject, includeInstance),
    recipesList: jspb.Message.toObjectList(msg.getRecipesList(),
    models_pb.Recipe.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.Banners}
 */
proto.app.Banners.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.Banners;
  return proto.app.Banners.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.Banners} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.Banners}
 */
proto.app.Banners.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.BannerItems;
      reader.readMessage(value,proto.app.BannerItems.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new models_pb.Event;
      reader.readMessage(value,models_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 3:
      var value = new models_pb.Movie;
      reader.readMessage(value,models_pb.Movie.deserializeBinaryFromReader);
      msg.addMovies(value);
      break;
    case 4:
      var value = new models_pb.Recipe;
      reader.readMessage(value,models_pb.Recipe.deserializeBinaryFromReader);
      msg.addRecipes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.Banners.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.Banners.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.Banners} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.Banners.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.BannerItems.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      models_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      models_pb.Movie.serializeBinaryToWriter
    );
  }
  f = message.getRecipesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      models_pb.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BannerItems Items = 1;
 * @return {!Array<!proto.app.BannerItems>}
 */
proto.app.Banners.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.app.BannerItems>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.BannerItems, 1));
};


/** @param {!Array<!proto.app.BannerItems>} value */
proto.app.Banners.prototype.setItemsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.BannerItems=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.BannerItems}
 */
proto.app.Banners.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.BannerItems, opt_index);
};


proto.app.Banners.prototype.clearItemsList = function() {
  this.setItemsList([]);
};


/**
 * repeated models.Event Events = 2;
 * @return {!Array<!proto.models.Event>}
 */
proto.app.Banners.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Event, 2));
};


/** @param {!Array<!proto.models.Event>} value */
proto.app.Banners.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.app.Banners.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.models.Event, opt_index);
};


proto.app.Banners.prototype.clearEventsList = function() {
  this.setEventsList([]);
};


/**
 * repeated models.Movie Movies = 3;
 * @return {!Array<!proto.models.Movie>}
 */
proto.app.Banners.prototype.getMoviesList = function() {
  return /** @type{!Array<!proto.models.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Movie, 3));
};


/** @param {!Array<!proto.models.Movie>} value */
proto.app.Banners.prototype.setMoviesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.models.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Movie}
 */
proto.app.Banners.prototype.addMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.models.Movie, opt_index);
};


proto.app.Banners.prototype.clearMoviesList = function() {
  this.setMoviesList([]);
};


/**
 * repeated models.Recipe Recipes = 4;
 * @return {!Array<!proto.models.Recipe>}
 */
proto.app.Banners.prototype.getRecipesList = function() {
  return /** @type{!Array<!proto.models.Recipe>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Recipe, 4));
};


/** @param {!Array<!proto.models.Recipe>} value */
proto.app.Banners.prototype.setRecipesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.models.Recipe=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Recipe}
 */
proto.app.Banners.prototype.addRecipes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.models.Recipe, opt_index);
};


proto.app.Banners.prototype.clearRecipesList = function() {
  this.setRecipesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.HomepageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.HomepageResponse.repeatedFields_, null);
};
goog.inherits(proto.app.HomepageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.HomepageResponse.displayName = 'proto.app.HomepageResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.HomepageResponse.repeatedFields_ = [2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.HomepageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.HomepageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.HomepageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.HomepageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    banners: (f = msg.getBanners()) && proto.app.Banners.toObject(includeInstance, f),
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    models_pb.Ads.toObject, includeInstance),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    models_pb.Event.toObject, includeInstance),
    moviesList: jspb.Message.toObjectList(msg.getMoviesList(),
    models_pb.Movie.toObject, includeInstance),
    recipesList: jspb.Message.toObjectList(msg.getRecipesList(),
    models_pb.Recipe.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.HomepageResponse}
 */
proto.app.HomepageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.HomepageResponse;
  return proto.app.HomepageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.HomepageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.HomepageResponse}
 */
proto.app.HomepageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.Banners;
      reader.readMessage(value,proto.app.Banners.deserializeBinaryFromReader);
      msg.setBanners(value);
      break;
    case 2:
      var value = new models_pb.Ads;
      reader.readMessage(value,models_pb.Ads.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    case 3:
      var value = new models_pb.Event;
      reader.readMessage(value,models_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 4:
      var value = new models_pb.Movie;
      reader.readMessage(value,models_pb.Movie.deserializeBinaryFromReader);
      msg.addMovies(value);
      break;
    case 5:
      var value = new models_pb.Recipe;
      reader.readMessage(value,models_pb.Recipe.deserializeBinaryFromReader);
      msg.addRecipes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.HomepageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.HomepageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.HomepageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.HomepageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBanners();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.app.Banners.serializeBinaryToWriter
    );
  }
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      models_pb.Ads.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      models_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      models_pb.Movie.serializeBinaryToWriter
    );
  }
  f = message.getRecipesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      models_pb.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * optional Banners Banners = 1;
 * @return {?proto.app.Banners}
 */
proto.app.HomepageResponse.prototype.getBanners = function() {
  return /** @type{?proto.app.Banners} */ (
    jspb.Message.getWrapperField(this, proto.app.Banners, 1));
};


/** @param {?proto.app.Banners|undefined} value */
proto.app.HomepageResponse.prototype.setBanners = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.HomepageResponse.prototype.clearBanners = function() {
  this.setBanners(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.HomepageResponse.prototype.hasBanners = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated models.Ads Ads = 2;
 * @return {!Array<!proto.models.Ads>}
 */
proto.app.HomepageResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.models.Ads>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Ads, 2));
};


/** @param {!Array<!proto.models.Ads>} value */
proto.app.HomepageResponse.prototype.setAdsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.models.Ads=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Ads}
 */
proto.app.HomepageResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.models.Ads, opt_index);
};


proto.app.HomepageResponse.prototype.clearAdsList = function() {
  this.setAdsList([]);
};


/**
 * repeated models.Event Events = 3;
 * @return {!Array<!proto.models.Event>}
 */
proto.app.HomepageResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Event, 3));
};


/** @param {!Array<!proto.models.Event>} value */
proto.app.HomepageResponse.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.app.HomepageResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.models.Event, opt_index);
};


proto.app.HomepageResponse.prototype.clearEventsList = function() {
  this.setEventsList([]);
};


/**
 * repeated models.Movie Movies = 4;
 * @return {!Array<!proto.models.Movie>}
 */
proto.app.HomepageResponse.prototype.getMoviesList = function() {
  return /** @type{!Array<!proto.models.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Movie, 4));
};


/** @param {!Array<!proto.models.Movie>} value */
proto.app.HomepageResponse.prototype.setMoviesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.models.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Movie}
 */
proto.app.HomepageResponse.prototype.addMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.models.Movie, opt_index);
};


proto.app.HomepageResponse.prototype.clearMoviesList = function() {
  this.setMoviesList([]);
};


/**
 * repeated models.Recipe Recipes = 5;
 * @return {!Array<!proto.models.Recipe>}
 */
proto.app.HomepageResponse.prototype.getRecipesList = function() {
  return /** @type{!Array<!proto.models.Recipe>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Recipe, 5));
};


/** @param {!Array<!proto.models.Recipe>} value */
proto.app.HomepageResponse.prototype.setRecipesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.models.Recipe=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Recipe}
 */
proto.app.HomepageResponse.prototype.addRecipes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.models.Recipe, opt_index);
};


proto.app.HomepageResponse.prototype.clearRecipesList = function() {
  this.setRecipesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EventListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.EventListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EventListRequest.displayName = 'proto.app.EventListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EventListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EventListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EventListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listopts: (f = msg.getListopts()) && models_pb.ListOpts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EventListRequest}
 */
proto.app.EventListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EventListRequest;
  return proto.app.EventListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EventListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EventListRequest}
 */
proto.app.EventListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.ListOpts;
      reader.readMessage(value,models_pb.ListOpts.deserializeBinaryFromReader);
      msg.setListopts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EventListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EventListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EventListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListopts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.ListOpts.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.ListOpts ListOpts = 1;
 * @return {?proto.models.ListOpts}
 */
proto.app.EventListRequest.prototype.getListopts = function() {
  return /** @type{?proto.models.ListOpts} */ (
    jspb.Message.getWrapperField(this, models_pb.ListOpts, 1));
};


/** @param {?proto.models.ListOpts|undefined} value */
proto.app.EventListRequest.prototype.setListopts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.EventListRequest.prototype.clearListopts = function() {
  this.setListopts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.EventListRequest.prototype.hasListopts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EventListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.EventListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.EventListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EventListResponse.displayName = 'proto.app.EventListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.EventListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EventListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EventListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EventListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    models_pb.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EventListResponse}
 */
proto.app.EventListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EventListResponse;
  return proto.app.EventListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EventListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EventListResponse}
 */
proto.app.EventListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Event;
      reader.readMessage(value,models_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EventListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EventListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EventListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Event Events = 1;
 * @return {!Array<!proto.models.Event>}
 */
proto.app.EventListResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Event, 1));
};


/** @param {!Array<!proto.models.Event>} value */
proto.app.EventListResponse.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.app.EventListResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Event, opt_index);
};


proto.app.EventListResponse.prototype.clearEventsList = function() {
  this.setEventsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.MovieListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.MovieListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.MovieListRequest.displayName = 'proto.app.MovieListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.MovieListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.MovieListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.MovieListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listopts: (f = msg.getListopts()) && models_pb.ListOpts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.MovieListRequest}
 */
proto.app.MovieListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.MovieListRequest;
  return proto.app.MovieListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.MovieListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.MovieListRequest}
 */
proto.app.MovieListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.ListOpts;
      reader.readMessage(value,models_pb.ListOpts.deserializeBinaryFromReader);
      msg.setListopts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.MovieListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.MovieListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.MovieListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListopts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.ListOpts.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.ListOpts ListOpts = 1;
 * @return {?proto.models.ListOpts}
 */
proto.app.MovieListRequest.prototype.getListopts = function() {
  return /** @type{?proto.models.ListOpts} */ (
    jspb.Message.getWrapperField(this, models_pb.ListOpts, 1));
};


/** @param {?proto.models.ListOpts|undefined} value */
proto.app.MovieListRequest.prototype.setListopts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.MovieListRequest.prototype.clearListopts = function() {
  this.setListopts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.MovieListRequest.prototype.hasListopts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.MovieListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.MovieListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.MovieListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.MovieListResponse.displayName = 'proto.app.MovieListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.MovieListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.MovieListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.MovieListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.MovieListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    moviesList: jspb.Message.toObjectList(msg.getMoviesList(),
    models_pb.Movie.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.MovieListResponse}
 */
proto.app.MovieListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.MovieListResponse;
  return proto.app.MovieListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.MovieListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.MovieListResponse}
 */
proto.app.MovieListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Movie;
      reader.readMessage(value,models_pb.Movie.deserializeBinaryFromReader);
      msg.addMovies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.MovieListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.MovieListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.MovieListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Movie.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Movie Movies = 1;
 * @return {!Array<!proto.models.Movie>}
 */
proto.app.MovieListResponse.prototype.getMoviesList = function() {
  return /** @type{!Array<!proto.models.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Movie, 1));
};


/** @param {!Array<!proto.models.Movie>} value */
proto.app.MovieListResponse.prototype.setMoviesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Movie}
 */
proto.app.MovieListResponse.prototype.addMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Movie, opt_index);
};


proto.app.MovieListResponse.prototype.clearMoviesList = function() {
  this.setMoviesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.RecipeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.RecipeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.RecipeListRequest.displayName = 'proto.app.RecipeListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.RecipeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.RecipeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.RecipeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listopts: (f = msg.getListopts()) && models_pb.ListOpts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.RecipeListRequest}
 */
proto.app.RecipeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.RecipeListRequest;
  return proto.app.RecipeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.RecipeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.RecipeListRequest}
 */
proto.app.RecipeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.ListOpts;
      reader.readMessage(value,models_pb.ListOpts.deserializeBinaryFromReader);
      msg.setListopts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.RecipeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.RecipeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.RecipeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListopts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.ListOpts.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.ListOpts ListOpts = 1;
 * @return {?proto.models.ListOpts}
 */
proto.app.RecipeListRequest.prototype.getListopts = function() {
  return /** @type{?proto.models.ListOpts} */ (
    jspb.Message.getWrapperField(this, models_pb.ListOpts, 1));
};


/** @param {?proto.models.ListOpts|undefined} value */
proto.app.RecipeListRequest.prototype.setListopts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.RecipeListRequest.prototype.clearListopts = function() {
  this.setListopts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.RecipeListRequest.prototype.hasListopts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.RecipeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.RecipeListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.RecipeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.RecipeListResponse.displayName = 'proto.app.RecipeListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.RecipeListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.RecipeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.RecipeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.RecipeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipesList: jspb.Message.toObjectList(msg.getRecipesList(),
    models_pb.Recipe.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.RecipeListResponse}
 */
proto.app.RecipeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.RecipeListResponse;
  return proto.app.RecipeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.RecipeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.RecipeListResponse}
 */
proto.app.RecipeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Recipe;
      reader.readMessage(value,models_pb.Recipe.deserializeBinaryFromReader);
      msg.addRecipes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.RecipeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.RecipeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.RecipeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Recipe Recipes = 1;
 * @return {!Array<!proto.models.Recipe>}
 */
proto.app.RecipeListResponse.prototype.getRecipesList = function() {
  return /** @type{!Array<!proto.models.Recipe>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Recipe, 1));
};


/** @param {!Array<!proto.models.Recipe>} value */
proto.app.RecipeListResponse.prototype.setRecipesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Recipe=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Recipe}
 */
proto.app.RecipeListResponse.prototype.addRecipes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Recipe, opt_index);
};


proto.app.RecipeListResponse.prototype.clearRecipesList = function() {
  this.setRecipesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.EventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EventRequest.displayName = 'proto.app.EventRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EventRequest}
 */
proto.app.EventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EventRequest;
  return proto.app.EventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EventRequest}
 */
proto.app.EventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.app.EventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.EventRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EventBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.EventBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EventBySlugRequest.displayName = 'proto.app.EventBySlugRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EventBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EventBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EventBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EventBySlugRequest}
 */
proto.app.EventBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EventBySlugRequest;
  return proto.app.EventBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EventBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EventBySlugRequest}
 */
proto.app.EventBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EventBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EventBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EventBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Slug = 1;
 * @return {string}
 */
proto.app.EventBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.EventBySlugRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.EventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.EventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.EventResponse.displayName = 'proto.app.EventResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.EventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.EventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.EventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && models_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.EventResponse}
 */
proto.app.EventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.EventResponse;
  return proto.app.EventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.EventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.EventResponse}
 */
proto.app.EventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Event;
      reader.readMessage(value,models_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.EventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.EventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.EventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.EventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.Event Event = 1;
 * @return {?proto.models.Event}
 */
proto.app.EventResponse.prototype.getEvent = function() {
  return /** @type{?proto.models.Event} */ (
    jspb.Message.getWrapperField(this, models_pb.Event, 1));
};


/** @param {?proto.models.Event|undefined} value */
proto.app.EventResponse.prototype.setEvent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.EventResponse.prototype.clearEvent = function() {
  this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.EventResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.MovieRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.MovieRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.MovieRequest.displayName = 'proto.app.MovieRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.MovieRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.MovieRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.MovieRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.MovieRequest}
 */
proto.app.MovieRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.MovieRequest;
  return proto.app.MovieRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.MovieRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.MovieRequest}
 */
proto.app.MovieRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.MovieRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.MovieRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.MovieRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.app.MovieRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.MovieRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.MovieBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.MovieBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.MovieBySlugRequest.displayName = 'proto.app.MovieBySlugRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.MovieBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.MovieBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.MovieBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.MovieBySlugRequest}
 */
proto.app.MovieBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.MovieBySlugRequest;
  return proto.app.MovieBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.MovieBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.MovieBySlugRequest}
 */
proto.app.MovieBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.MovieBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.MovieBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.MovieBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Slug = 1;
 * @return {string}
 */
proto.app.MovieBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.MovieBySlugRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.MovieResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.MovieResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.MovieResponse.displayName = 'proto.app.MovieResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.MovieResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.MovieResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.MovieResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    movie: (f = msg.getMovie()) && models_pb.Movie.toObject(includeInstance, f),
    showtimesMap: (f = msg.getShowtimesMap()) ? f.toObject(includeInstance, proto.models.Showtime.toObject) : [],
    venuesMap: (f = msg.getVenuesMap()) ? f.toObject(includeInstance, proto.models.Venue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.MovieResponse}
 */
proto.app.MovieResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.MovieResponse;
  return proto.app.MovieResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.MovieResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.MovieResponse}
 */
proto.app.MovieResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Movie;
      reader.readMessage(value,models_pb.Movie.deserializeBinaryFromReader);
      msg.setMovie(value);
      break;
    case 2:
      var value = msg.getShowtimesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.models.Showtime.deserializeBinaryFromReader, "");
         });
      break;
    case 3:
      var value = msg.getVenuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.models.Venue.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.MovieResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.MovieResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.MovieResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.MovieResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovie();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.Movie.serializeBinaryToWriter
    );
  }
  f = message.getShowtimesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.models.Showtime.serializeBinaryToWriter);
  }
  f = message.getVenuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.models.Venue.serializeBinaryToWriter);
  }
};


/**
 * optional models.Movie Movie = 1;
 * @return {?proto.models.Movie}
 */
proto.app.MovieResponse.prototype.getMovie = function() {
  return /** @type{?proto.models.Movie} */ (
    jspb.Message.getWrapperField(this, models_pb.Movie, 1));
};


/** @param {?proto.models.Movie|undefined} value */
proto.app.MovieResponse.prototype.setMovie = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.MovieResponse.prototype.clearMovie = function() {
  this.setMovie(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.MovieResponse.prototype.hasMovie = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, models.Showtime> Showtimes = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.models.Showtime>}
 */
proto.app.MovieResponse.prototype.getShowtimesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.models.Showtime>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.models.Showtime));
};


proto.app.MovieResponse.prototype.clearShowtimesMap = function() {
  this.getShowtimesMap().clear();
};


/**
 * map<string, models.Venue> Venues = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.models.Venue>}
 */
proto.app.MovieResponse.prototype.getVenuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.models.Venue>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.models.Venue));
};


proto.app.MovieResponse.prototype.clearVenuesMap = function() {
  this.getVenuesMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.RecipeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.RecipeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.RecipeRequest.displayName = 'proto.app.RecipeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.RecipeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.RecipeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.RecipeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.RecipeRequest}
 */
proto.app.RecipeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.RecipeRequest;
  return proto.app.RecipeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.RecipeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.RecipeRequest}
 */
proto.app.RecipeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.RecipeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.RecipeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.RecipeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.app.RecipeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.RecipeRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.RecipeBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.RecipeBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.RecipeBySlugRequest.displayName = 'proto.app.RecipeBySlugRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.RecipeBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.RecipeBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.RecipeBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.RecipeBySlugRequest}
 */
proto.app.RecipeBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.RecipeBySlugRequest;
  return proto.app.RecipeBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.RecipeBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.RecipeBySlugRequest}
 */
proto.app.RecipeBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.RecipeBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.RecipeBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.RecipeBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Slug = 1;
 * @return {string}
 */
proto.app.RecipeBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.RecipeBySlugRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.RecipeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.RecipeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.RecipeResponse.displayName = 'proto.app.RecipeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.RecipeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.RecipeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.RecipeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipe: (f = msg.getRecipe()) && models_pb.Recipe.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.RecipeResponse}
 */
proto.app.RecipeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.RecipeResponse;
  return proto.app.RecipeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.RecipeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.RecipeResponse}
 */
proto.app.RecipeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Recipe;
      reader.readMessage(value,models_pb.Recipe.deserializeBinaryFromReader);
      msg.setRecipe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.RecipeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.RecipeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.RecipeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.RecipeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipe();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.Recipe Recipe = 1;
 * @return {?proto.models.Recipe}
 */
proto.app.RecipeResponse.prototype.getRecipe = function() {
  return /** @type{?proto.models.Recipe} */ (
    jspb.Message.getWrapperField(this, models_pb.Recipe, 1));
};


/** @param {?proto.models.Recipe|undefined} value */
proto.app.RecipeResponse.prototype.setRecipe = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.RecipeResponse.prototype.clearRecipe = function() {
  this.setRecipe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.RecipeResponse.prototype.hasRecipe = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.VenueRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueRequest.displayName = 'proto.app.VenueRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueRequest}
 */
proto.app.VenueRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueRequest;
  return proto.app.VenueRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueRequest}
 */
proto.app.VenueRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.app.VenueRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.VenueRequest.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueBySlugRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.VenueBySlugRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueBySlugRequest.displayName = 'proto.app.VenueBySlugRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueBySlugRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueBySlugRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueBySlugRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueBySlugRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueBySlugRequest}
 */
proto.app.VenueBySlugRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueBySlugRequest;
  return proto.app.VenueBySlugRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueBySlugRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueBySlugRequest}
 */
proto.app.VenueBySlugRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueBySlugRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueBySlugRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueBySlugRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueBySlugRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Slug = 1;
 * @return {string}
 */
proto.app.VenueBySlugRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.app.VenueBySlugRequest.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.VenueResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueResponse.displayName = 'proto.app.VenueResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    venue: (f = msg.getVenue()) && models_pb.Venue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueResponse}
 */
proto.app.VenueResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueResponse;
  return proto.app.VenueResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueResponse}
 */
proto.app.VenueResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Venue;
      reader.readMessage(value,models_pb.Venue.deserializeBinaryFromReader);
      msg.setVenue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.Venue.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.Venue Venue = 1;
 * @return {?proto.models.Venue}
 */
proto.app.VenueResponse.prototype.getVenue = function() {
  return /** @type{?proto.models.Venue} */ (
    jspb.Message.getWrapperField(this, models_pb.Venue, 1));
};


/** @param {?proto.models.Venue|undefined} value */
proto.app.VenueResponse.prototype.setVenue = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.VenueResponse.prototype.clearVenue = function() {
  this.setVenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.VenueResponse.prototype.hasVenue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.ReviewsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.ReviewsResponse.repeatedFields_, null);
};
goog.inherits(proto.app.ReviewsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.ReviewsResponse.displayName = 'proto.app.ReviewsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.ReviewsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.ReviewsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.ReviewsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.ReviewsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ReviewsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reviewsList: jspb.Message.toObjectList(msg.getReviewsList(),
    models_pb.Review.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.ReviewsResponse}
 */
proto.app.ReviewsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.ReviewsResponse;
  return proto.app.ReviewsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.ReviewsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.ReviewsResponse}
 */
proto.app.ReviewsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Review;
      reader.readMessage(value,models_pb.Review.deserializeBinaryFromReader);
      msg.addReviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.ReviewsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.ReviewsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.ReviewsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ReviewsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Review.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Review Reviews = 1;
 * @return {!Array<!proto.models.Review>}
 */
proto.app.ReviewsResponse.prototype.getReviewsList = function() {
  return /** @type{!Array<!proto.models.Review>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Review, 1));
};


/** @param {!Array<!proto.models.Review>} value */
proto.app.ReviewsResponse.prototype.setReviewsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Review=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Review}
 */
proto.app.ReviewsResponse.prototype.addReviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Review, opt_index);
};


proto.app.ReviewsResponse.prototype.clearReviewsList = function() {
  this.setReviewsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.VenueListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueListRequest.displayName = 'proto.app.VenueListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listopts: (f = msg.getListopts()) && models_pb.ListOpts.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    townid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    venuetypeid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueListRequest}
 */
proto.app.VenueListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueListRequest;
  return proto.app.VenueListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueListRequest}
 */
proto.app.VenueListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.ListOpts;
      reader.readMessage(value,models_pb.ListOpts.deserializeBinaryFromReader);
      msg.setListopts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTownid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenuetypeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListopts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.ListOpts.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTownid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVenuetypeid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional models.ListOpts ListOpts = 1;
 * @return {?proto.models.ListOpts}
 */
proto.app.VenueListRequest.prototype.getListopts = function() {
  return /** @type{?proto.models.ListOpts} */ (
    jspb.Message.getWrapperField(this, models_pb.ListOpts, 1));
};


/** @param {?proto.models.ListOpts|undefined} value */
proto.app.VenueListRequest.prototype.setListopts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.VenueListRequest.prototype.clearListopts = function() {
  this.setListopts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.VenueListRequest.prototype.hasListopts = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Title = 2;
 * @return {string}
 */
proto.app.VenueListRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.app.VenueListRequest.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string TownID = 3;
 * @return {string}
 */
proto.app.VenueListRequest.prototype.getTownid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.app.VenueListRequest.prototype.setTownid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string VenueTypeID = 4;
 * @return {string}
 */
proto.app.VenueListRequest.prototype.getVenuetypeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.app.VenueListRequest.prototype.setVenuetypeid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.VenueListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.VenueListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueListResponse.displayName = 'proto.app.VenueListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.VenueListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    venuesList: jspb.Message.toObjectList(msg.getVenuesList(),
    models_pb.Venue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueListResponse}
 */
proto.app.VenueListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueListResponse;
  return proto.app.VenueListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueListResponse}
 */
proto.app.VenueListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Venue;
      reader.readMessage(value,models_pb.Venue.deserializeBinaryFromReader);
      msg.addVenues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Venue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Venue Venues = 1;
 * @return {!Array<!proto.models.Venue>}
 */
proto.app.VenueListResponse.prototype.getVenuesList = function() {
  return /** @type{!Array<!proto.models.Venue>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Venue, 1));
};


/** @param {!Array<!proto.models.Venue>} value */
proto.app.VenueListResponse.prototype.setVenuesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Venue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Venue}
 */
proto.app.VenueListResponse.prototype.addVenues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Venue, opt_index);
};


proto.app.VenueListResponse.prototype.clearVenuesList = function() {
  this.setVenuesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.ShowtimeListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.ShowtimeListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.ShowtimeListRequest.displayName = 'proto.app.ShowtimeListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.ShowtimeListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.ShowtimeListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.ShowtimeListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ShowtimeListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    listopts: (f = msg.getListopts()) && models_pb.ListOpts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.ShowtimeListRequest}
 */
proto.app.ShowtimeListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.ShowtimeListRequest;
  return proto.app.ShowtimeListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.ShowtimeListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.ShowtimeListRequest}
 */
proto.app.ShowtimeListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.ListOpts;
      reader.readMessage(value,models_pb.ListOpts.deserializeBinaryFromReader);
      msg.setListopts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.ShowtimeListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.ShowtimeListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.ShowtimeListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ShowtimeListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListopts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      models_pb.ListOpts.serializeBinaryToWriter
    );
  }
};


/**
 * optional models.ListOpts ListOpts = 1;
 * @return {?proto.models.ListOpts}
 */
proto.app.ShowtimeListRequest.prototype.getListopts = function() {
  return /** @type{?proto.models.ListOpts} */ (
    jspb.Message.getWrapperField(this, models_pb.ListOpts, 1));
};


/** @param {?proto.models.ListOpts|undefined} value */
proto.app.ShowtimeListRequest.prototype.setListopts = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.app.ShowtimeListRequest.prototype.clearListopts = function() {
  this.setListopts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.app.ShowtimeListRequest.prototype.hasListopts = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.ShowtimeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.ShowtimeListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.ShowtimeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.ShowtimeListResponse.displayName = 'proto.app.ShowtimeListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.ShowtimeListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.ShowtimeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.ShowtimeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.ShowtimeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ShowtimeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    showtimesList: jspb.Message.toObjectList(msg.getShowtimesList(),
    models_pb.Showtime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.ShowtimeListResponse}
 */
proto.app.ShowtimeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.ShowtimeListResponse;
  return proto.app.ShowtimeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.ShowtimeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.ShowtimeListResponse}
 */
proto.app.ShowtimeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Showtime;
      reader.readMessage(value,models_pb.Showtime.deserializeBinaryFromReader);
      msg.addShowtimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.ShowtimeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.ShowtimeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.ShowtimeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ShowtimeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShowtimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Showtime.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Showtime Showtimes = 1;
 * @return {!Array<!proto.models.Showtime>}
 */
proto.app.ShowtimeListResponse.prototype.getShowtimesList = function() {
  return /** @type{!Array<!proto.models.Showtime>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Showtime, 1));
};


/** @param {!Array<!proto.models.Showtime>} value */
proto.app.ShowtimeListResponse.prototype.setShowtimesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Showtime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Showtime}
 */
proto.app.ShowtimeListResponse.prototype.addShowtimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Showtime, opt_index);
};


proto.app.ShowtimeListResponse.prototype.clearShowtimesList = function() {
  this.setShowtimesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VenueTypeListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.VenueTypeListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.VenueTypeListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VenueTypeListResponse.displayName = 'proto.app.VenueTypeListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.VenueTypeListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VenueTypeListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VenueTypeListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VenueTypeListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueTypeListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    venuetypesList: jspb.Message.toObjectList(msg.getVenuetypesList(),
    models_pb.VenueType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VenueTypeListResponse}
 */
proto.app.VenueTypeListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VenueTypeListResponse;
  return proto.app.VenueTypeListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VenueTypeListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VenueTypeListResponse}
 */
proto.app.VenueTypeListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.VenueType;
      reader.readMessage(value,models_pb.VenueType.deserializeBinaryFromReader);
      msg.addVenuetypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VenueTypeListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VenueTypeListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VenueTypeListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VenueTypeListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVenuetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.VenueType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.VenueType VenueTypes = 1;
 * @return {!Array<!proto.models.VenueType>}
 */
proto.app.VenueTypeListResponse.prototype.getVenuetypesList = function() {
  return /** @type{!Array<!proto.models.VenueType>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.VenueType, 1));
};


/** @param {!Array<!proto.models.VenueType>} value */
proto.app.VenueTypeListResponse.prototype.setVenuetypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.VenueType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.VenueType}
 */
proto.app.VenueTypeListResponse.prototype.addVenuetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.VenueType, opt_index);
};


proto.app.VenueTypeListResponse.prototype.clearVenuetypesList = function() {
  this.setVenuetypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.TownListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.TownListResponse.repeatedFields_, null);
};
goog.inherits(proto.app.TownListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.TownListResponse.displayName = 'proto.app.TownListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.TownListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.TownListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.TownListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.TownListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.TownListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    townsList: jspb.Message.toObjectList(msg.getTownsList(),
    models_pb.Town.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.TownListResponse}
 */
proto.app.TownListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.TownListResponse;
  return proto.app.TownListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.TownListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.TownListResponse}
 */
proto.app.TownListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Town;
      reader.readMessage(value,models_pb.Town.deserializeBinaryFromReader);
      msg.addTowns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.TownListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.TownListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.TownListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.TownListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTownsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Town.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Town Towns = 1;
 * @return {!Array<!proto.models.Town>}
 */
proto.app.TownListResponse.prototype.getTownsList = function() {
  return /** @type{!Array<!proto.models.Town>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Town, 1));
};


/** @param {!Array<!proto.models.Town>} value */
proto.app.TownListResponse.prototype.setTownsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Town=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Town}
 */
proto.app.TownListResponse.prototype.addTowns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Town, opt_index);
};


proto.app.TownListResponse.prototype.clearTownsList = function() {
  this.setTownsList([]);
};


goog.object.extend(exports, proto.app);
