let getVenues = (state, getters) => {
  return state.venues
};

let getVenueTypes = (state, getters) => {
  return state.venueTypes
};

let getTowns = (state, getters) => {
  return state.towns
};

let getHasSearched = (state, getters) => {
  return state.hasSearched
}

let getSearchStatus = (state, getters) => {
  return state.searchStatus
}

let getVenue = (state, getters) => {
  return state.venue
}

export default {
  getVenues,
  getVenueTypes,
  getTowns,
  getHasSearched,
  getSearchStatus,
  getVenue
};
