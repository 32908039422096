function getRecipes(state) {
  return state.recipes;
}

function getRecipe(state) {
  return state.recipe;
}

export default {
  getRecipes,
  getRecipe
};
