<template>
  <div class="bg-grey" v-if="ad">
    <div class="container-fw">
      <div class="atomic__fw-container">
        <div class="image-wrapper">
          <a target="_blank" :href="ad.getWebsite()">
            <img v-if="ad.getImage()" :src="ad.getImage() + ':thumb'" :alt="ad.getName()">
          </a>
        </div>
        <div class="description-wrapper">
          <div class="sponsored">
            <router-link to="/sponsored">
              <div class="icon">i</div>
              <div class="small-text">Sponsored</div>
            </router-link>
          </div>
          <div class="title">
            <a target="_blank" :href="ad.getWebsite()">
              <h2>{{ad.getName()}}</h2>
            </a>
          </div>
          <p>{{ad.getDescription()}}</p>
          <div class="button-wrapper">
            <a target="_blank" :href="ad.getWebsite()">Plus d'info</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import moment from 'moment'

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getAds: "homepage/getAds"
    }),
    ad() {
      return this.getAds[0];
    },
  //   canDisplay () {
  //     let adStart = this.ad.getStartat().toDate().toISOString()
  //     let adEnd = this.ad.getEndat().toDate().toISOString()
  //     let currentTimestamp = moment().format()
  //     let status = true
  //
  //     // http://momentjs.com/docs/#/query/is-between/
  //     let isBetween = moment(currentTimestamp).isBetween(adStart, adEnd);
  //     if (isBetween === false) {
  //       status = false
  //     }
  //
  //     console.log(adStart)
  //     console.log(adEnd)
  //     console.log(currentTimestamp)
  //     console.log(isBetween)
  //
  //     return status
  //   }
  },
  beforeMount() {}
}

</script>
<style lang="scss">
.bg-grey {
  background: #e5e5e5;
  padding: $gutter * 1.5 0;
}

.atomic__fw-container {
  --title-font-size: 58px;
  --desc-font-size: 40px;

  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-column-gap: $gutter;
  align-items: center;

  .image-wrapper {
    a {
      display: block;
      text-align: center;

      img {
        border: 7px solid black;
        width: 100%;
      }
    }
  }
  .description-wrapper {
    .sponsored {
      opacity: 0.6;

      a {
        display: flex;
        text-decoration: none;
        align-items: center;
        line-height: 0;

        .icon {
          height: 20px;
          width: 20px;
          background: black;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 500;
          font-size: 13px;
          margin-right: $gutter/4;
          line-height: 0;
        }

        .small-text {
          font-size: 13px;
          text-transform: uppercase;
          color: black;
        }
      }
    }

    .title {
      a {
        text-decoration: none;
        color: black;

        h2 {
          font-size: var(--title-font-size);
          margin: 0;
        }
      }
    }

    p {
      font-size: var(--desc-font-size);
      margin: 0;
      margin-bottom: $gutter/2;
    }

    .button-wrapper {
      a {
        display: inline-block;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid black;
        padding: 10px 70px;
        background: white;
      }
    }
  }
}

@media (max-width: $desktop) {
  .atomic__fw-container {
    --title-font-size: 50px;
    --desc-font-size: 35px;
  }
}

@media (max-width: $tablet) {
  .bg-grey {
    padding: $gutter 0;
  }

  .atomic__fw-container {
    --title-font-size: 45px;
    --desc-font-size: 30px;

    grid-template-columns: 1fr;
    grid-row-gap: $gutter/2;

    .image-wrapper {
      a {
        img {
          max-width: 70%;
          margin: 0 auto;
        }
      }
    }

    .description-wrapper {
      text-align: center;

      .sponsored {
        a {
          justify-content: center;
        }
      }

      .title {
        margin-bottom: $gutter/3;
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .atomic__fw-container {
    --title-font-size: 30px;
    --desc-font-size: 22px;

    .image-wrapper {
      a {
        img {
          border-width: 5px;
        }
      }
    }

    .description-wrapper {
      .sponsored {
        margin-bottom: $gutter/4;
      }

      .title {
        margin-bottom: $gutter/5;
      }
    }
  }
}

@media (max-width: $mobile) {
  .atomic__fw-container {
    --title-font-size: 25px;
    --desc-font-size: 15px;

    .button-wrapper {
      a {
        font-size: 15px;
      }
    }
  }
}
</style>