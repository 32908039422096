function addEvent(state, payload) {
  state.allEvents.push(payload.data);
}

function setCurrentEvent(state, payload) {
  state.event = payload.data;
}

function setAllEvents(state, payload) {
  state.allEvents = payload;
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

export default {
  setAllEvents,
  addEvent,
  setCurrentEvent,
  hasFetched,
};
