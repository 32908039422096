import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  recipes: [],
  recipe: null,
  fetched: false,
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
