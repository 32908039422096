//@ts-check
import { AppClient } from "@/services/service_app_pb_service.js";
import {
  MovieListRequest,
  MovieBySlugRequest,
  ShowtimeListRequest,
  VenueListRequest,
} from "@/services/service_app_pb";
import {
  MovieListResponse,
  MovieResponse,
  VenueListResponse,
  ShowtimeListResponse,
} from "../../services/service_app_pb";
import { grpcFetch, loadSingleEntityFromStore } from "@/util/cache-manager.js";

import router from "@/router";
import moment from "moment";

function load({ state, commit }) {
  let reqMovieList = new MovieListRequest();
  const cacheName = "cinema";
  const methodName = "getMovies";

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Looking up movies",
    },
    { root: true },
  );
  /**** Loader ****/

  grpcFetch(
    methodName,
    reqMovieList,
    cacheName,
    MovieListResponse,
    commit,
  ).then((res) => {
    let entities = res.getMoviesList();
    commit("setMovies", entities);

    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Done Looking up movies",
      },
      { root: true },
    );
    /**** Loader ****/
  });
}

function getSingleMovieById({ state, commit, rootState }, payload) {
  let reqMovie = new MovieBySlugRequest();
  let entitySlug = rootState.route.params.id;
  const cacheName = entitySlug;
  const methodName = "getMovieBySlug";

  reqMovie.setSlug(entitySlug);

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: `Looking up ${entitySlug}`,
    },
    { root: true },
  );
  /**** Loader ****/

  loadSingleEntityFromStore(state.movies, "getSlug", entitySlug, commit)
    .then((res) => {
      let getShowtimesMap = res.getShowtimesMap();
      let getVenuesMap = res.getVenuesMap();
      let movieObject = res.getMovie();

      //commit("addMovie", { movie: movieObject, showtimes: getShowtimesMap, venues: getVenuesMap });
      commit("setMovie", {
        data: movieObject,
        showtimes: getShowtimesMap,
        venues: getVenuesMap,
      });

      /**** Loader ****/
      commit(
        "SET_STATUS",
        {
          status: false,
          message: `Found ${entitySlug}`,
        },
        { root: true },
      );
      /**** Loader ****/
    })
    .catch((err) => {
      grpcFetch(methodName, reqMovie, cacheName, MovieResponse, commit).then(
        (res) => {
          let getShowtimesMap = res.getShowtimesMap();
          let getVenuesMap = res.getVenuesMap();
          let movieObject = res.getMovie();

          //commit("addMovie", { movie: movieObject, showtimes: getShowtimesMap, venues: getVenuesMap });
          commit("setMovie", {
            data: movieObject,
            showtimes: getShowtimesMap,
            venues: getVenuesMap,
          });

          /**** Loader ****/
          commit(
            "SET_STATUS",
            {
              status: false,
              message: `Found ${entitySlug}`,
            },
            { root: true },
          );
          /**** Loader ****/
        },
      );
    });
}

function getTheatresShowtimes({ state, commit, rootState }, payload) {
  let reqShowtimeList = new ShowtimeListRequest();

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching showtimes list",
    },
    { root: true },
  );
  /**** Loader ****/

  const cacheName = "showtimes";
  const methodName = "getShowtimes";

  grpcFetch(
    methodName,
    reqShowtimeList,
    cacheName,
    ShowtimeListResponse,
    commit,
  ).then((res) => {
    let getShowtimesMap = res.getShowtimesList();

    let showtimes = [];
    getShowtimesMap.forEach((showtime) => {
      showtimes.push({
        venueid: showtime.getVenueid(),
        movieid: showtime.getMovieid(),
        timestamp: showtime.getStartat(),
        date: moment(showtime.getStartat().toDate()).format("Do MMM YYYY"),
      });
    });

    let moviesGroupedByTheatre = _.groupBy(showtimes, "venueid");
    let moviesGroupedByDate = _.groupBy(showtimes, "date");
    commit("setMoviesGroupedByTheatre", { data: moviesGroupedByTheatre });
    commit("setMoviesGroupedByDate", { data: moviesGroupedByDate });
    commit("setShowtimes", { data: getShowtimesMap });

    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Fetched showtimes list",
      },
      { root: true },
    );
    /**** Loader ****/
  });

  let reqVenueList = new VenueListRequest();

  const cacheNameTheatre = "theatres";
  const methodNameTheatre = "getTheatres";

  grpcFetch(
    methodNameTheatre,
    reqVenueList,
    cacheNameTheatre,
    VenueListResponse,
    commit,
  ).then((res) => {
    let getVenuesMap = res.getVenuesList();
    commit("setTheatres", {
      data: getVenuesMap,
    });
    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Fetched venues/theatres list",
      },
      { root: true },
    );
    /**** Loader ****/
  });
}

export default {
  load,
  getSingleMovieById,
  getTheatresShowtimes,
};
