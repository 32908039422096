export default {
  SET_STATUS(state, data) {
    //console.log(data.message)
    state.loading.status = data.status;
    state.loading.message.push(data.message);
  },
  setMenuStatus(state, payload) {
    state.menuStatus = payload.data;
  }
};
