<template>
  <div class="theaters-list-wrapper">
    <div class="theater-wrapper"
         v-for="(theatre, index) in theatres.venueData"
         v-bind:class="{ showDetails : true }"
         :key="index"
    >
      <div class="theater-name">
        <div class="name-wrapper">{{theatre.getName()}}</div>
      </div>
      <div class="theater-details-wrapper">
        <div class="header-container">
          <div class="column-wrapper date">Date</div>
          <div class="column-wrapper theater-number">Salle</div>
          <div class="column-wrapper timing">Horaires</div>
        </div>
        <div class="rows-container">
          <div class="row-wrapper" :key="index" :class="{ 'highlight' : schedule.data.selected, 'isToday' : schedule.data.isToday }" v-for="(schedule, index) in theatres.venueGrouped[theatre.getId()]">
            <div class="column-wrapper date">{{schedule.data.date}}</div>
            <div class="column-wrapper theater-number">{{schedule.data.screen}}</div>
            <div class="column-wrapper timing timing">
              <span class="time-wrapper" :key="" v-for="time in schedule.showtimes">
                {{time.time}}
                <template v-if="time.three_d">
                  (3D)
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'ScheduleListing',
    computed: {
      ...mapGetters({
        theatres: 'movies/getTheatres',
        filterInfo: 'movies/filterInfo'
      }),
      datecheck () {
        return (moment(this.theatre.schedules[0].date * 1000).format('M/D/Y') === this.filterInfo.date)
      }
    },
    filters: {
      ago (date) {
        return moment(date * 1000).format('MMM Do YYYY')
      },
      getTime(date) {
        return moment(date * 1000).format('HH:mm')
      }
    },
    data () {
      return {
        msg: 'Schedule Listing'
      }
    },
    methods: {
      groupData (value) {
        return value
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  @keyframes scheduleDropMenu {
    0% {
      display: block;
      overflow: hidden;
      max-height: 0;
    }
    100% {
      display: block;
      max-height: 1000px;
      overflow: hidden;
    }
  }

  .theaters-list-wrapper {
    .theater-wrapper {

      // Show schedule
      &.showDetails {
        .theater-name {
          .arrow-wrapper {
            button {
              i {
                transform: rotate(180deg);
              }
            }
          }
        }
        .theater-details-wrapper {
          display: block;
         // max-height: 0;
          overflow: hidden;

          animation-name: scheduleDropMenu;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
          //transition: all 0.4s ease-in-out;
        }
      }

      .theater-name {
        //padding: $gutter/2;
        display: flex;
        align-items: center;
        padding: $gutter/4;
        border-bottom: 1px solid $color-grey;
        transition: all 0.3s ease-in-out;
        font-weight: 700;
        font-size: 1.2em;

        .name-wrapper {
        }
        .arrow-wrapper {
          text-align: right;

          button {
            background: transparent;
            border: 0px;
            padding: 0;
            position: relative;
            top: 2px;

            &:focus {
              box-shadow: none;
              outline: none;
            }

            &:hover {
              cursor: pointer;
            }

            i {
              font-size: 35px;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
      .theater-details-wrapper {
        width: 100%;
        display: none;

        .header-container {
          background: $color-light-grey;
          color: $color-black;
          padding: $gutter/4;
          display: grid;
          grid-column-gap: $gutter / 4;
          grid-template-columns: calc(2/8 * 100%) calc(1/8 * 100%) calc(5/8 * 100%);

          .column-wrapper {
            text-transform: uppercase;
            font-size:13px;
            font-weight: 500;

            &.date {
            }
            &.theater-number {
            }
            &.timing {
            }
          }
        }
        .rows-container {
          display: grid;
          //background: $color-white;
          color: $color-black;

          .row-wrapper {
            padding: 0 $gutter/4;
            border-bottom: 1px solid $color-grey;
            font-size: 15px;
            width: 100%;
            display: grid;
            grid-template-columns: calc(2/8 * 100%) calc(1/8 * 100%) calc(5/8 * 100%);
            grid-column-gap: $gutter / 4;
            transition: all 0.3s ease-in-out;

            &.isToday {
              font-weight: 700;
            }

            &.highlight{
              background: rgba($color-main, 0.2);
            }

            &:last-child {
              border-bottom: 0;
            }

            .column-wrapper {
              &.date {
                padding: $gutter/4 0;
              }
              &.theater-number {
                padding: $gutter/4 0;
              }
              &.timing {

                .time-wrapper {
                  margin-right: $gutter/4;
                  padding: $gutter/4 0;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .theaters-list-wrapper {
      .theater-wrapper {
        .theater-details-wrapper {
          width: 100%;
          display: none;

          .header-container {
            .column-wrapper {
              &.date {
              }
              &.theater-number {
              }
              &.timing {
              }
            }
          }
          .rows-container {
            .row-wrapper {
              .column-wrapper {
                &.date {
                  padding: $gutter/4 0;
                }
                &.theater-number {
                  padding: $gutter/4 0;
                }
                &.timing {
                }
              }
            }
          }
        }
      }
    }
  }

</style>
