<template>
  <headroom>
    <header>
      <div class="container-fw">
        <div class="logo-wrapper">
          <transition-group name="fade" class="logo-list" mode="out-in">
            <router-link
              to
              @click.native="goBack()"
              key="back"
              class="back-to-from"
              v-if="$route.path !== '/'"
            >
              <img src="@/assets/images/back.svg" alt>
            </router-link>
            <router-link :to="{ 'path': '/'}" key="logo" class="logo">
              <img class="logo" src="@/assets/images/logo.svg" alt="lexpress.mu culture">
            </router-link>
          </transition-group>
        </div>
        <div class="site-navigation" :class="{ active }">
          <nav>
            <ul>
              <li>
                <router-link :to="{ 'path': '/cinema'}" @click.native="resetMenu()">Cinéma</router-link>
                <span class="menu-bg"></span>
              </li>
              <li>
                <router-link :to="{ 'path': '/evenements'}" @click.native="resetMenu()">Ki bon plan?</router-link>
                <span class="menu-bg"></span>
              </li>
              <li>
                <router-link :to="{ 'path': '/recettes'}" @click.native="resetMenu()">Bon appétit!</router-link>
                <span class="menu-bg"></span>
              </li>
              <li>
                <router-link :to="{ 'path': '/venues'}" @click.native="resetMenu()">Ki bon baz?</router-link>
                <span class="menu-bg"></span>
              </li>
            </ul>
          </nav>
        </div>
        <button class="menu-button" @click="activateMenu()" :class="{ active }">
          <i></i>
        </button>
      </div>
    </header>
  </headroom>
</template>

<script>
import router from "@/router";
import { headroom } from "vue-headroom";

export default {
  components: {
    headroom,
  },
  data() {
    return {
      active: null,
      backIsActive: false,
      backURL: "",
    };
  },
  methods: {
    activateMenu() {
      let status = false;

      if (this.active === null) {
        this.active = "active";
        status = true;
      } else {
        this.active = null;
        status = false;
      }

      this.$store.dispatch(
        "setMenuStatus",
        {
          data: status,
        },
        { root: true },
      );
    },
    resetMenu() {
      this.active = null;

      if (document.body.classList.contains("no-scroll")) {
        document.body.classList.remove("no-scroll");
      }
    },
    goBack() {
      router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app {
  &.noScroll {
    overflow-y: hidden;
  }
}

header {
  background: #002b54;
  height: 65px;
  width: 100%;
  position: relative;

  .container-fw {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .logo-wrapper {
    grid-column: 1 / 2;
    display: flex;

    .logo-list {
      display: flex;
    }

    a {
      display: block;
      height: 65px;

      &.back-to-from {
        display: flex;
        align-items: center;
        padding: 0 10px 0 10px;
        //background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);

        img {
          height: 30px;
          width: auto;
        }
      }

      &.logo {
        padding-top: 13px;
        //margin-left: 15px;

        img {
          max-width: 200px;
          width: 200px;
        }
      }
    }
  }

  .site-navigation {
    nav {
      display: flex;
      justify-content: flex-end;

      ul {
        list-style: none;
        display: flex;
        margin: 0;

        li {
          list-style: none;
          padding: 0 30px;
          position: relative;

          a {
            height: 65px;
            color: white;
            text-decoration: none;
            white-space: nowrap;
            display: flex;
            align-items: center;
            font-weight: 400;

            &:focus {
              outline: none;
            }
          }

          .menu-bg {
            transition: all 0.2s ease-out;
            position: absolute;
            transform: skew(-20deg);
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
          }

          &:hover .menu-bg {
            filter: saturate(2);
            transition: filter 0.2s ease-in;
          }

          &:nth-child(4) .menu-bg {
            background: #457c00;
          }

          &:nth-child(3) .menu-bg {
            background: #a8ab00;
          }

          &:nth-child(2) .menu-bg {
            background: #004a8f;
          }

          &:nth-child(1) .menu-bg {
            background: #aa3b3b;
          }
        }
      }
    }
  }

  .menu-button {
    display: none;
    background: transparent;
    border: 0;
    width: 100%;
    outline: none;
    cursor: pointer;
    padding: 0;

    i {
      position: relative;
      display: block;
      text-align: center;
      width: 40px;
      height: 3px;
      color: #fff;
      background: #fff;
      transition: all 0.2s ease-out;

      &:after,
      &:before {
        content: "";
        width: 40px;
        height: 3px;
        background: #fff;
        position: absolute;
        left: 0;
        transition: all 0.3s ease-in-out;
      }
      &:after {
        bottom: -11px;
      }
      &:before {
        top: -11px;
      }
    }

    &.active {
      i {
        background: transparent;
        &:after {
          bottom: 0;
          transform: rotate(-45deg);
          opacity: 1;
        }
        &:before {
          top: 0;
          transform: rotate(45deg);
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  header {
    .site-navigation {
      position: fixed;
      width: 250px;
      transform: translateX(-260px);
      transition: all 0.2s ease-in-out;

      nav {
        ul {
          display: block;
          width: 250px;
          padding: 0;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          height: 100%;
          min-height: calc(100vh);
          background: white;

          li {
            border-bottom: 1px solid #cccccc;
            a {
              justify-content: center;
              font-weight: 400;
              color: #000000;

              &.router-link-exact-active {
                font-weight: 600;
              }
            }

            &:nth-child(4) .router-link-exact-active {
              color: #457c00;
            }

            &:nth-child(3) .router-link-exact-active {
              color: #a8ab00;
            }

            &:nth-child(2) .router-link-exact-active {
              color: #004a8f;
            }

            &:nth-child(1) .router-link-exact-active {
              color: #aa3b3b;
            }

            &:nth-child(4) .menu-bg {
              border-bottom: 0;
            }

            .menu-bg {
              transform: none;
              background: #fff !important;
              border-bottom: 1px solid #ccc;
            }
          }
        }
      }

      &.active {
        transform: translateX(-10px);
      }
    }

    .container-fw {
      grid-template-columns: 1fr 0.2fr;
    }

    .logo-wrapper {
      a {
        &.back-to-from {
          img {
          }
        }
      }
    }

    .menu-button {
      display: flex !important;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
