import { AppClient } from "@/services/service_app_pb_service.js";
import {
  EmptyRequest
} from "@/services/service_app_pb";

function load({ state, commit }) {
  let client = new AppClient("/api");
  let req = new EmptyRequest();

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching reviews"
    },
    { root: true }
  );
  /**** Loader ****/

  if (!state.fetched) {
    client.getReviews(req, (err, res) => {
      if (err == null) {
        commit("hasFetched", { value: true });
        /**** Loader ****/
        commit(
          "SET_STATUS",
          {
            status: false,
            message: "Fetched reviews data"
          },
          { root: true }
        );
        /**** Loader ****/

        // Banners
        let reviews = res.getReviewsList();

        // set data
        commit("setReviews", { data: reviews });
      } else {
        console.log(err);
      }
    });
  } else {
    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Has already fetched reviews data"
      },
      { root: true }
    );
    /**** Loader ****/
  }
}

export default {
  load
};
