<template>
  <div class="bg-grey-pattern" v-if="recipes.length">
    <div class="container-fw">
      <div class="section-title">
        <h2>Bon appétit!</h2>
      </div>
      <div class="recipes__container">
        <swiper :options="swiperOption" ref="HomeRecetteSwiper">
          <swiper-slide v-for="(recipe)  in recipes">
            <RecipeComponent :recipe="recipe"></RecipeComponent>
          </swiper-slide>
        </swiper>
      </div>
      <ViewMoreButton theme="dark" url="/recettes"></ViewMoreButton>
    </div>
  </div>
</template>
<script>
  import "swiper/dist/css/swiper.css";

  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import ViewMoreButton from "@/components/shared/ViewMoreButton";
  import { mapGetters } from "vuex";
  import RecipeComponent from '@/components/recipe/RecipeComponent'

  export default {
    name: "HomeRecettes",
    data() {
      return {
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 30,
          grabCursor: true,
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          breakpoints: {
            // when window width is <= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            // when window width is <= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 20
            },
            // when window width is <= 1024px
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },
          pagination: {
            el: ".swiper-pagination"
          }
        }
      };
    },
    components: {
      ViewMoreButton,
      swiper,
      swiperSlide,
      RecipeComponent
    },
    mounted() {
      setTimeout(() => {
        if (this.$refs.hasOwnProperty("HomeRecetteSwiper")) {
          this.HomeRecetteSwiper.destroy(false, false);
          this.HomeRecetteSwiper.init();
        }
      }, 100);
    },
    computed: {
      ...mapGetters({
        recipes: "homepage/getRecipes"
      }),
      HomeRecetteSwiper() {
        if (this.$refs.hasOwnProperty("HomeRecetteSwiper")) {
          return this.$refs.HomeRecetteSwiper.swiper;
        }
      }
    },
    updated () {
      if (this.$refs.hasOwnProperty("HomeRecetteSwiper")) {
        this.HomeRecetteSwiper.destroy(false, false);
        this.HomeRecetteSwiper.init();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .bg-grey-pattern {
    background: url("../../assets/images/bgnoise_lg.png");
    padding: $gutter * 1.5 0;
  }

  .section-title {
    h2 {
      font-size: 36px;
      margin: 0;
      padding-bottom: $gutter/2;
    }
  }
</style>
