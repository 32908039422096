<template>
  <div :data-deck="featuredClass">
    <router-link
      :to="{'path': 'evenement/' + event.getSlug()}"
      title
      :key="index"
      class="single-content-card"
      v-for="(event, index) in sortData(events)"
      :data-order="index + 1"
      v-if="featured === event.getIsfeatured()"
    >
      <div class="card-thumbnail">
        <img v-if="event.getImage()" :src="event.getImage() + ':thumb'" :alt="event.getName()">
      </div>
      <div class="card-description">
        <div class="title-wrapper">
          <h2 v-if="event.getName()">{{event.getName()}}</h2>
          <!--<div class="event-details-wrapper">-->
          <!--{{event.getStartat().toDate() | date}}-->
          <!--</div>-->
        </div>
        <div class="description-wrapper">
          <div class="digit-wrapper" v-if="event.getStartat()">{{event.getStartat().toDate() | day}}</div>
          <div
            class="month-wrapper"
            v-if="event.getStartat()"
          >{{event.getStartat().toDate() | month}}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "EventListing",
  props: ["featured"],
  filters: {
    date(date) {
      return moment(date)
        .locale("fr")
        .format("MMM Do");
    },
    day(date) {
      return moment(date)
        .locale("fr")
        .format("D");
    },
    month(date) {
      return moment(date)
        .locale("fr")
        .format("MMM");
    },
  },
  methods: {
    sortData(data) {
      return _.orderBy(
        data,
        (event) => {
          return event.getStartat().toDate();
        },
        ["desc"],
      );
    },
  },
  computed: {
    featuredClass: function() {
      if (this.featured) {
        return "featured-events-listing";
      }
      return "main-events-listing";
    },
    ...mapGetters({
      events: "events/getEvents",
    }),
  },
  mounted() {
    // console.log(this.events)
  },
};
</script>

<style scoped>
</style>
