/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.exportSymbol('proto.models.Ads', null, global);
goog.exportSymbol('proto.models.Banner', null, global);
goog.exportSymbol('proto.models.Event', null, global);
goog.exportSymbol('proto.models.EventTopic', null, global);
goog.exportSymbol('proto.models.EventType', null, global);
goog.exportSymbol('proto.models.Genre', null, global);
goog.exportSymbol('proto.models.ListOpts', null, global);
goog.exportSymbol('proto.models.ListSortOpts', null, global);
goog.exportSymbol('proto.models.Movie', null, global);
goog.exportSymbol('proto.models.Product', null, global);
goog.exportSymbol('proto.models.Recipe', null, global);
goog.exportSymbol('proto.models.RecipeInstruction', null, global);
goog.exportSymbol('proto.models.Review', null, global);
goog.exportSymbol('proto.models.Settings', null, global);
goog.exportSymbol('proto.models.Showtime', null, global);
goog.exportSymbol('proto.models.Sponsor', null, global);
goog.exportSymbol('proto.models.Town', null, global);
goog.exportSymbol('proto.models.Venue', null, global);
goog.exportSymbol('proto.models.VenueGallery', null, global);
goog.exportSymbol('proto.models.VenueType', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.ListOpts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.ListOpts.repeatedFields_, null);
};
goog.inherits(proto.models.ListOpts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.ListOpts.displayName = 'proto.models.ListOpts';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.ListOpts.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.ListOpts.prototype.toObject = function(opt_includeInstance) {
  return proto.models.ListOpts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.ListOpts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.ListOpts.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortList: jspb.Message.toObjectList(msg.getSortList(),
    proto.models.ListSortOpts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.ListOpts}
 */
proto.models.ListOpts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.ListOpts;
  return proto.models.ListOpts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.ListOpts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.ListOpts}
 */
proto.models.ListOpts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = new proto.models.ListSortOpts;
      reader.readMessage(value,proto.models.ListSortOpts.deserializeBinaryFromReader);
      msg.addSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.ListOpts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.ListOpts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.ListOpts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.ListOpts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSortList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.models.ListSortOpts.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 Offset = 1;
 * @return {number}
 */
proto.models.ListOpts.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.models.ListOpts.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Limit = 2;
 * @return {number}
 */
proto.models.ListOpts.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.models.ListOpts.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ListSortOpts Sort = 3;
 * @return {!Array<!proto.models.ListSortOpts>}
 */
proto.models.ListOpts.prototype.getSortList = function() {
  return /** @type{!Array<!proto.models.ListSortOpts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.ListSortOpts, 3));
};


/** @param {!Array<!proto.models.ListSortOpts>} value */
proto.models.ListOpts.prototype.setSortList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.models.ListSortOpts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.ListSortOpts}
 */
proto.models.ListOpts.prototype.addSort = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.models.ListSortOpts, opt_index);
};


proto.models.ListOpts.prototype.clearSortList = function() {
  this.setSortList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.ListSortOpts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.ListSortOpts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.ListSortOpts.displayName = 'proto.models.ListSortOpts';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.ListSortOpts.prototype.toObject = function(opt_includeInstance) {
  return proto.models.ListSortOpts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.ListSortOpts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.ListSortOpts.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ascending: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.ListSortOpts}
 */
proto.models.ListSortOpts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.ListSortOpts;
  return proto.models.ListSortOpts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.ListSortOpts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.ListSortOpts}
 */
proto.models.ListSortOpts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAscending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.ListSortOpts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.ListSortOpts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.ListSortOpts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.ListSortOpts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAscending();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string Field = 1;
 * @return {string}
 */
proto.models.ListSortOpts.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.ListSortOpts.prototype.setField = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Ascending = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.models.ListSortOpts.prototype.getAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.models.ListSortOpts.prototype.setAscending = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Event.repeatedFields_, null);
};
goog.inherits(proto.models.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Event.displayName = 'proto.models.Event';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Event.repeatedFields_ = [20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    startat: (f = msg.getStartat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    summary: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    video: jspb.Message.getFieldWithDefault(msg, 12, ""),
    audio: jspb.Message.getFieldWithDefault(msg, 13, ""),
    image: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isfeatured: jspb.Message.getFieldWithDefault(msg, 15, false),
    weight: jspb.Message.getFieldWithDefault(msg, 16, 0),
    organizer: jspb.Message.getFieldWithDefault(msg, 17, ""),
    venueid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    venue: (f = msg.getVenue()) && proto.models.Venue.toObject(includeInstance, f),
    eventtypesList: jspb.Message.toObjectList(msg.getEventtypesList(),
    proto.models.EventType.toObject, includeInstance),
    eventtopicsList: jspb.Message.toObjectList(msg.getEventtopicsList(),
    proto.models.EventTopic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Event}
 */
proto.models.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Event;
  return proto.models.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Event}
 */
proto.models.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudio(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfeatured(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizer(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueid(value);
      break;
    case 19:
      var value = new proto.models.Venue;
      reader.readMessage(value,proto.models.Venue.deserializeBinaryFromReader);
      msg.setVenue(value);
      break;
    case 20:
      var value = new proto.models.EventType;
      reader.readMessage(value,proto.models.EventType.deserializeBinaryFromReader);
      msg.addEventtypes(value);
      break;
    case 21:
      var value = new proto.models.EventTopic;
      reader.readMessage(value,proto.models.EventTopic.deserializeBinaryFromReader);
      msg.addEventtopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAudio();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsfeatured();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getOrganizer();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVenueid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getVenue();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.models.Venue.serializeBinaryToWriter
    );
  }
  f = message.getEventtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.models.EventType.serializeBinaryToWriter
    );
  }
  f = message.getEventtopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.models.EventTopic.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Event.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Event.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Slug = 3;
 * @return {string}
 */
proto.models.Event.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.models.Event.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Event.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Event.prototype.setCreatedat = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.models.Event.prototype.clearCreatedat = function() {
  this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Event.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Event.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Event.prototype.setUpdatedat = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.models.Event.prototype.clearUpdatedat = function() {
  this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Event.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Name = 7;
 * @return {string}
 */
proto.models.Event.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp StartAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Event.prototype.getStartat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Event.prototype.setStartat = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.models.Event.prototype.clearStartat = function() {
  this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Event.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp EndAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Event.prototype.getEndat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Event.prototype.setEndat = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.models.Event.prototype.clearEndat = function() {
  this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Event.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string Summary = 10;
 * @return {string}
 */
proto.models.Event.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setSummary = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Description = 11;
 * @return {string}
 */
proto.models.Event.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Video = 12;
 * @return {string}
 */
proto.models.Event.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setVideo = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string Audio = 13;
 * @return {string}
 */
proto.models.Event.prototype.getAudio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setAudio = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string Image = 14;
 * @return {string}
 */
proto.models.Event.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool IsFeatured = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.models.Event.prototype.getIsfeatured = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.models.Event.prototype.setIsfeatured = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional int64 Weight = 16;
 * @return {number}
 */
proto.models.Event.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.models.Event.prototype.setWeight = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string Organizer = 17;
 * @return {string}
 */
proto.models.Event.prototype.getOrganizer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setOrganizer = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string VenueID = 18;
 * @return {string}
 */
proto.models.Event.prototype.getVenueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.models.Event.prototype.setVenueid = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional Venue Venue = 19;
 * @return {?proto.models.Venue}
 */
proto.models.Event.prototype.getVenue = function() {
  return /** @type{?proto.models.Venue} */ (
    jspb.Message.getWrapperField(this, proto.models.Venue, 19));
};


/** @param {?proto.models.Venue|undefined} value */
proto.models.Event.prototype.setVenue = function(value) {
  jspb.Message.setWrapperField(this, 19, value);
};


proto.models.Event.prototype.clearVenue = function() {
  this.setVenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Event.prototype.hasVenue = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated EventType Eventtypes = 20;
 * @return {!Array<!proto.models.EventType>}
 */
proto.models.Event.prototype.getEventtypesList = function() {
  return /** @type{!Array<!proto.models.EventType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.EventType, 20));
};


/** @param {!Array<!proto.models.EventType>} value */
proto.models.Event.prototype.setEventtypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.models.EventType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.EventType}
 */
proto.models.Event.prototype.addEventtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.models.EventType, opt_index);
};


proto.models.Event.prototype.clearEventtypesList = function() {
  this.setEventtypesList([]);
};


/**
 * repeated EventTopic Eventtopics = 21;
 * @return {!Array<!proto.models.EventTopic>}
 */
proto.models.Event.prototype.getEventtopicsList = function() {
  return /** @type{!Array<!proto.models.EventTopic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.EventTopic, 21));
};


/** @param {!Array<!proto.models.EventTopic>} value */
proto.models.Event.prototype.setEventtopicsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.models.EventTopic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.EventTopic}
 */
proto.models.Event.prototype.addEventtopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.models.EventTopic, opt_index);
};


proto.models.Event.prototype.clearEventtopicsList = function() {
  this.setEventtopicsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.EventType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.EventType.repeatedFields_, null);
};
goog.inherits(proto.models.EventType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.EventType.displayName = 'proto.models.EventType';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.EventType.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.EventType.prototype.toObject = function(opt_includeInstance) {
  return proto.models.EventType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.EventType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.EventType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.models.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.EventType}
 */
proto.models.EventType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.EventType;
  return proto.models.EventType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.EventType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.EventType}
 */
proto.models.EventType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.models.Event;
      reader.readMessage(value,proto.models.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.EventType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.EventType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.EventType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.EventType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.models.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.EventType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.EventType.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.EventType.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.EventType.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.EventType.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.EventType.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.EventType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.EventType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Event Events = 5;
 * @return {!Array<!proto.models.Event>}
 */
proto.models.EventType.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Event, 5));
};


/** @param {!Array<!proto.models.Event>} value */
proto.models.EventType.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.models.EventType.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.models.Event, opt_index);
};


proto.models.EventType.prototype.clearEventsList = function() {
  this.setEventsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.EventTopic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.EventTopic.repeatedFields_, null);
};
goog.inherits(proto.models.EventTopic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.EventTopic.displayName = 'proto.models.EventTopic';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.EventTopic.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.EventTopic.prototype.toObject = function(opt_includeInstance) {
  return proto.models.EventTopic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.EventTopic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.EventTopic.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.models.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.EventTopic}
 */
proto.models.EventTopic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.EventTopic;
  return proto.models.EventTopic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.EventTopic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.EventTopic}
 */
proto.models.EventTopic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.models.Event;
      reader.readMessage(value,proto.models.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.EventTopic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.EventTopic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.EventTopic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.EventTopic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.models.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.EventTopic.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.EventTopic.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.EventTopic.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.EventTopic.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.EventTopic.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.EventTopic.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.EventTopic.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.EventTopic.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Event Events = 5;
 * @return {!Array<!proto.models.Event>}
 */
proto.models.EventTopic.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Event, 5));
};


/** @param {!Array<!proto.models.Event>} value */
proto.models.EventTopic.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.models.EventTopic.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.models.Event, opt_index);
};


proto.models.EventTopic.prototype.clearEventsList = function() {
  this.setEventsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Venue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Venue.repeatedFields_, null);
};
goog.inherits(proto.models.Venue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Venue.displayName = 'proto.models.Venue';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Venue.repeatedFields_ = [16,17,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Venue.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Venue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Venue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Venue.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    addr: jspb.Message.getFieldWithDefault(msg, 6, ""),
    latlng: jspb.Message.getFieldWithDefault(msg, 7, ""),
    website: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contactnum: jspb.Message.getFieldWithDefault(msg, 10, ""),
    image: jspb.Message.getFieldWithDefault(msg, 11, ""),
    video: jspb.Message.getFieldWithDefault(msg, 12, ""),
    openinghours: jspb.Message.getFieldWithDefault(msg, 13, ""),
    facilities: jspb.Message.getFieldWithDefault(msg, 14, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 15, ""),
    venuetypesList: jspb.Message.toObjectList(msg.getVenuetypesList(),
    proto.models.VenueType.toObject, includeInstance),
    venuegalleriesList: jspb.Message.toObjectList(msg.getVenuegalleriesList(),
    proto.models.VenueGallery.toObject, includeInstance),
    townid: jspb.Message.getFieldWithDefault(msg, 18, ""),
    town: (f = msg.getTown()) && proto.models.Town.toObject(includeInstance, f),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.models.Event.toObject, includeInstance),
    showtimesList: jspb.Message.toObjectList(msg.getShowtimesList(),
    proto.models.Showtime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Venue}
 */
proto.models.Venue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Venue;
  return proto.models.Venue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Venue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Venue}
 */
proto.models.Venue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatlng(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactnum(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeninghours(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFacilities(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 16:
      var value = new proto.models.VenueType;
      reader.readMessage(value,proto.models.VenueType.deserializeBinaryFromReader);
      msg.addVenuetypes(value);
      break;
    case 17:
      var value = new proto.models.VenueGallery;
      reader.readMessage(value,proto.models.VenueGallery.deserializeBinaryFromReader);
      msg.addVenuegalleries(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTownid(value);
      break;
    case 19:
      var value = new proto.models.Town;
      reader.readMessage(value,proto.models.Town.deserializeBinaryFromReader);
      msg.setTown(value);
      break;
    case 20:
      var value = new proto.models.Event;
      reader.readMessage(value,proto.models.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 21:
      var value = new proto.models.Showtime;
      reader.readMessage(value,proto.models.Showtime.deserializeBinaryFromReader);
      msg.addShowtimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Venue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Venue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Venue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Venue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLatlng();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContactnum();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOpeninghours();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFacilities();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getVenuetypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.models.VenueType.serializeBinaryToWriter
    );
  }
  f = message.getVenuegalleriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.models.VenueGallery.serializeBinaryToWriter
    );
  }
  f = message.getTownid();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTown();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.models.Town.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.models.Event.serializeBinaryToWriter
    );
  }
  f = message.getShowtimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.models.Showtime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Venue.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Venue.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Slug = 3;
 * @return {string}
 */
proto.models.Venue.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.models.Venue.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Name = 5;
 * @return {string}
 */
proto.models.Venue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Addr = 6;
 * @return {string}
 */
proto.models.Venue.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setAddr = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string LatLng = 7;
 * @return {string}
 */
proto.models.Venue.prototype.getLatlng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setLatlng = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Website = 8;
 * @return {string}
 */
proto.models.Venue.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setWebsite = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Email = 9;
 * @return {string}
 */
proto.models.Venue.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setEmail = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string ContactNum = 10;
 * @return {string}
 */
proto.models.Venue.prototype.getContactnum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setContactnum = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Image = 11;
 * @return {string}
 */
proto.models.Venue.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Video = 12;
 * @return {string}
 */
proto.models.Venue.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setVideo = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string OpeningHours = 13;
 * @return {string}
 */
proto.models.Venue.prototype.getOpeninghours = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setOpeninghours = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string Facilities = 14;
 * @return {string}
 */
proto.models.Venue.prototype.getFacilities = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setFacilities = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Notes = 15;
 * @return {string}
 */
proto.models.Venue.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setNotes = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated VenueType Venuetypes = 16;
 * @return {!Array<!proto.models.VenueType>}
 */
proto.models.Venue.prototype.getVenuetypesList = function() {
  return /** @type{!Array<!proto.models.VenueType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.VenueType, 16));
};


/** @param {!Array<!proto.models.VenueType>} value */
proto.models.Venue.prototype.setVenuetypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.models.VenueType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.VenueType}
 */
proto.models.Venue.prototype.addVenuetypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.models.VenueType, opt_index);
};


proto.models.Venue.prototype.clearVenuetypesList = function() {
  this.setVenuetypesList([]);
};


/**
 * repeated VenueGallery Venuegalleries = 17;
 * @return {!Array<!proto.models.VenueGallery>}
 */
proto.models.Venue.prototype.getVenuegalleriesList = function() {
  return /** @type{!Array<!proto.models.VenueGallery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.VenueGallery, 17));
};


/** @param {!Array<!proto.models.VenueGallery>} value */
proto.models.Venue.prototype.setVenuegalleriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.models.VenueGallery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.VenueGallery}
 */
proto.models.Venue.prototype.addVenuegalleries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.models.VenueGallery, opt_index);
};


proto.models.Venue.prototype.clearVenuegalleriesList = function() {
  this.setVenuegalleriesList([]);
};


/**
 * optional string TownID = 18;
 * @return {string}
 */
proto.models.Venue.prototype.getTownid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.models.Venue.prototype.setTownid = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional Town Town = 19;
 * @return {?proto.models.Town}
 */
proto.models.Venue.prototype.getTown = function() {
  return /** @type{?proto.models.Town} */ (
    jspb.Message.getWrapperField(this, proto.models.Town, 19));
};


/** @param {?proto.models.Town|undefined} value */
proto.models.Venue.prototype.setTown = function(value) {
  jspb.Message.setWrapperField(this, 19, value);
};


proto.models.Venue.prototype.clearTown = function() {
  this.setTown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Venue.prototype.hasTown = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated Event Events = 20;
 * @return {!Array<!proto.models.Event>}
 */
proto.models.Venue.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.models.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Event, 20));
};


/** @param {!Array<!proto.models.Event>} value */
proto.models.Venue.prototype.setEventsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.models.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Event}
 */
proto.models.Venue.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.models.Event, opt_index);
};


proto.models.Venue.prototype.clearEventsList = function() {
  this.setEventsList([]);
};


/**
 * repeated Showtime Showtimes = 21;
 * @return {!Array<!proto.models.Showtime>}
 */
proto.models.Venue.prototype.getShowtimesList = function() {
  return /** @type{!Array<!proto.models.Showtime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Showtime, 21));
};


/** @param {!Array<!proto.models.Showtime>} value */
proto.models.Venue.prototype.setShowtimesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.models.Showtime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Showtime}
 */
proto.models.Venue.prototype.addShowtimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.models.Showtime, opt_index);
};


proto.models.Venue.prototype.clearShowtimesList = function() {
  this.setShowtimesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.VenueType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.VenueType.repeatedFields_, null);
};
goog.inherits(proto.models.VenueType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.VenueType.displayName = 'proto.models.VenueType';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.VenueType.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.VenueType.prototype.toObject = function(opt_includeInstance) {
  return proto.models.VenueType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.VenueType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.VenueType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    venuesList: jspb.Message.toObjectList(msg.getVenuesList(),
    proto.models.Venue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.VenueType}
 */
proto.models.VenueType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.VenueType;
  return proto.models.VenueType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.VenueType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.VenueType}
 */
proto.models.VenueType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = new proto.models.Venue;
      reader.readMessage(value,proto.models.Venue.deserializeBinaryFromReader);
      msg.addVenues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.VenueType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.VenueType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.VenueType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.VenueType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVenuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.models.Venue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.VenueType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.VenueType.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.VenueType.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.VenueType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Icon = 5;
 * @return {string}
 */
proto.models.VenueType.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setIcon = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Image = 6;
 * @return {string}
 */
proto.models.VenueType.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.VenueType.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Venue Venues = 7;
 * @return {!Array<!proto.models.Venue>}
 */
proto.models.VenueType.prototype.getVenuesList = function() {
  return /** @type{!Array<!proto.models.Venue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Venue, 7));
};


/** @param {!Array<!proto.models.Venue>} value */
proto.models.VenueType.prototype.setVenuesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.models.Venue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Venue}
 */
proto.models.VenueType.prototype.addVenues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.models.Venue, opt_index);
};


proto.models.VenueType.prototype.clearVenuesList = function() {
  this.setVenuesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.VenueGallery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.VenueGallery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.VenueGallery.displayName = 'proto.models.VenueGallery';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.VenueGallery.prototype.toObject = function(opt_includeInstance) {
  return proto.models.VenueGallery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.VenueGallery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.VenueGallery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    venueid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    venue: (f = msg.getVenue()) && proto.models.Venue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.VenueGallery}
 */
proto.models.VenueGallery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.VenueGallery;
  return proto.models.VenueGallery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.VenueGallery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.VenueGallery}
 */
proto.models.VenueGallery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueid(value);
      break;
    case 6:
      var value = new proto.models.Venue;
      reader.readMessage(value,proto.models.Venue.deserializeBinaryFromReader);
      msg.setVenue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.VenueGallery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.VenueGallery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.VenueGallery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.VenueGallery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVenueid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVenue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.models.Venue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.VenueGallery.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.VenueGallery.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.VenueGallery.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.VenueGallery.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.VenueGallery.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.VenueGallery.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Image = 4;
 * @return {string}
 */
proto.models.VenueGallery.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.VenueGallery.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string VenueID = 5;
 * @return {string}
 */
proto.models.VenueGallery.prototype.getVenueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.VenueGallery.prototype.setVenueid = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Venue Venue = 6;
 * @return {?proto.models.Venue}
 */
proto.models.VenueGallery.prototype.getVenue = function() {
  return /** @type{?proto.models.Venue} */ (
    jspb.Message.getWrapperField(this, proto.models.Venue, 6));
};


/** @param {?proto.models.Venue|undefined} value */
proto.models.VenueGallery.prototype.setVenue = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.models.VenueGallery.prototype.clearVenue = function() {
  this.setVenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.VenueGallery.prototype.hasVenue = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Town = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Town.repeatedFields_, null);
};
goog.inherits(proto.models.Town, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Town.displayName = 'proto.models.Town';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Town.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Town.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Town.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Town} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Town.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    latlng: jspb.Message.getFieldWithDefault(msg, 5, ""),
    region: jspb.Message.getFieldWithDefault(msg, 6, ""),
    venuesList: jspb.Message.toObjectList(msg.getVenuesList(),
    proto.models.Venue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Town}
 */
proto.models.Town.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Town;
  return proto.models.Town.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Town} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Town}
 */
proto.models.Town.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatlng(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 7:
      var value = new proto.models.Venue;
      reader.readMessage(value,proto.models.Venue.deserializeBinaryFromReader);
      msg.addVenues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Town.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Town.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Town} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Town.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLatlng();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVenuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.models.Venue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Town.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Town.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Town.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Town.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string LatLng = 5;
 * @return {string}
 */
proto.models.Town.prototype.getLatlng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setLatlng = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Region = 6;
 * @return {string}
 */
proto.models.Town.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Town.prototype.setRegion = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Venue Venues = 7;
 * @return {!Array<!proto.models.Venue>}
 */
proto.models.Town.prototype.getVenuesList = function() {
  return /** @type{!Array<!proto.models.Venue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Venue, 7));
};


/** @param {!Array<!proto.models.Venue>} value */
proto.models.Town.prototype.setVenuesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.models.Venue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Venue}
 */
proto.models.Town.prototype.addVenues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.models.Venue, opt_index);
};


proto.models.Town.prototype.clearVenuesList = function() {
  this.setVenuesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Movie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Movie.repeatedFields_, null);
};
goog.inherits(proto.models.Movie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Movie.displayName = 'proto.models.Movie';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Movie.repeatedFields_ = [23,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Movie.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Movie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Movie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Movie.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 7, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isfeatured: jspb.Message.getFieldWithDefault(msg, 10, false),
    weight: jspb.Message.getFieldWithDefault(msg, 11, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    trailer: jspb.Message.getFieldWithDefault(msg, 13, ""),
    audio: jspb.Message.getFieldWithDefault(msg, 14, ""),
    image: jspb.Message.getFieldWithDefault(msg, 15, ""),
    year: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cast: jspb.Message.getFieldWithDefault(msg, 17, ""),
    writer: jspb.Message.getFieldWithDefault(msg, 18, ""),
    director: jspb.Message.getFieldWithDefault(msg, 19, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 20, ""),
    contentrating: jspb.Message.getFieldWithDefault(msg, 21, ""),
    opinion: jspb.Message.getFieldWithDefault(msg, 22, ""),
    genresList: jspb.Message.toObjectList(msg.getGenresList(),
    proto.models.Genre.toObject, includeInstance),
    showtimesList: jspb.Message.toObjectList(msg.getShowtimesList(),
    proto.models.Showtime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Movie}
 */
proto.models.Movie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Movie;
  return proto.models.Movie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Movie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Movie}
 */
proto.models.Movie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfeatured(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailer(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAudio(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCast(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setWriter(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirector(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRating(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentrating(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpinion(value);
      break;
    case 23:
      var value = new proto.models.Genre;
      reader.readMessage(value,proto.models.Genre.deserializeBinaryFromReader);
      msg.addGenres(value);
      break;
    case 24:
      var value = new proto.models.Showtime;
      reader.readMessage(value,proto.models.Showtime.deserializeBinaryFromReader);
      msg.addShowtimes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Movie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Movie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Movie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Movie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsfeatured();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTrailer();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAudio();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCast();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getWriter();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDirector();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getRating();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getContentrating();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getOpinion();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGenresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.models.Genre.serializeBinaryToWriter
    );
  }
  f = message.getShowtimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.models.Showtime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Movie.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Movie.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Slug = 3;
 * @return {string}
 */
proto.models.Movie.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.models.Movie.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Movie.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Movie.prototype.setCreatedat = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.models.Movie.prototype.clearCreatedat = function() {
  this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Movie.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Movie.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Movie.prototype.setUpdatedat = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.models.Movie.prototype.clearUpdatedat = function() {
  this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Movie.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Title = 7;
 * @return {string}
 */
proto.models.Movie.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Summary = 8;
 * @return {string}
 */
proto.models.Movie.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setSummary = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Description = 9;
 * @return {string}
 */
proto.models.Movie.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool IsFeatured = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.models.Movie.prototype.getIsfeatured = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};


/** @param {boolean} value */
proto.models.Movie.prototype.setIsfeatured = function(value) {
  jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 Weight = 11;
 * @return {number}
 */
proto.models.Movie.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.models.Movie.prototype.setWeight = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 Duration = 12;
 * @return {number}
 */
proto.models.Movie.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.models.Movie.prototype.setDuration = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string Trailer = 13;
 * @return {string}
 */
proto.models.Movie.prototype.getTrailer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setTrailer = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string Audio = 14;
 * @return {string}
 */
proto.models.Movie.prototype.getAudio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setAudio = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Image = 15;
 * @return {string}
 */
proto.models.Movie.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Year = 16;
 * @return {string}
 */
proto.models.Movie.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setYear = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string Cast = 17;
 * @return {string}
 */
proto.models.Movie.prototype.getCast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setCast = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string Writer = 18;
 * @return {string}
 */
proto.models.Movie.prototype.getWriter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setWriter = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string Director = 19;
 * @return {string}
 */
proto.models.Movie.prototype.getDirector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setDirector = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string Rating = 20;
 * @return {string}
 */
proto.models.Movie.prototype.getRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setRating = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string ContentRating = 21;
 * @return {string}
 */
proto.models.Movie.prototype.getContentrating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setContentrating = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string Opinion = 22;
 * @return {string}
 */
proto.models.Movie.prototype.getOpinion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.models.Movie.prototype.setOpinion = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated Genre Genres = 23;
 * @return {!Array<!proto.models.Genre>}
 */
proto.models.Movie.prototype.getGenresList = function() {
  return /** @type{!Array<!proto.models.Genre>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Genre, 23));
};


/** @param {!Array<!proto.models.Genre>} value */
proto.models.Movie.prototype.setGenresList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.models.Genre=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Genre}
 */
proto.models.Movie.prototype.addGenres = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.models.Genre, opt_index);
};


proto.models.Movie.prototype.clearGenresList = function() {
  this.setGenresList([]);
};


/**
 * repeated Showtime Showtimes = 24;
 * @return {!Array<!proto.models.Showtime>}
 */
proto.models.Movie.prototype.getShowtimesList = function() {
  return /** @type{!Array<!proto.models.Showtime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Showtime, 24));
};


/** @param {!Array<!proto.models.Showtime>} value */
proto.models.Movie.prototype.setShowtimesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.models.Showtime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Showtime}
 */
proto.models.Movie.prototype.addShowtimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.models.Showtime, opt_index);
};


proto.models.Movie.prototype.clearShowtimesList = function() {
  this.setShowtimesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Genre = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Genre.repeatedFields_, null);
};
goog.inherits(proto.models.Genre, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Genre.displayName = 'proto.models.Genre';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Genre.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Genre.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Genre.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Genre} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Genre.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    moviesList: jspb.Message.toObjectList(msg.getMoviesList(),
    proto.models.Movie.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Genre}
 */
proto.models.Genre.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Genre;
  return proto.models.Genre.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Genre} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Genre}
 */
proto.models.Genre.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.models.Movie;
      reader.readMessage(value,proto.models.Movie.deserializeBinaryFromReader);
      msg.addMovies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Genre.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Genre.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Genre} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Genre.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMoviesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.models.Movie.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Genre.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Genre.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Genre.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Genre.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Genre.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Genre.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Genre.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Genre.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Movie Movies = 5;
 * @return {!Array<!proto.models.Movie>}
 */
proto.models.Genre.prototype.getMoviesList = function() {
  return /** @type{!Array<!proto.models.Movie>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Movie, 5));
};


/** @param {!Array<!proto.models.Movie>} value */
proto.models.Genre.prototype.setMoviesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.models.Movie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Movie}
 */
proto.models.Genre.prototype.addMovies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.models.Movie, opt_index);
};


proto.models.Genre.prototype.clearMoviesList = function() {
  this.setMoviesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Showtime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.Showtime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Showtime.displayName = 'proto.models.Showtime';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Showtime.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Showtime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Showtime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Showtime.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    screen: jspb.Message.getFieldWithDefault(msg, 5, ""),
    startat: (f = msg.getStartat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    threed: jspb.Message.getFieldWithDefault(msg, 7, false),
    language: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 9, ""),
    venueid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    venue: (f = msg.getVenue()) && proto.models.Venue.toObject(includeInstance, f),
    movieid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    movie: (f = msg.getMovie()) && proto.models.Movie.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Showtime}
 */
proto.models.Showtime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Showtime;
  return proto.models.Showtime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Showtime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Showtime}
 */
proto.models.Showtime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreen(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThreed(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueid(value);
      break;
    case 11:
      var value = new proto.models.Venue;
      reader.readMessage(value,proto.models.Venue.deserializeBinaryFromReader);
      msg.setVenue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMovieid(value);
      break;
    case 13:
      var value = new proto.models.Movie;
      reader.readMessage(value,proto.models.Movie.deserializeBinaryFromReader);
      msg.setMovie(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Showtime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Showtime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Showtime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Showtime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScreen();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getThreed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVenueid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVenue();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.models.Venue.serializeBinaryToWriter
    );
  }
  f = message.getMovieid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMovie();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.models.Movie.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Showtime.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Showtime.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Showtime.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Showtime.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Showtime.prototype.setUpdatedat = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.models.Showtime.prototype.clearUpdatedat = function() {
  this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Showtime.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Screen = 5;
 * @return {string}
 */
proto.models.Showtime.prototype.getScreen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setScreen = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp StartAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Showtime.prototype.getStartat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Showtime.prototype.setStartat = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.models.Showtime.prototype.clearStartat = function() {
  this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Showtime.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool ThreeD = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.models.Showtime.prototype.getThreed = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.models.Showtime.prototype.setThreed = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string Language = 8;
 * @return {string}
 */
proto.models.Showtime.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setLanguage = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Subtitle = 9;
 * @return {string}
 */
proto.models.Showtime.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setSubtitle = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string VenueID = 10;
 * @return {string}
 */
proto.models.Showtime.prototype.getVenueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setVenueid = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Venue Venue = 11;
 * @return {?proto.models.Venue}
 */
proto.models.Showtime.prototype.getVenue = function() {
  return /** @type{?proto.models.Venue} */ (
    jspb.Message.getWrapperField(this, proto.models.Venue, 11));
};


/** @param {?proto.models.Venue|undefined} value */
proto.models.Showtime.prototype.setVenue = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.models.Showtime.prototype.clearVenue = function() {
  this.setVenue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Showtime.prototype.hasVenue = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string MovieID = 12;
 * @return {string}
 */
proto.models.Showtime.prototype.getMovieid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.models.Showtime.prototype.setMovieid = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Movie Movie = 13;
 * @return {?proto.models.Movie}
 */
proto.models.Showtime.prototype.getMovie = function() {
  return /** @type{?proto.models.Movie} */ (
    jspb.Message.getWrapperField(this, proto.models.Movie, 13));
};


/** @param {?proto.models.Movie|undefined} value */
proto.models.Showtime.prototype.setMovie = function(value) {
  jspb.Message.setWrapperField(this, 13, value);
};


proto.models.Showtime.prototype.clearMovie = function() {
  this.setMovie(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Showtime.prototype.hasMovie = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Recipe = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Recipe.repeatedFields_, null);
};
goog.inherits(proto.models.Recipe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Recipe.displayName = 'proto.models.Recipe';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Recipe.repeatedFields_ = [23,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Recipe.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Recipe.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Recipe} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Recipe.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isfeatured: jspb.Message.getFieldWithDefault(msg, 8, false),
    weight: jspb.Message.getFieldWithDefault(msg, 9, 0),
    image: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ingredients: jspb.Message.getFieldWithDefault(msg, 11, ""),
    diet: jspb.Message.getFieldWithDefault(msg, 12, ""),
    cuisine: jspb.Message.getFieldWithDefault(msg, 13, ""),
    spicelevel: jspb.Message.getFieldWithDefault(msg, 14, ""),
    difficulty: jspb.Message.getFieldWithDefault(msg, 15, ""),
    category: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cookingmethod: jspb.Message.getFieldWithDefault(msg, 17, ""),
    servings: jspb.Message.getFieldWithDefault(msg, 18, ""),
    preparationtime: jspb.Message.getFieldWithDefault(msg, 19, ""),
    cookingtime: jspb.Message.getFieldWithDefault(msg, 20, ""),
    video: jspb.Message.getFieldWithDefault(msg, 21, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 22, ""),
    recipeinstructionsList: jspb.Message.toObjectList(msg.getRecipeinstructionsList(),
    proto.models.RecipeInstruction.toObject, includeInstance),
    sponsorsList: jspb.Message.toObjectList(msg.getSponsorsList(),
    proto.models.Sponsor.toObject, includeInstance),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.models.Product.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Recipe}
 */
proto.models.Recipe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Recipe;
  return proto.models.Recipe.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Recipe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Recipe}
 */
proto.models.Recipe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfeatured(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIngredients(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiet(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCuisine(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpicelevel(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDifficulty(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCookingmethod(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setServings(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreparationtime(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCookingtime(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideo(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 23:
      var value = new proto.models.RecipeInstruction;
      reader.readMessage(value,proto.models.RecipeInstruction.deserializeBinaryFromReader);
      msg.addRecipeinstructions(value);
      break;
    case 24:
      var value = new proto.models.Sponsor;
      reader.readMessage(value,proto.models.Sponsor.deserializeBinaryFromReader);
      msg.addSponsors(value);
      break;
    case 25:
      var value = new proto.models.Product;
      reader.readMessage(value,proto.models.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Recipe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Recipe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Recipe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Recipe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsfeatured();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIngredients();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDiet();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCuisine();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSpicelevel();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDifficulty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCookingmethod();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getServings();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPreparationtime();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCookingtime();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getVideo();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRecipeinstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.models.RecipeInstruction.serializeBinaryToWriter
    );
  }
  f = message.getSponsorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.models.Sponsor.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.models.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Recipe.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Recipe.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Slug = 3;
 * @return {string}
 */
proto.models.Recipe.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setSlug = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserID = 4;
 * @return {string}
 */
proto.models.Recipe.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Recipe.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Recipe.prototype.setCreatedat = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.models.Recipe.prototype.clearCreatedat = function() {
  this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Recipe.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Recipe.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Recipe.prototype.setUpdatedat = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.models.Recipe.prototype.clearUpdatedat = function() {
  this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Recipe.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Name = 7;
 * @return {string}
 */
proto.models.Recipe.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool IsFeatured = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.models.Recipe.prototype.getIsfeatured = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.models.Recipe.prototype.setIsfeatured = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 Weight = 9;
 * @return {number}
 */
proto.models.Recipe.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.models.Recipe.prototype.setWeight = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string Image = 10;
 * @return {string}
 */
proto.models.Recipe.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Ingredients = 11;
 * @return {string}
 */
proto.models.Recipe.prototype.getIngredients = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setIngredients = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Diet = 12;
 * @return {string}
 */
proto.models.Recipe.prototype.getDiet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setDiet = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string Cuisine = 13;
 * @return {string}
 */
proto.models.Recipe.prototype.getCuisine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setCuisine = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string SpiceLevel = 14;
 * @return {string}
 */
proto.models.Recipe.prototype.getSpicelevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setSpicelevel = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Difficulty = 15;
 * @return {string}
 */
proto.models.Recipe.prototype.getDifficulty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setDifficulty = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Category = 16;
 * @return {string}
 */
proto.models.Recipe.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setCategory = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string CookingMethod = 17;
 * @return {string}
 */
proto.models.Recipe.prototype.getCookingmethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setCookingmethod = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string Servings = 18;
 * @return {string}
 */
proto.models.Recipe.prototype.getServings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setServings = function(value) {
  jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string PreparationTime = 19;
 * @return {string}
 */
proto.models.Recipe.prototype.getPreparationtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setPreparationtime = function(value) {
  jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string CookingTime = 20;
 * @return {string}
 */
proto.models.Recipe.prototype.getCookingtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setCookingtime = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string Video = 21;
 * @return {string}
 */
proto.models.Recipe.prototype.getVideo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setVideo = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string Notes = 22;
 * @return {string}
 */
proto.models.Recipe.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/** @param {string} value */
proto.models.Recipe.prototype.setNotes = function(value) {
  jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated RecipeInstruction Recipeinstructions = 23;
 * @return {!Array<!proto.models.RecipeInstruction>}
 */
proto.models.Recipe.prototype.getRecipeinstructionsList = function() {
  return /** @type{!Array<!proto.models.RecipeInstruction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.RecipeInstruction, 23));
};


/** @param {!Array<!proto.models.RecipeInstruction>} value */
proto.models.Recipe.prototype.setRecipeinstructionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.models.RecipeInstruction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.RecipeInstruction}
 */
proto.models.Recipe.prototype.addRecipeinstructions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.models.RecipeInstruction, opt_index);
};


proto.models.Recipe.prototype.clearRecipeinstructionsList = function() {
  this.setRecipeinstructionsList([]);
};


/**
 * repeated Sponsor Sponsors = 24;
 * @return {!Array<!proto.models.Sponsor>}
 */
proto.models.Recipe.prototype.getSponsorsList = function() {
  return /** @type{!Array<!proto.models.Sponsor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Sponsor, 24));
};


/** @param {!Array<!proto.models.Sponsor>} value */
proto.models.Recipe.prototype.setSponsorsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.models.Sponsor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Sponsor}
 */
proto.models.Recipe.prototype.addSponsors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.models.Sponsor, opt_index);
};


proto.models.Recipe.prototype.clearSponsorsList = function() {
  this.setSponsorsList([]);
};


/**
 * repeated Product Products = 25;
 * @return {!Array<!proto.models.Product>}
 */
proto.models.Recipe.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.models.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Product, 25));
};


/** @param {!Array<!proto.models.Product>} value */
proto.models.Recipe.prototype.setProductsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.models.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Product}
 */
proto.models.Recipe.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.models.Product, opt_index);
};


proto.models.Recipe.prototype.clearProductsList = function() {
  this.setProductsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.RecipeInstruction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.RecipeInstruction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.RecipeInstruction.displayName = 'proto.models.RecipeInstruction';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.RecipeInstruction.prototype.toObject = function(opt_includeInstance) {
  return proto.models.RecipeInstruction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.RecipeInstruction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.RecipeInstruction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    step: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    recipeid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recipe: (f = msg.getRecipe()) && proto.models.Recipe.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.RecipeInstruction}
 */
proto.models.RecipeInstruction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.RecipeInstruction;
  return proto.models.RecipeInstruction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.RecipeInstruction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.RecipeInstruction}
 */
proto.models.RecipeInstruction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStep(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipeid(value);
      break;
    case 8:
      var value = new proto.models.Recipe;
      reader.readMessage(value,proto.models.Recipe.deserializeBinaryFromReader);
      msg.setRecipe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.RecipeInstruction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.RecipeInstruction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.RecipeInstruction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.RecipeInstruction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRecipeid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecipe();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.models.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 Step = 4;
 * @return {number}
 */
proto.models.RecipeInstruction.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.models.RecipeInstruction.prototype.setStep = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Image = 6;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string RecipeID = 7;
 * @return {string}
 */
proto.models.RecipeInstruction.prototype.getRecipeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.RecipeInstruction.prototype.setRecipeid = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Recipe Recipe = 8;
 * @return {?proto.models.Recipe}
 */
proto.models.RecipeInstruction.prototype.getRecipe = function() {
  return /** @type{?proto.models.Recipe} */ (
    jspb.Message.getWrapperField(this, proto.models.Recipe, 8));
};


/** @param {?proto.models.Recipe|undefined} value */
proto.models.RecipeInstruction.prototype.setRecipe = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.models.RecipeInstruction.prototype.clearRecipe = function() {
  this.setRecipe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.RecipeInstruction.prototype.hasRecipe = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Sponsor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Sponsor.repeatedFields_, null);
};
goog.inherits(proto.models.Sponsor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Sponsor.displayName = 'proto.models.Sponsor';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Sponsor.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Sponsor.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Sponsor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Sponsor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Sponsor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    website: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recipesList: jspb.Message.toObjectList(msg.getRecipesList(),
    proto.models.Recipe.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Sponsor}
 */
proto.models.Sponsor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Sponsor;
  return proto.models.Sponsor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Sponsor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Sponsor}
 */
proto.models.Sponsor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 8:
      var value = new proto.models.Recipe;
      reader.readMessage(value,proto.models.Recipe.deserializeBinaryFromReader);
      msg.addRecipes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Sponsor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Sponsor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Sponsor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Sponsor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecipesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.models.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Sponsor.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Sponsor.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Sponsor.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Sponsor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.models.Sponsor.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Image = 6;
 * @return {string}
 */
proto.models.Sponsor.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Website = 7;
 * @return {string}
 */
proto.models.Sponsor.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Sponsor.prototype.setWebsite = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Recipe Recipes = 8;
 * @return {!Array<!proto.models.Recipe>}
 */
proto.models.Sponsor.prototype.getRecipesList = function() {
  return /** @type{!Array<!proto.models.Recipe>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Recipe, 8));
};


/** @param {!Array<!proto.models.Recipe>} value */
proto.models.Sponsor.prototype.setRecipesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.models.Recipe=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Recipe}
 */
proto.models.Sponsor.prototype.addRecipes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.models.Recipe, opt_index);
};


proto.models.Sponsor.prototype.clearRecipesList = function() {
  this.setRecipesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.models.Product.repeatedFields_, null);
};
goog.inherits(proto.models.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Product.displayName = 'proto.models.Product';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.models.Product.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    website: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recipesList: jspb.Message.toObjectList(msg.getRecipesList(),
    proto.models.Recipe.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Product}
 */
proto.models.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Product;
  return proto.models.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Product}
 */
proto.models.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 8:
      var value = new proto.models.Recipe;
      reader.readMessage(value,proto.models.Recipe.deserializeBinaryFromReader);
      msg.addRecipes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecipesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.models.Recipe.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Product.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Product.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.models.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Image = 6;
 * @return {string}
 */
proto.models.Product.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Website = 7;
 * @return {string}
 */
proto.models.Product.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Product.prototype.setWebsite = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Recipe Recipes = 8;
 * @return {!Array<!proto.models.Recipe>}
 */
proto.models.Product.prototype.getRecipesList = function() {
  return /** @type{!Array<!proto.models.Recipe>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.models.Recipe, 8));
};


/** @param {!Array<!proto.models.Recipe>} value */
proto.models.Product.prototype.setRecipesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.models.Recipe=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Recipe}
 */
proto.models.Product.prototype.addRecipes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.models.Recipe, opt_index);
};


proto.models.Product.prototype.clearRecipesList = function() {
  this.setRecipesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Banner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.Banner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Banner.displayName = 'proto.models.Banner';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Banner.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Banner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Banner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Banner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bannertype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    entity: jspb.Message.getFieldWithDefault(msg, 6, ""),
    entitytype: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Banner}
 */
proto.models.Banner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Banner;
  return proto.models.Banner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Banner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Banner}
 */
proto.models.Banner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBannertype(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Banner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Banner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Banner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Banner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBannertype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEntity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Banner.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Banner.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Banner.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string BannerType = 4;
 * @return {string}
 */
proto.models.Banner.prototype.getBannertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setBannertype = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 Weight = 5;
 * @return {number}
 */
proto.models.Banner.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.models.Banner.prototype.setWeight = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string Entity = 6;
 * @return {string}
 */
proto.models.Banner.prototype.getEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setEntity = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string EntityType = 7;
 * @return {string}
 */
proto.models.Banner.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Banner.prototype.setEntitytype = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Ads = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.Ads, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Ads.displayName = 'proto.models.Ads';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Ads.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Ads.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Ads} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Ads.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    image: jspb.Message.getFieldWithDefault(msg, 6, ""),
    website: jspb.Message.getFieldWithDefault(msg, 7, ""),
    startat: (f = msg.getStartat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endat: (f = msg.getEndat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adstype: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sponsorid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sponsor: (f = msg.getSponsor()) && proto.models.Sponsor.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Ads}
 */
proto.models.Ads.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Ads;
  return proto.models.Ads.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Ads} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Ads}
 */
proto.models.Ads.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdstype(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsorid(value);
      break;
    case 12:
      var value = new proto.models.Sponsor;
      reader.readMessage(value,proto.models.Sponsor.deserializeBinaryFromReader);
      msg.setSponsor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Ads.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Ads.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Ads} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Ads.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStartat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAdstype();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSponsorid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSponsor();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.models.Sponsor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Ads.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Ads.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Ads.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Ads.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Description = 5;
 * @return {string}
 */
proto.models.Ads.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Image = 6;
 * @return {string}
 */
proto.models.Ads.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Website = 7;
 * @return {string}
 */
proto.models.Ads.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setWebsite = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp StartAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Ads.prototype.getStartat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Ads.prototype.setStartat = function(value) {
  jspb.Message.setWrapperField(this, 8, value);
};


proto.models.Ads.prototype.clearStartat = function() {
  this.setStartat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Ads.prototype.hasStartat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp EndAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Ads.prototype.getEndat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Ads.prototype.setEndat = function(value) {
  jspb.Message.setWrapperField(this, 9, value);
};


proto.models.Ads.prototype.clearEndat = function() {
  this.setEndat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Ads.prototype.hasEndat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string AdsType = 10;
 * @return {string}
 */
proto.models.Ads.prototype.getAdstype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setAdstype = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string SponsorID = 11;
 * @return {string}
 */
proto.models.Ads.prototype.getSponsorid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.models.Ads.prototype.setSponsorid = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Sponsor Sponsor = 12;
 * @return {?proto.models.Sponsor}
 */
proto.models.Ads.prototype.getSponsor = function() {
  return /** @type{?proto.models.Sponsor} */ (
    jspb.Message.getWrapperField(this, proto.models.Sponsor, 12));
};


/** @param {?proto.models.Sponsor|undefined} value */
proto.models.Ads.prototype.setSponsor = function(value) {
  jspb.Message.setWrapperField(this, 12, value);
};


proto.models.Ads.prototype.clearSponsor = function() {
  this.setSponsor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Ads.prototype.hasSponsor = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Settings.displayName = 'proto.models.Settings';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Settings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, ""),
    value: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Settings}
 */
proto.models.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Settings;
  return proto.models.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Settings}
 */
proto.models.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Settings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Settings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Settings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Settings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Settings.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Settings.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.models.Settings.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Code = 5;
 * @return {string}
 */
proto.models.Settings.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setCode = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Icon = 6;
 * @return {string}
 */
proto.models.Settings.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setIcon = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Value = 7;
 * @return {string}
 */
proto.models.Settings.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setValue = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Description = 8;
 * @return {string}
 */
proto.models.Settings.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.models.Settings.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.models.Review = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.models.Review, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.models.Review.displayName = 'proto.models.Review';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.models.Review.prototype.toObject = function(opt_includeInstance) {
  return proto.models.Review.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.models.Review} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Review.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    image: jspb.Message.getFieldWithDefault(msg, 7, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.models.Review}
 */
proto.models.Review.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.models.Review;
  return proto.models.Review.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.models.Review} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.models.Review}
 */
proto.models.Review.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.models.Review.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.models.Review.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.models.Review} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.models.Review.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.models.Review.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setId = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Status = 2;
 * @return {string}
 */
proto.models.Review.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string UserID = 3;
 * @return {string}
 */
proto.models.Review.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setUserid = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.models.Review.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.models.Review.prototype.setCreatedat = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.models.Review.prototype.clearCreatedat = function() {
  this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.models.Review.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string Title = 5;
 * @return {string}
 */
proto.models.Review.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string URL = 6;
 * @return {string}
 */
proto.models.Review.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setUrl = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Image = 7;
 * @return {string}
 */
proto.models.Review.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.models.Review.prototype.setImage = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 Weight = 8;
 * @return {number}
 */
proto.models.Review.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.models.Review.prototype.setWeight = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.models);
