function setReviews(state, payload) {
  state.reviews = payload.data;
}

function setVenueTypes(state, payload) {
  state.venueTypes = payload.data;
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

export default {
  setReviews,
  hasFetched,
  setVenueTypes
};
