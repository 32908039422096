<template>
  <div class="content-container">
    <div class="container-fw">
      <div class="title-section__wrapper">
        <h2>Ki bon baz?</h2>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>Ki bon baz?</li>
        </ul>
      </div>
      <!-- Breadcrumb -->

      <div class="search-intersection-wrapper" v-observe-visibility="{ callback: visibilityChanged, intersection: { rootMargin: '0px'}}">
        <div class="search-box__container" :class="{ 'is-fixed' : isVisible === false, 'not-fixed' : isVisible === true, 'open-drawer': activatedDrawer }">
          <button class="activate-filter" @click="activatedDrawer = !activatedDrawer">Filtre</button>
          <div class="search-box__input">
            <input type="text" name="keyword" placeholder="Mot-clé du venue/lieu" class="input"  v-on:keyup.enter="executeSearch()" @focus="keywordError === 'error'" v-model="keyword">
          </div>
          <div class="search-box-filter__town">
            <select name="filter-town" v-model="townSelected">
              <option value="">-- Choisir --</option>
              <option
                  v-for="(town, index) in getTowns" :value="town.getId()">
                {{town.getName()}}
              </option>
            </select>
          </div>
          <div class="search-box-filter__venuetype">
            <select name="filter-venuetype" v-model="venueTypeSelected">
              <option value="">-- Choisir --</option>
              <option
                  v-for="(venue, index) in getVenueTypes"
                  :value="venue.getId()">
                {{venue.getName()}}
              </option>
            </select>
          </div>
          <div class="search-box__submit">
            <button class="submit__button" @click="executeSearch()">
              <i class="material-icons">
                search
              </i>
            </button>
          </div>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <component :is="currentComponent" ></component>
      </transition>

    </div>
  </div>
</template>

<script>
import ReviewsComponent from "@/components/shared/Reviews";
import ResultsComponent from "@/components/venues/ResultsComponent";
import Searching from "@/components/venues/Searching";
import { mapGetters } from "vuex";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  data: function() {
    return {
      activatedDrawer: false,
      isVisible: false,
      keyword: "",
      venueTypeSelected: "",
      townSelected: "",
      currentComponent: "reviews",
      keywordError: null,
      townError: null,
      typeError: null
    };
  },
  components: {
    reviews: ReviewsComponent,
    results: ResultsComponent,
    searching: Searching
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      getVenueTypes: "venues/getVenueTypes",
      getTowns: "venues/getTowns",
      getHasSearched: "venues/getHasSearched",
      getVenues: "venues/getVenues",
      getSearchStatus: "venues/getSearchStatus"
    })
  },
  metaInfo() {
    // if no subcomponents specify a metaInfo.title, this title will be used
    return {
      title: this.loading.state ? "Loading..." : "Ki bon baz?",
      // all titles will be injected into this template
      titleTemplate: "%s | LexpressCulture"
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
    executeSearch() {
      this.$store.dispatch({
        type: "venues/setCurrentTown",
        data: this.townSelected
      });

      this.$store.dispatch({
        type: "venues/setCurrentVenueType",
        data: this.venueTypeSelected
      });

      this.$store.dispatch({
        type: "venues/setCurrentKeyword",
        data: this.keyword
      });

      let keyword = this.keyword.trim();
      if (keyword.length === 0) {
        console.log("error");
        this.keywordError = "error";
      } else {
        this.keywordError = "";
        this.$store.dispatch("venues/search");
      }

      if (
        (keyword.length === 0 &&
          this.townSelected !== "" &&
          this.venueTypeSelected !== "") ||
        (keyword.length === 0 &&
          this.townSelected !== "" &&
          this.venueTypeSelected === "") ||
        (keyword.length === 0 &&
          this.townSelected === "" &&
          this.venueTypeSelected !== "")
      ) {
        this.$store.dispatch("venues/search");
      }
    }
  },
  beforeMount() {
    this.$store.dispatch("venues/load");
  },
  watch: {
    getSearchStatus(val) {
      if (val === 1) {
        this.currentComponent = "searching";
      } else if (val === 0) {
        setTimeout(() => {
          this.currentComponent = "results";
        }, 800);
        setTimeout(() => {
          this.$scrollTo("#results__container", 400);
        }, 1200);
      }
    },
    keyword(val) {
      if (val.trim().length === 0) {
        this.keywordError = "error";
      } else {
        this.keywordError = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.results-reviews-results__container {
  margin-right: 40px;
}

.search-intersection-wrapper {
  height: 72px;
  position: relative;
}

/*@keyframes slide-in {*/
/*0% {*/
/*transform: translateY(0px);*/
/*}*/
/*100% {*/
/*transform: translateY(80px);*/
/*}*/
/*}*/

.search-box__container {
  padding: $gutter/3;
  margin-bottom: $gutter/2;
  margin-top: $gutter/2;
  display: grid;
  grid-template-columns: 1fr 0.3fr 0.3fr 60px;
  grid-gap: $gutter/2;
  background: $color-grey;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;

  .activate-filter {
    position: absolute;
    height: 50px;
    top: -50px;
    right: 20px;
    border: 0;
    padding: 0 20px;
    background: $color-event-bleu;
    color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: none;

    &:focus {
      outline: none;
    }
  }

  &.is-fixed {
    position: fixed;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    top: 0px;
    z-index: 999;
    transform: translateY(80px);

    /*animation-name: slide-in;*/
    /*animation-timing-function: ease-in-out;*/
    /*animation-duration: 0.2s;*/
    /*animation-fill-mode: forwards;*/
    /*animation-iteration-count: 1;*/
  }

  .search-box__input {
    .input {
      width: 100%;
      height: 45px;
      border-radius: 3px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
      padding: 0 $gutter/2;
      border-width: 1px;
      border-style: solid;
      border-color: $color-light-grey;
      transition: all 0.3s ease-in-out;
      font-weight: 300;

      &.error {
        border-color: red;

        &:focus {
          outline: none;
          border-color: red;
        }
      }

      &:focus {
        outline: none;
        border-color: $color-event-bleu;
      }
    }
  }
  .search-box-filter__town,
  .search-box-filter__venuetype {
    select {
      height: 45px;
      width: 100%;
      border: 1px solid $color-grey;
      font-weight: 300;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
      padding: 0 10px;

      &:focus {
        outline: none;
        border-color: $color-event-bleu;
      }
    }
  }
  .search-box-filter__venuetype {
  }
  .search-box__submit {
    .submit__button {
      height: 45px;
      width: 100%;
      border: 0;
      background: $color-event-bleu;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      i {
        color: var(--color-white);
      }

      &:hover {
        transform: scale(0.91);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .search-intersection-wrapper {
    height: 182px;
  }

  .search-box__container {
    grid-template-columns: 1fr 0.6fr 0.6fr 60px;
    transition: all 0.1s ease-in-out;

    &.is-fixed {
      bottom: 0;
      left: 0;
      /*height: 182px;*/
      top: auto;
      transform: translateY(100%);

      .activate-filter {
        display: block;
      }
    }

    &.open-drawer {
      transform: translateY(0%) !important;
    }
  }
}

@media (max-width: $tablet-portrait) {
  .search-box__container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: $gutter/4;

    .search-box__input {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    .search-box-filter__town {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    .search-box-filter__venuetype {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    .search-box__submit {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
  }
}

@media (max-width: $mobile) {
  .search-intersection-wrapper {
    height: 230px;
  }

  .search-box__container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    .search-box__input {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    .search-box-filter__town {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    .search-box-filter__venuetype {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }
    .search-box__submit {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
    }
  }
}
</style>
