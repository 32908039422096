// package: app
// file: service_app.proto

var service_app_pb = require("./service_app_pb");
var grpc = require("grpc-web-client").grpc;

var App = (function () {
  function App() {}
  App.serviceName = "app.App";
  return App;
}());

App.GetHomepage = {
  methodName: "GetHomepage",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EmptyRequest,
  responseType: service_app_pb.HomepageResponse
};

App.GetEvents = {
  methodName: "GetEvents",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EventListRequest,
  responseType: service_app_pb.EventListResponse
};

App.GetMovies = {
  methodName: "GetMovies",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.MovieListRequest,
  responseType: service_app_pb.MovieListResponse
};

App.GetRecipes = {
  methodName: "GetRecipes",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.RecipeListRequest,
  responseType: service_app_pb.RecipeListResponse
};

App.GetVenues = {
  methodName: "GetVenues",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VenueListRequest,
  responseType: service_app_pb.VenueListResponse
};

App.GetTheatres = {
  methodName: "GetTheatres",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VenueListRequest,
  responseType: service_app_pb.VenueListResponse
};

App.GetShowtimes = {
  methodName: "GetShowtimes",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.ShowtimeListRequest,
  responseType: service_app_pb.ShowtimeListResponse
};

App.GetVenueTypes = {
  methodName: "GetVenueTypes",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EmptyRequest,
  responseType: service_app_pb.VenueTypeListResponse
};

App.GetTowns = {
  methodName: "GetTowns",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EmptyRequest,
  responseType: service_app_pb.TownListResponse
};

App.GetReviews = {
  methodName: "GetReviews",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EmptyRequest,
  responseType: service_app_pb.ReviewsResponse
};

App.GetEvent = {
  methodName: "GetEvent",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EventRequest,
  responseType: service_app_pb.EventResponse
};

App.GetEventBySlug = {
  methodName: "GetEventBySlug",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.EventBySlugRequest,
  responseType: service_app_pb.EventResponse
};

App.GetMovie = {
  methodName: "GetMovie",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.MovieRequest,
  responseType: service_app_pb.MovieResponse
};

App.GetMovieBySlug = {
  methodName: "GetMovieBySlug",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.MovieBySlugRequest,
  responseType: service_app_pb.MovieResponse
};

App.GetRecipe = {
  methodName: "GetRecipe",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.RecipeRequest,
  responseType: service_app_pb.RecipeResponse
};

App.GetRecipeBySlug = {
  methodName: "GetRecipeBySlug",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.RecipeBySlugRequest,
  responseType: service_app_pb.RecipeResponse
};

App.GetVenue = {
  methodName: "GetVenue",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VenueRequest,
  responseType: service_app_pb.VenueResponse
};

App.GetVenueBySlug = {
  methodName: "GetVenueBySlug",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VenueBySlugRequest,
  responseType: service_app_pb.VenueResponse
};

exports.App = App;

function AppClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AppClient.prototype.getHomepage = function getHomepage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetHomepage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getEvents = function getEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getMovies = function getMovies(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetMovies, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getRecipes = function getRecipes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetRecipes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getVenues = function getVenues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetVenues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getTheatres = function getTheatres(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetTheatres, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getShowtimes = function getShowtimes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetShowtimes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getVenueTypes = function getVenueTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetVenueTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getTowns = function getTowns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetTowns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getReviews = function getReviews(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetReviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getEvent = function getEvent(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getEventBySlug = function getEventBySlug(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetEventBySlug, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getMovie = function getMovie(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetMovie, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getMovieBySlug = function getMovieBySlug(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetMovieBySlug, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getRecipe = function getRecipe(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetRecipe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getRecipeBySlug = function getRecipeBySlug(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetRecipeBySlug, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getVenue = function getVenue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetVenue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getVenueBySlug = function getVenueBySlug(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetVenueBySlug, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          callback(Object.assign(new Error(response.statusMessage), { code: response.status, metadata: response.trailers }), null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

exports.AppClient = AppClient;

