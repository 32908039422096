<template>
  <div class="content-section__wrapper">
    <template v-if="filtered != true">
      <div class="movies-listing__container" v-if="getMovies">
        <template v-for="movie in getMovies" v-if="movie.getId() != filter">
          <transition name="fade" mode="out-in">
            <MovieComponent :movie="movie"></MovieComponent>
          </transition>
        </template>
      </div>
    </template>
    <template v-else>
      <transition name="fade" mode="out-in">
        <component :filter="filter" :is="view"></component>
      </transition>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MovieComponent from "@/components/movie/MovieComponent";
import MovieResults from "@/components/movie/listing/MovieResults";
import MovieNoResults from "@/components/movie/listing/MovieNoResults";

export default {
  props: {
    filter: {
      default: null,
      type: String
    },
    max: {
      default: null,
      type: Number
    },
    filtered: {
      default: true,
      type: Boolean
    }
  },
  components: {
    MovieComponent,
    noResults: MovieNoResults,
    results: MovieResults
  },
  data() {
    return {
      view: "results"
    };
  },
  computed: {
    ...mapGetters({
      getMovies: "movies/getMovies",
      getMoviesFiltered: "movies/getMoviesFiltered",
      getResultsStatus: "movies/getResultsStatus"
    })
  },
  methods: {
    changeView(val) {
      this.view = val;
    }
  },
  beforeMount() {
    if (this.getMovies.length === 0 || this.getMovies.length === 1) {
      this.$store.dispatch("movies/load");
    }
  },
  watch: {
    getMoviesFiltered: {
      handler(val) {
        if (val.length === 0) {
          this.changeView("noResults");
        } else {
          this.changeView("results");
        }
      },
      deep: true
    }
  }
};
</script>


<style lang="scss" scoped>
.content-section__wrapper {
  margin-bottom: $gutter * 2;

  .movies-listing__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: $gutter/2;
  }

  .no-results__container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cccccc;
    font-size: 30px;
    margin-top: 40px;
    text-align: center;
  }
}

@media (max-width: $desktop) {
  .content-section__wrapper {
    .movies-listing__container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (max-width: $tablet) {
  .content-section__wrapper {
    .movies-listing__container {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $gutter/4;
    }

    .no-results__container {
      font-size: 20px;
    }
  }
}

@media (max-width: $mobile) {
  .content-section__wrapper {
    .movies-listing__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
