<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'" :size="'40px'"></moon-loader>
    <div v-if="!loading.status && event" class="container-fw">
      <div class="title-section__wrapper">
        <h2>{{event.getName()}}</h2>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>
            <router-link to="/evenements" title="Evenements">Ki bon plan?</router-link>
          </li>
          <li>{{event.getName()}}</li>
        </ul>
      </div>
      <!-- Breadcrumb -->
      <section class="generic-layout page-event">
        <div class="generic-content">
          <div class="section-container">
            <div class="section-description-wrapper">
              <div class="event-main-image">
                <figure>
                  <img v-if="event.getImage()" :src="event.getImage() + ':full'" alt>
                  <figcaption v-if="event.getName()">
                    <p>{{ event.getName() }}</p>
                  </figcaption>
                </figure>
                <div class="event-date-wrapper">
                  <div class="date-wrapper">
                    <div class="date-digit">
                      {{ event.getStartat().toDate() |
                      day }}
                    </div>
                    <div class="date-text">
                      {{ event.getStartat().toDate() |
                      month }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="generic-sidebar">
          <!-- Event details -->
          <div class="section-container">
            <div class="section-description-wrapper">
              <div class="event-details-wrapper">
                <div class="event-title-container">
                  <div class="event-title-wrapper">
                    <h2>{{ event.getName() }}</h2>
                    <div
                      class="event-organizer-details"
                      v-if="event.getOrganizer()"
                    >par {{ event.getOrganizer() }}</div>
                  </div>
                  <div class="event-date-wrapper">
                    <div class="date-wrapper">
                      <div class="date-digit">
                        {{ event.getStartat().toDate() |
                        day }}
                      </div>
                      <div class="date-text">
                        {{ event.getStartat().toDate() |
                        month }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="event-information-wrapper">
                  <div class="info-wrapper">
                    <div class="icon-wrapper">
                      <i class="material-icons">access_time</i>
                    </div>
                    <div class="title-wrapper">
                      <h4>Info</h4>
                      <div class="info-row-wrapper">
                        Le {{
                        event.getStartat().toDate() | date }} de {{
                        event.getStartat().toDate() | fromTime }} à {{
                        event.getEndat().toDate() | toTime }}
                      </div>
                    </div>
                  </div>
                  <div class="info-wrapper">
                    <div class="icon-wrapper">
                      <i class="material-icons">location_on</i>
                    </div>
                    <div class="title-wrapper">
                      <h4>Location</h4>
                      <div class="info-row-wrapper" v-if="event.getVenue()">
                        {{event.getVenue().getName()}}
                        <br>
                        <template v-if="event.getVenue().getAddr()">{{event.getVenue().getAddr()}}</template>
                        <br>
                        <template v-if="event.getVenue().getEmail()">
                          <a
                            :href="'mailto:' + event.getVenue().getEmail()"
                          >{{event.getVenue().getEmail()}}</a>
                        </template>
                        <br>
                        <template v-if="event.getVenue().getWebsite()">
                          <a :href="event.getVenue().getWebsite()">{{event.getVenue().getWebsite()}}</a>
                        </template>
                        <br>
                        <template v-if="event.getVenue().getContactnum()">
                          <a
                            :href="'tel:' + event.getVenue().getContactnum()"
                          >{{event.getVenue().getContactnum()}}</a>
                        </template>
                        <br>
                        <!--{{ event.venue.name }}, {{ event.venue.address }}-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Event details -->
        </div>
      </section>

      <section class="generic-layout page-event">
        <div class="generic-content">
          <div class="section-container">
            <div class="section-description-wrapper">
              <article v-html="event.getDescription()"></article>
            </div>
          </div>
          <div class="section-container" v-if="event.getVenue().getLatlng()">
            <div class="section-title-wrapper">
              <h2>Emplacement</h2>
            </div>
            <div class="section-description-wrapper">
              <div class="locations-wrapper">
                <!--<iframe :src="'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1395.729993253652!2d' + parseLocation(event.getVenue().getLatlng())[0] + '!3d' + parseLocation(event.getVenue().getLatlng())[1] + '!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjDCsDE0JzA1LjMiUyA1N8KwNDAnMzAuOSJF!5e0!3m2!1sen!2smu!4v1539261418116'" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>-->
                <gmap-map
                  v-if="marker !== null"
                  :center="marker"
                  :zoom="13"
                  map-type-id="satellite"
                  style="width:100%; height: 500px;"
                >
                  <gmap-marker v-if="marker !== null" :position="marker" @click="marker=marker"></gmap-marker>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <div class="generic-sidebar">
          <!-- Event details -->
          <div class="section-container">
            <div class="section-description-wrapper">
              <div class="section-title-wrapper">
                <h2>Événements phares</h2>
              </div>
              <div class="section-description-wrapper">
                <EvenementsListingWidget :filter="event.getId()"></EvenementsListingWidget>
              </div>
            </div>
          </div>
          <!-- Event details -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EvenementsListingWidget from "@/components/homepage/EvenementsListingWidget.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import moment from "moment";

export default {
  name: "Evenement",
  metaInfo() {
    return {
      title: this.loading.state
        ? "Loading..."
        : this.eventInfo
        ? this.eventInfo.getName()
        : "",
      titleTemplate: "%s | LexpressCulture",
    };
  },
  data() {
    return {
      eventInfo: null,
      marker: null,
      places: [],
      currentPosition: null,
    };
  },
  mounted() {},
  methods: {
    parseLocation(location) {
      if (location) {
        return location.split(",").reverse();
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      event: "events/getEvent",
    }),
  },
  components: {
    EvenementsListingWidget,
    MoonLoader,
  },
  watch: {
    event(val) {
      this.eventInfo = val;

      let position = this.event
        .getVenue()
        .getLatlng()
        .split(",")
        .reverse();

      const marker = {
        lat: parseFloat(position[0]),
        lng: parseFloat(position[1]),
      };

      this.marker = marker;
    },
  },
  beforeMount() {
    this.$store.dispatch("events/getSingleEventById");
    this.$store.dispatch("events/load");
  },
  filters: {
    date(date) {
      return moment(date)
        .locale("fr")
        .format("Do MMMM YYYY");
    },
    fromTime(date) {
      return moment(date)
        .locale("fr")
        .format("HH:mm");
    },
    toTime(date) {
      return moment(date)
        .locale("fr")
        .format("HH:mm");
    },
    day(date) {
      return moment(date)
        .locale("fr")
        .format("D");
    },
    month(date) {
      return moment(date)
        .locale("fr")
        .format("MMM");
    },
  },
};
</script>

<style lang="scss">
.event-main-image {
  position: relative;

  .event-date-wrapper {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    background: $color-main;
    display: none;
    align-items: center;
    justify-content: center;

    .date-wrapper {
      .date-digit,
      .date-text {
        color: $color-white;
        text-align: center;
      }
      .date-digit {
        font-weight: 500;
        font-size: 38px;
        line-height: 38px;
        letter-spacing: 0px;
      }
      .date-text {
        font-size: 13px;
      }
    }
  }

  figure {
    padding: 0;
    margin: 0;
    line-height: 0;

    img {
      margin: 0 !important;
      width: 100%;
      border: 0;
    }

    figcaption {
      //background: rgba($color-black, 0.2);
      color: rgba($color-black, 0.6);
      text-align: left;
      padding: $gutter/8 $gutter/4;
      background: rgba($color-grey, 0.5);

      p {
        margin: 0;
        font-size: 14px;
        padding: 12px 0;
      }
    }

    iframe {
      margin: 0;
      padding: 0;
    }
  }
}

article {
  width: 100%;
  font-weight: 300;

  h1 {
    font-family: $font-roboto;
    color: $color-black;
  }

  a {
    color: $color-main;
  }

  figure {
    padding: 0;
    margin: 0;
    line-height: 0;

    img {
      margin: 0 !important;
      width: 100%;
    }

    figcaption {
      //background: rgba($color-black, 0.2);
      color: $color-black;
      text-align: left;
      padding: $gutter/8 $gutter/4;
      background: $color-grey;

      p {
        margin: 0;
        font-size: 14px;
      }
    }

    iframe {
      margin: 0;
      padding: 0;
    }
  }

  p,
  ul,
  ol {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-black;
    font-family: $font-roboto;
    a {
      color: $color-black;
      text-decoration: none;
    }
  }

  hr {
    border-top: 0;
    border-bottom: 1px solid rgba($color-black, 0.2);
    border-left: 0px;
    margin: $gutter/2 0;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 20px;
    margin: $gutter/3 0;
    color: $color-black;

    li {
      padding-left: $gutter/3;
    }

    @for $i from 1 through 4 {
      ol,
      ul {
        padding-left: 30px;
      }
    }
  }

  ul {
    list-style-type: none;
    position: relative;

    li {
      position: relative;

      &:before {
        content: "";
        background: $color-main;
        height: 8px;
        width: 8px;
        border-radius: 5px;
        position: absolute;
        left: -14px;
        top: 10px;
        display: block;
        //margin-left: -7px;
        font-size: 9px;
      }
    }
  }

  blockquote {
    font-family: "Georgia";
    width: 100%;
    //float: right !important;
    right: 0;
    padding: $gutter - 10px;
    margin: 0;

    p {
      padding: 0;
      font-size: 18px;
      font-style: italic;
      position: relative;

      &:before,
      &:after {
        font-family: "Georgia";
        font-size: 60px;
        display: block;
        color: $color-black;
        font-style: initial;
      }

      &:before {
        position: absolute;
        content: "\201C";
        left: -25px;
        top: -10px;
      }

      &:after {
        position: absolute;
        content: "\201D";
        right: -15px;
      }
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  u {
    text-decoration: underline;
  }

  i,
  em {
    font-style: italic;
  }

  video,
  audio,
  iframe,
  embed,
  table {
    width: 100%;
  }

  table {
    border: 1px solid rgba($color-black, 0.2);
    thead,
    tfoot {
      border-bottom: 1px solid rgba($color-black, 0.2);
      background: $color-white;

      tr {
        td,
        th {
          padding: 10px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          border-right: 1px solid rgba($color-black, 0.2);

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid rgba($color-black, 0.2);

        &:last-child {
          border-bottom: 0px;
        }

        td {
          padding: 10px;
          font-weight: 300;
          text-align: left;
          text-transform: none;
          border-right: 1px solid rgba($color-black, 0.2);

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  iframe {
    margin: $gutter/2 0;
  }
}

.event-details-wrapper {
  .event-title-container {
    margin-bottom: $gutter/4;
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-column-gap: $gutter/4;

    .event-title-wrapper {
      width: 100%;

      h2 {
        margin: 0;
        font-size: 30px;
      }
    }
    .event-date-wrapper {
      width: 80px;
      height: 80px;
      background: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      .date-wrapper {
        .date-digit,
        .date-text {
          color: $color-white;
          text-align: center;
        }
        .date-digit {
          font-weight: 500;
          font-size: 38px;
          line-height: 38px;
          letter-spacing: 0px;
        }
        .date-text {
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
  }
  .event-organizer-details {
    font-size: 14px;
    color: rgba($color-black, 0.6);
    margin-bottom: $gutter/2;
  }
  .event-information-wrapper {
    .info-wrapper {
      display: grid;
      grid-template-columns: 1fr 9fr;
      margin-bottom: $gutter/3;

      .icon-wrapper {
        i {
          position: relative;
          top: 3px;
          opacity: 0.6;
        }
      }
      .title-wrapper {
        h4 {
          font-weight: 500;
          padding-top: 4px;
          margin: 0;
          text-transform: uppercase;
          margin-bottom: $gutter/3;
        }
        .info-row-wrapper {
          font-size: 15px;
          margin-bottom: $gutter/5;

          a {
            text-decoration: none;
            color: $color-main;
          }
        }
      }
    }
  }
}

.locations-wrapper {
  iframe {
    width: 100%;
  }
}

.other-events-container {
  width: 100%;

  .other-events-wrapper {
    width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    a {
      &.event-wrapper {
        width: calc(100% * 1 / 3);
        float: left;
        text-align: center;
        color: $color-white;
        text-decoration: none;
        padding: $gutter * 1.5 $gutter;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;

        h2 {
          font-size: 40px;
          font-weight: 500;
          margin: 0 0 $gutter/3 0;
        }

        .details-text-wrapper {
          opacity: 0;
          transform: translateY(30px);
          transition: all 0.4s ease-in-out;
          position: absolute;
          left: 50%;
          right: 50%;
        }

        &.first {
          background: darken($color-main, 10);
        }
        &.second {
          background: $color-main;
        }
        &.third {
          background: rgba($color-main, 0.9);
        }

        &:hover {
          .details-text-wrapper {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
  }
}

@media (max-width: $desktop) {
  .other-events-container {
    .other-events-wrapper {
      a {
        &.event-wrapper {
          padding: $gutter/1.5;
          h2 {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .other-events-container {
    .other-events-wrapper {
      a {
        &.event-wrapper {
          width: 100%;
          h2 {
            font-size: 25px;
            margin-bottom: 0;
          }
          .details-text-wrapper {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .event-details-wrapper {
    .event-title-container {
      width: 100%;
      margin-bottom: $gutter/4;

      .event-title-wrapper {
        width: 100%;
        margin-right: 0;
        float: none;
        clear: both;

        h2 {
          font-size: 25px;
        }
      }
      .event-date-wrapper {
        display: none;
      }
    }
  }

  .event-main-image {
    .event-date-wrapper {
      display: flex;
    }
  }

  .other-events-container {
    .other-events-wrapper {
      a {
        &.event-wrapper {
          width: 100%;
          h2 {
            font-size: 20px;
            margin-bottom: 0;
          }
          .details-text-wrapper {
            display: none;
          }
        }
      }
    }
  }
}
</style>
