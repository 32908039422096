<template>
  <transition name="slide-fade">
    <div class="jumbo-menu-container" v-if="showMenu">
      <div class="container-fw">
        <div class="utilities-wrapper">
          <button type="button" v-on:click="toggleShow">
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve">
            <g>
              <rect x="-4.164" y="9.532" transform="matrix(0.706 0.7082 -0.7082 0.706 10.0446 -4.1327)" width="28.329" height="1"></rect>
            </g>
            <g>
              <rect x="9.5" y="-4.142" transform="matrix(0.7071 0.7071 -0.7071 0.7071 10 -4.1421)" width="1" height="28.284"></rect>
            </g>
          </svg>
          </button>
        </div>
        <div class="menu-content-wrapper">
          <div class="links-wrapper">
            <ul>
              <li data-menu-section="Politique">
                <a href="https://www.lexpress.mu/politique">Politique</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/politique">Politique home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/politique/lexpress-leaks">L&#039;express leaks</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Société">
                <a href="https://www.lexpress.mu/societe">Société</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/societe">Société home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/faits-divers">Faits divers</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/police-et-justice">Police et justice</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/solidarite">Solidarité</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/education">Éducation</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/infrastructures">Infrastructures</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/environnement">Environnement</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/sante">Santé</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/who-cares%3F">Who cares?</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/societe/ephemeride">Éphéméride</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Économie">
                <a href="https://www.lexpress.mu/economie">Économie</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/economie">Économie home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/economie/entreprises">Entreprises</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/economie/immobilier">Immobilier</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/economie/emploi">Emploi</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/economie/infos-pratiques">Infos pratiques</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/economie/tourisme">Tourisme</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Régions">
                <a href="https://www.lexpress.mu/regions">Régions</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/regions">Régions home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/regions/nord">Nord</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/regions/sud">Sud</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/regions/est">Est</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/regions/ouest">Ouest</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/regions/rodrigues">Rodrigues</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Idées">
                <a href="https://www.lexpress.mu/idees">Idées</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/idees">Idées home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/idees/editorial">Éditorial</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/idees/tribune">Tribune</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/idees/opinions">Opinion</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/idees/focus">Focus</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/idees/blogs">Blog</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Sport">
                <a href="https://www.lexpress.mu/sport">Sport</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/sport">Sport home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/football">Football</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/sport-local">Sport local</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/sport-monde">Sport monde</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/turf">Turf</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/premier-league">Premier league</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/championnats-europeens">Championnats européens</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/uefa">Uefa</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/selections">Sélections</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/sport/lekip">Lékip</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="International">
                <a href="https://www.lexpress.mu/international">International</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/international">International home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/international/monde">Monde</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/international/ocean-indien">Océan indien</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/international/mauriciens-du-monde">Mauriciens du monde</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Vous">
                <a href="https://www.lexpress.mu/vous">Vous</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/vous">Vous home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/people/vip">People/vip</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/tendances">Tendances</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/sante--bien-etre">Santé &amp; bien-être</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/cuisine">Cuisine</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/auto">Auto</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/science-et-tech">Science et tech</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/culture">Culture</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/cine">Ciné</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/tele">Télé</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/musique">Musique</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/bons-plans">Bons plans</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/vous/coups-de-coeur">Coups de coeur</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Multimédia">
                <a href="https://www.lexpress.mu/multimedia">Multimédia</a>                   <ul>
                <li>
                  <a href="https://www.lexpress.mu/multimedia">Multimédia home</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/multimedia/videos">Videos</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/multimedia/diaporamas">Diaporamas</a>                     </li>
                <li>
                  <a href="https://www.lexpress.mu/multimedia/radio">Radio</a>                     </li>
              </ul>
              </li>
              <li data-menu-section="Annonces">
                <a href="#">Petites Annonces</a>
                <ul>
                  <li>
                    <a href="http://www.lexpressclassifieds.mu/" target="_blank">Toutes</a>
                  </li>
                  <li>
                    <a href="https://www.lexpressproperty.com" target="_blank">Immobilier</a>
                  </li>
                  <li>
                    <a href="http://www.lexpresscars.mu" target="_blank">Auto</a>
                  </li>
                  <li>
                    <a href="http://www.lexpressclassifieds.mu/fr/ile-maurice-jobs-emplois-814" target="_blank">Jobs, Emplois</a>
                  </li>
                  <li>
                    <a href="http://www.findme.mu" target="_blank">Annuaire</a>
                  </li>
                  <li>
                    <a href="http://www.lacase.mu/fr " target="_blank">Maison Déco</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
  export default {
    name: 'JumboMenu',
    props: [
      'showMenu',
      'toggleShow'
    ],
    computed: {
    },
    methods: {

    },
    components: {
    }
  }
</script>


<style scoped>

</style>
