function loading(state) {
  return state.loading
}

function getMenuStatus(state) {
  return state.menuStatus
}

export default {
  loading,
  getMenuStatus
}
