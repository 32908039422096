function setVenues(state, payload) {
  state.venues = payload.data
}

function setVenueTypes(state, payload) {
  state.venueTypes = payload.data
}

function setTowns(state, payload) {
  state.towns = payload.data
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

function setHasSearched(state, payload) {
  state.hasSearched = payload.data;
}

function setSearchStatus(state, payload) {
  state.searchStatus = payload;
}

function setCurrentTown(state, payload) {
  state.currentTown = payload.data;
}

function setCurrentVenueType(state, payload) {
  state.currentVenueType = payload.data;
}

function setCurrentKeyword(state, payload) {
  state.currentKeyword = payload.data;
}

function setVenue (state, payload) {
  state.venue = payload.data
}

function addVenue (state, payload) {
  state.venuesStore.push(payload.data)
}

export default {
  setVenues,
  setVenueTypes,
  setTowns,
  hasFetched,
  setHasSearched,
  setCurrentTown,
  setCurrentVenueType,
  setCurrentKeyword,
  setSearchStatus,
  setVenue,
  addVenue
};
