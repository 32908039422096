<template>
  <div data-deck="events-listing" v-if="events">
    <!-- Single content card -->
    <router-link
      v-if="event.getId() !== filter && event.getIsfeatured() === true"
      :to="{ path: '/evenement/' + event.getSlug() }"
      class="single-content-card"
      :key="index"
      v-for="(event, index) in events"
      :data-order="index"
    >
      <div class="card-thumbnail"></div>
      <div class="card-description">
        <div class="title-wrapper">
          <h2>{{event.getName()}}</h2>
        </div>
        <div class="description-wrapper">
          <div class="event-details-wrapper">
            <div class="icon-wrapper">
              <i class="material-icons">&#xE0C8;</i>
            </div>
            <div v-if="event.getVenue()" class="text-wrapper">{{event.getVenue().getName()}}</div>
          </div>
          <div class="event-details-wrapper">
            <div class="icon-wrapper">
              <i class="material-icons">&#xE916;</i>
            </div>
            <div class="text-wrapper">{{event.getStartat().toDate()| ago}}</div>
          </div>
        </div>
      </div>
    </router-link>
    <!-- Single content card -->
    <div class="events-listing-see-more">
      <router-link :to="{'path': '/evenements'}" title>
        Voir tous les
        événements
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "EvenementsListingWidget",
  props: {
    filter: {
      default: null,
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      events: "events/getEvents",
    }),
  },
  beforeMount() {
    // if (this.events.length === 0 || this.events.length === 1) {
    //   this.$store.dispatch("events/load");
    // }
  },
  filters: {
    ago(date) {
      return moment(date)
        .locale("fr")
        .format("MMM Do");
    },
  },
};
</script>

<style scoped lang="scss">
.events-listing-see-more {
  a {
    border: 2px solid map-get($rubriques, culture);
    display: block;
    padding: $gutter/4;
    text-align: center;
    text-decoration: none;
    color: map-get($rubriques, culture);
    font-weight: 500;

    &:hover {
      background: map-get($rubriques, culture);
      color: $color-white;
    }
  }
}

[data-deck="events-listing"] {
  display: grid;

  .single-content-card {
    margin-bottom: $gutter/2;
    position: relative;
    background: $color-main;
    padding: $gutter/2;
    text-decoration: none;
    display: block;

    &:hover {
      box-shadow: 0 7px 15px rgba($color-black, 0.5);
    }

    .card-thumbnail {
    }

    .card-description {
      .title-wrapper {
        margin-bottom: $gutter/3;

        h2 {
          text-align: left;
          margin: 0;
          color: $color-white;
          font-weight: 500;
          font-size: 18px;
        }
      }
      .description-wrapper {
        .event-details-wrapper {
          margin-bottom: $gutter/4;
          color: $color-white;
          display: grid;
          grid-template-columns: 40px 1fr;

          &:last-child {
            margin-bottom: 0;
          }

          .icon-wrapper {
          }
          .text-wrapper {
            padding-top: 5px;
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .single-content-card {
      margin-bottom: $gutter/4;
    }
  }
}
</style>
