<template>
  <div class="searching">
    <moon-loader :loading="loadStatus" :color="'#004a8f'" :size="'50px'"></moon-loader>
    <h4>En cours de chargement</h4>
  </div>
</template>

<script>
  import MoonLoader from "vue-spinner/src/MoonLoader.vue";

  export default {
    components: {
      MoonLoader
    },
    data () {
      return {
        loadStatus: true
      }
    },
    computed: {

    },
  }
</script>

<style lang="scss" scoped>
  .searching {
    margin-bottom: 100px;

    h4 {
      text-align: center;
      color: $color-event-bleu;
      margin-top: 20px;
    }
  }
</style>