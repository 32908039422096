import { AppClient } from "@/services/service_app_pb_service.js";
import {
  GetActiveMoviesRequest,
  EventListRequest,
  MovieListRequest,
  EventRequest,
  MovieRequest,
  RecipeRequest,
  RecipeListRequest,
} from "@/services/service_app_pb";

function getFeaturedEvents(state) {
  return state.events.featured;
}

function getMainEvents(state) {
  return state.events.main;
}

function getEvents(state) {
  return state.allEvents;
}

function getEvent(state) {
  return state.event;
}

export default {
  getFeaturedEvents,
  getMainEvents,
  getEvents,
  getEvent,
};
