<template>
  <div class="movie-box" :class="theme">
    <router-link :to="{ path : '/cinema/' + movie.getSlug()}" :title="movie.getTitle()">
      <div class="card-thumbnail" v-if="movie.getImage()">
        <img src="@/assets/placeholder/movie.svg" alt=" ">
        <div class="poster-placeholder" :style="{ 'background-image': 'url(' +  movie.getImage() + ':thumb' + ')' }"></div>
        <div class="other__details" v-if="showData">
          <div class="details__row" v-if="movie.getDuration()"><i class="material-icons">access_time</i> <span>{{movie.getDuration()}} mins</span></div>
          <!--<div class="details__row" v-if="movie.getRating()"><i class="material-icons">star</i> <span>{{movie.getRating()}}/10</span></div>-->
          <div class="details__row" v-if="movie.getContentrating()"><i class="material-icons">visibility</i> <span>{{movie.getContentrating()}}</span></div>
          <div class="details__row" v-if="movie.getGenresList().length > 0"><i class="material-icons">local_movies</i> <span v-for="(genre, index) in movie.getGenresList()">{{genre.getName()}}<template v-if="index !== movie.getGenresList().length - 1">, </template></span>
          </div>
        </div>
        <div class="card-description" v-if="showData">
          <div class="description-wrapper">
            <p v-if="movie.getOpinion()" v-html="movie.getOpinion()"></p>
          </div>
        </div>
      </div>
      <div class="card-title">
        <h2>{{ movie.getTitle() }}</h2>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MovieComponent",
  props: {
    movie: {
      type: Object
    },
    theme: {
      type: String
    },
    showData: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.movie-box {
  position: relative;
  width: 100%;

  a {
    display: block;
    position: relative;
    text-decoration: none;
  }

  .card-thumbnail {
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;

    img {
      border-color: black;
      display: block;
      width: 100%;
      box-sizing: border-box;
    }

    .poster-placeholder {
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      background-position: center center;
      left: 0;
      top: 0;
    }
  }

  &:hover {
    .card-thumbnail {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
      transition: all 0.4s ease-in-out;

      .poster-placeholder {
        // transform: scale(0.95);
      }
    }
  }

  &.light {
    .card-title {
      h2 {
        color: var(--color-white);
      }
    }
  }

  .card-title {
    h2 {
      margin: 0;
      font-size: 18px;
      padding: 10px 0;
      color: var(--color-black);
    }
  }

  .other__details {
    position: absolute;
    top: 0;
    padding: 20px 10px;
    width: 100%;
    background: linear-gradient(
      170deg,
      rgba(0, 0, 0, 0.9) 30%,
      transparent 70%
    );
    color: white;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);

    .details__row {
      i {
        margin-right: 10px;
        font-size: 17px;
      }

      span {
        position: relative;
        top: -3px;
        font-size: 14px;
      }
    }
  }

  .card-description {
    transition: opacity 0.4s ease-out;
    position: absolute;
    bottom: 0;
    padding: 10px;
    opacity: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    top: 0;
    display: flex;
    align-items: flex-end;
    color: white;
    pointer-events: none;
    width: 100%;

    .title-wrapper {
      a {
        text-decoration: none;
        color: white;
      }
    }

    .description-wrapper {
      font-size: 14px;
      max-width: 95%;
    }
  }

  &:hover {
    .card-description {
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
  }
}

@media (max-width: $mobile) {
  .movie-box {
    .card-description {
      display: none;
    }
  }
}
</style>
