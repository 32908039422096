import moment from 'moment'

function getMovies(state) {
  return state.movies
}

function getMoviesFiltered(state) {

  let movies = []
  let moviesFiltered = []

  state.movies.forEach(movie => {
    movies.push(movie)
  })

  if (state.currentTheatre != null) {
    let moviesToDisplay = state.moviesGroupedByTheatre[state.currentTheatre.id]

    let filtered = moviesToDisplay
    if (state.currentDate !== null) {
      if (typeof moviesToDisplay !== 'undefined') {
        filtered = moviesToDisplay.filter(movie => {
          return moment(state.currentDate.toDate()).format('Do MMM YYYY') === moment(movie.timestamp.toDate()).format('Do MMM YYYY')
        })
      }
    }

    if (typeof moviesToDisplay !== 'undefined') {
      let uniqueMoviesToDisplay = _.uniqBy(filtered, function (movie) {
        return movie.movieid;
      });

      uniqueMoviesToDisplay.map(movie => {
        let id = movie.movieid
        movies.map(m => {
          if (m.getId() == id) {
            moviesFiltered.push(m)
          }
        })
      })
    }

  } else if (state.currentTheatre === null && state.currentDate !== null) {
    let dateSelected = moment(state.currentDate.toDate()).format('Do MMM YYYY')

    if (state.moviesGroupedByDate) {
      let moviesFilteredByDate = state.moviesGroupedByDate[dateSelected]
      let moviesFilteredByDateUnique = _.uniqBy(moviesFilteredByDate, function (movie) {
        return movie.movieid;
      });

      moviesFilteredByDateUnique.map(movie => {
        let id = movie.movieid
        movies.map(m => {
          if (m.getId() == id) {
            moviesFiltered.push(m)
          }
        })
      })
    }


  } else {
    moviesFiltered = movies
  }

  return moviesFiltered;
}

function getTheatres(state) {
  let showtimes = []
  if (state.showtimes !== null) {
    state.showtimes.forEach(showtime => { showtimes.push(showtime) })
  }

  let venueShowtimes = _.groupBy(showtimes, (showtime) => {
    return showtime.getVenueid()
  })

  let groupedByTheatreID = _.mapValues(venueShowtimes, (item) => {
    return _.sortBy(item, (array) => {
      return array.getStartat()
    })
  })

  let groupedData = {}

  _.mapValues(groupedByTheatreID, (item, propertyKey) => {
    let showTimesArray = []
    item.map(showtime => {
      let object = {
        date: moment(showtime.getStartat().toDate()).locale("fr").format('Do MMM YYYY'),
        time: moment(showtime.getStartat().toDate()).locale("fr").format('HH:mm'),
        screen: showtime.getScreen(),
        three_d: showtime.getThreed()
      }
      showTimesArray.push(object)
    })

    let groupedByMultiple = _.groupBy(showTimesArray, (d) => {
      return d.date + " - " + d.screen;
    });

    let mergedGrouped = {}
    _.mapValues(groupedByMultiple, (item, propertyKey) => {
      let purifiedData = {}
      let selectedDate = false

      if (state.currentDate !== null) {
        selectedDate = moment(state.currentDate.toDate()).locale("fr").format('Do MMM YYYY') == item[0].date
      } else {
        selectedDate = false
      }

      let isToday = false
      if (moment().locale("fr").format('Do MMM YYYY') === item[0].date) {
        isToday = true
      }

      purifiedData.data = {
        date: item[0].date,
        screen: item[0].screen,
        selected: selectedDate,
        isToday: isToday
      }
      purifiedData.showtimes = item
      mergedGrouped[propertyKey] = purifiedData
    })

    groupedData[propertyKey] = mergedGrouped
  });

  let venueIDs = Object.keys(venueShowtimes)
  let venueData = venueIDs.map(venueID => {
    let data = {}
    if (state.venues) {
      state.venues.forEach(venue => {
        if (venue.getId() === venueID) {
          data = venue
        }
      })
    }
    return data
  })

  let result = {
    venueData: venueData,
    venueGrouped: groupedData
  }

  return result;
}

function getDates(state) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const dates = [];

  if (state.showtimes !== null) {
    state.showtimes.forEach(showtime => {
      const day = new Date(showtime.getStartat().toDate()).getDate();
      const month = moment(showtime.getStartat().toDate()).locale("fr").format('MMM')
      const parsedDate = showtime.getStartat().toDate().toString().substring(0, 15)

      let selectedDate = false

      if (state.currentDate !== null) {
        selectedDate = moment(state.currentDate.toDate()).locale("fr").format('MMM Do YYYY') == moment(showtime.getStartat().toDate()).locale("fr").format('MMM Do YYYY')
      }

      let data = {
        date: parsedDate,
        timestamp: showtime.getStartat(),
        day: day,
        selected: selectedDate,
        month: month
      };

      dates.push(data)
    });
  }

  let uniqueDates = _.uniqBy(dates, 'date');

  let result = _.sortBy(uniqueDates, function (date) {
    return date.timestamp
  })

  return result;
}

function getMovie(state, payload) {
  let movie = state.movies.find(movie => {
    return movie.getId() === payload.id;
  });
  return movie;
}

function movie(state) {
  return state.movie;
}

function getCurrentDate (state) {
  return state.currentDate
}

function getCurrentTheatre (state) {
  return state.currentTheatre
}

function getThreatresData(state) {
  let venues = []
  let selected = false

  if (state.theatres !== null) {
    state.theatres.forEach(venue => {

      if (state.currentTheatre && state.currentTheatre !== null && state.currentTheatre.id === venue.getId()) {
        selected = true
      } else {
        selected = false
      }

      venues.push({
        name: venue.getName(),
        id: venue.getId(),
        selected: selected
      })
    })
  }

  return venues
}

function getMoviesGroupedByTheatre (state) {
  return state.moviesGroupedByTheatre
}

function getMoviesGroupedByDate (state) {
  return state.moviesGroupedByDate
}

export default {
  getTheatres,
  getDates,
  getMovies,
  getMovie,
  movie,
  getCurrentDate,
  getThreatresData,
  getMoviesGroupedByTheatre,
  getMoviesFiltered,
  getCurrentTheatre,
  getMoviesGroupedByDate
};
