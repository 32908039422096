import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  movies: [],
  movie: null,
  showtimes: null,
  venues: null,
  theatres: null,
  fetched: false,
  currentDate: null,
  currentTheatre: null,
  moviesGroupedByTheatre: null,
  moviesGroupedByDate: null,
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
