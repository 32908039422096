<template>
  <div class="reviews__container">
    <div class="featured-reviews__container">
      <template v-if="index < 4" v-for="(review, index) in getReviews">
        <ReviewComponent :review="review" />
      </template>
    </div>

    <div class="view-more__wrapper">
      <ViewMoreButton size="small" theme="dark" url="/coups-de-coeur"/>
    </div>
  </div>
</template>
<script>
import ReviewComponent from "@/components/reviews/ReviewComponent";
import ViewMoreButton from "@/components/shared/ViewMoreButton";
import { mapGetters } from "vuex";

export default {
  components: {
    ReviewComponent,
    ViewMoreButton
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      getReviews: "reviews/getReviews"
    })
  },
  beforeMount() {
    this.$store.dispatch("reviews/load");
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.featured-reviews__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gutter/2;
  margin-top: $gutter * 2;
  margin-bottom: $gutter/2;
}

.reviews__container {
  text-align: right;
  margin-bottom: 100px;
}

.view-more__wrapper {
  margin-bottom: 20px;
}

@media (max-width: $tablet-portrait) {
  .featured-reviews__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $mobile) {
  .featured-reviews__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
