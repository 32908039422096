//@ts-check
import {
  RecipeListRequest,
  RecipeBySlugRequest,
} from "@/services/service_app_pb";
import { grpcFetch, loadSingleEntityFromStore } from "@/util/cache-manager.js";
import {
  RecipeListResponse,
  RecipeResponse,
} from "../../services/service_app_pb";

function load({ state, commit }) {
  let reqRecipesList = new RecipeListRequest();
  const cacheName = "recettes";
  const methodName = "getRecipes";

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching recipes data",
    },
    { root: true },
  );
  /**** Loader ****/

  grpcFetch(
    methodName,
    reqRecipesList,
    cacheName,
    RecipeListResponse,
    commit,
  ).then((res) => {
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Has fetched recipes data",
      },
      { root: true },
    );

    let entities = res.getRecipesList();
    commit("setRecipes", entities);
    // commit("setFeaturedEvents", entities);
    // commit("setMainEvents", entities);
  });
}

function getSingleRecipeById({ state, commit, rootState }, payload) {
  let reqRecipe = new RecipeBySlugRequest();
  let entitySlug = rootState.route.params.id;

  const methodName = "getRecipeBySlug";
  const cacheName = "recette" + entitySlug;

  reqRecipe.setSlug(entitySlug);

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Looking up recipe",
    },
    { root: true },
  );
  /**** Loader ****/

  loadSingleEntityFromStore(state.recipes, "getSlug", entitySlug)
    .then((entity) => {
      commit("setRecipe", { data: entity });
      commit(
        "SET_STATUS",
        { status: false, message: "Found " + entitySlug + " in store" },
        { root: true },
      );
    })
    .catch((err) => {
      grpcFetch(methodName, reqRecipe, cacheName, RecipeResponse, commit).then(
        (res) => {
          commit(
            "SET_STATUS",
            {
              status: false,
              message: "Has fetched homepage data",
            },
            { root: true },
          );

          let recipeObject = res.getRecipe();

          commit("addRecipe", { recipe: recipeObject });
          commit("setRecipe", { data: recipeObject });
        },
      );
    });
}

export default {
  load,
  getSingleRecipeById,
};
