<template>
  <div class="movies-listing__container" v-if="getMoviesFiltered">
    <template v-for="(movie) in getMoviesFiltered" v-if="movie.getId() != filter">
      <transition name="fade" mode="in-out">
        <MovieComponent :movie="movie"></MovieComponent>
      </transition>
    </template>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import MovieComponent from '@/components/movie/MovieComponent'

  export default {
    props: {
      filter: {
        default: null,
        type: String
      }
    },
    components: {
      MovieComponent
    },
    data() {
      return {
        noResults: false
      };
    },
    computed: {
      ...mapGetters({
        getMoviesFiltered: "movies/getMoviesFiltered",
      })
    },
    beforeMount() {
      this.$store.dispatch("movies/load");
    }
  };
</script>
<style lang="scss">

</style>