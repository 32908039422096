<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'"
      :size="'40px'"></moon-loader>
    <template v-else>
      <div class="poster-deck">
        <div class="poster-data">

          <!-- poster preview -->
          <div class="poster-card">
            <span class="poster-fill" v-if="movie.getImage()">
              <img :src="movie.getImage() + ':thumb'" alt="">
            </span>

            <span class="poster-featured">
              <img src="@/assets/placeholder/poster-placeholder(140X195).svg"
                alt="" class="poster-image">
              <div class="poster-image-preview" v-if="movie.getImage()" :style="{ 'background-image':'url('+ movie.getImage() +':full)' }"></div>
              <div class="tablet-content-wrapper">
                <h2 class="title-tablet"> {{ movie.getTitle() }} </h2>
                <div class="poster-desc-category-tablet">
                  <ul class="movie-category-tablet">
                    <li class="movie-category-parental tag">{{
                      movie.getContentrating() }}</li>
                    <li class="movie-year">{{ movie.getYear() }}</li>
                  </ul>
                </div>
              </div>
            </span>

          </div>
          <!-- poster preview -->

          <!-- poster details -->
          <div class="poster-details">
            <h1 class="movie-details-title">
              {{ movie.getTitle() }}
            </h1>
            <div class="poster-desc-category">
              <ul class="movie-category">
                <li class="movie-category-parental tag">{{
                  movie.getContentrating() }}</li>
                <li class="movie-year">{{ movie.getYear() }}</li>
              </ul>
            </div>

            <div class="movie-information">
              <div class="info-row">
                <hgroup>Synopsis</hgroup>
                <!-- <summary> {{ movie.summary }}</summary> -->
                <summary v-html="movie.getDescription()"></summary>
              </div>
              <div class="info-row">
                <hgroup>Réalisateur</hgroup>
                <!-- <summary>{{movie.director}}</summary> -->
                <summary v-html="movie.getDirector()"></summary>
              </div>
              <div class="info-row">
                <hgroup>Écrivains</hgroup>
                <summary v-html="movie.getWriter()"></summary>
              </div>
              <div class="info-row">
                <hgroup>Vedettes</hgroup>
                <!-- <summary>{{movie.cast}}</summary> -->
                <summary v-html="movie.getCast()"></summary>
              </div>
              <div class="info-row">
                <hgroup>Genre</hgroup>
                <!-- <summary>{{movie.cast}}</summary> -->
                <span v-for="(genre, index) in movie.getGenresList()">{{genre.getName()}}<template
                    v-if="index !== movie.getGenresList().length - 1">,
                  </template></span>
              </div>
            </div>

          </div>
          <!-- poster details -->

          <!-- upcoming poster  -->
          <div class="upcoming-poster">
            <h2>Films à venir</h2>

            <div class="upcoming__movies-listing">
              <template v-for="(m, index) in shuffledMovies()" v-if="m.getId() != movie.getId() && index < 4">
                <MovieComponent :showData="false" :movie="m"></MovieComponent>
              </template>
            </div>
          </div>
          <!-- upcoming poster  -->
        </div>
      </div>
      <div class="container-fw">
        <div class="generic-layout full-page">
          <div class="section-container">
            <div class="section-description-wrapper">

              <!-- Movie details -->
              <div class="movie-info-container">
                <div class="page-title-container default flex-wrapper schedule-title-wrapper">
                  <div class="title-wrapper">
                    <h2>Séances par salles</h2>
                  </div>
                  <div class="reset-button-wrapper">
                    <transition name="reset-button-slide" mode="out-in">
                      <button v-if="currentDate != null" @click="setFilter({ 'date' : null })"
                        title="Reset">
                        <i class="material-icons">refresh</i>
                      </button>
                    </transition>
                  </div>
                </div>
                <div class="section-container">
                  <div class="section-description-wrapper">
                    <div class="movie-info-left-wrapper">
                      <div class="theaters-wrapper">
                        <!-- Date selection -->
                        <DatePicker></DatePicker>

                        <!-- Theaters -->
                        <ScheduleListing></ScheduleListing>
                      </div>
                    </div>
                    <div class="movie-info-right-wrapper movie-information"
                      v-if="movie.getTrailer()">
                      <div class="movie-trailer-wrapper" data-video="movie-trailer">
                        <iframe width="560" height="315" :src="'https://youtube.com/embed/' + getYoutubeId(movie.getTrailer())"
                          frameborder="0" allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Movie details -->

              <!-- Share -->
              <div class="share-social-container">
                <div class="share-social-left-wrapper">
                  <div class="section-container">
                    <div class="section-title-wrapper">
                      <h2 class="small">Partagez cette page</h2>
                    </div>
                    <div class="section-description-wrapper">
                      <a href="#" @click.prevent="shareToFacebook()" class="social-icon fb">
                        <img src="@/assets/images/icons/facebook.svg" alt="Facebook">
                      </a>
                      <a href="#" @click.prevent="shareToTwitter()" class="social-icon tw">
                        <img src="@/assets/images/icons/twitter.svg" alt="Twitter">
                      </a>
                      <a href="#" @click.prevent="shareToEmail()" class="social-icon email">
                        <img src="@/assets/images/icons/envelope.svg" alt="Email">
                      </a>
                    </div>
                  </div>
                </div>
                <transition name="fade" mode="out-in">
                  <div class="share-social-right-wrapper" v-if="currentDate != null">
                    <div class="section-container">
                      <div class="section-title-wrapper">
                        <h2 class="small">Ajouter a votre calendrier</h2>
                      </div>
                      <div class="section-description-wrapper">
                        <a href="#" @click.prevent="addToGoogleCalendar()" id="google-calendar"
                          class="social-icon google">
                          <img src="@/assets/images/icons/google.svg" alt="Google">
                        </a>

                        <a href="#" @click.prevent="addToWindowsCalendar()" id="microsoft-calendar"
                          class="social-icon windows">
                          <img src="@/assets/images/icons/windows.svg" alt="Microsoft">
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- Share -->

              <!-- Notre Opinion -->
              <div class="our-opinion-container" v-if="movie.getOpinion()">
                <div class="page-title-container default">
                  <div class="title-wrapper">
                    <h2>Notre Opinion</h2>
                  </div>
                </div>
                <div class="section-container">
                  <div class="section-description-wrapper">
                    <div class="text-number-container">
                      <div class="text-wrapper" v-if="movie.getOpinion()"
                        v-html="movie.getOpinion()"></div>
                      <div class="number-wrapper" v-if="movie.getRating()">
                        <span>{{ movie.getRating() }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Notre Opinion -->

              <div class="our-opinion-container">
                <div class="page-title-container default">
                  <div class="title-wrapper">
                    <h2>Vos commentaires</h2>
                  </div>
                </div>
                <div class="section-container">
                  <div class="section-description-wrapper">
                    <vue-disqus shortname="lexpressculture" :title="movie.getSlug()" :identifier="'/cinema/' + movie.getSlug()"
                      :url="'https://culture.lexpress.mu/cinema/' + movie.getSlug()"></vue-disqus>
                  </div>
                </div>
              </div>

              <div class="page-title-container default">
                <div class="title-wrapper">
                  <h2>Autres films</h2>
                </div>
              </div>
              <MovieListing :filtered="false" :filter="movie.getId()" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import DatePicker from "@/components/shared/DatePicker.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import ScheduleListing from "@/components/movie/ScheduleListing";
import MovieListing from "@/components/movie/MovieListing.vue";
import MovieComponent from "@/components/movie/MovieComponent";
import moment from "moment";

export default {
  name: "Movie",
  data() {
    return {
      movieInfo: null,
      facebookAppID: 1662665607355206
    };
  },
  metaInfo() {
    return {
      title: this.loading.state
        ? "Loading..."
        : this.movieInfo
          ? this.movieInfo.getTitle()
          : "",
      titleTemplate: "%s | LexpressCulture"
    };
  },
  methods: {
    setFilter(message) {
      this.$store.commit({
        type: "movies/SET_FILTER",
        payload: message
      });
    },
    getYoutubeId(url) {
      let ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      } else {
        ID = url;
      }

      return ID;
    },
    shuffledMovies() {
      return this.getMovies;
    },
    addToGoogleCalendar() {
      let url =
        "http://www.google.com/calendar/event?action=TEMPLATE&trp=false&text=" +
        this.movie.getTitle() +
        "&details=" +
        this.movie.getSummary() +
        "&dates=" +
        moment(this.currentDate * 1000).format("YYYYMMDDTHHmmss") +
        "/" +
        moment(this.currentDate * 1000).format("YYYYMMDDTHHmmss");

      window.open(url, "_blank");
    },
    addToWindowsCalendar() {
      let url =
        "http://calendar.live.com/calendar/calendar.aspx?rru=addevent&summary=" +
        this.movie.getTitle() +
        "&details=" +
        this.movie.getSummary() +
        "&dtstart=" +
        moment(this.currentDate * 1000).format("YYYYMMDDTHHmmss") +
        "&dtend=" +
        moment(this.currentDate * 1000).format("YYYYMMDDTHHmmss");

      window.open(url, "_blank");
    },
    shareToFacebook() {
      let url =
        "https://www.facebook.com/dialog/share?app_id=" +
        this.facebookAppID +
        "&href=" +
        window.location.href +
        "&redirect_uri=" +
        window.location.href;
      window.open(url, "_blank");
    },
    shareToTwitter() {
      let url =
        "http://twitter.com/share?text=" +
        this.movie.getTitle() +
        "&url=" +
        window.location.href +
        "&hashtags=Mauritius";
      window.open(url, "_blank");
    },
    shareToEmail() {
      let url =
        "mailto:?subject=" +
        this.movie.getTitle() +
        " - L'express Culture&body=Bonjour, regarde ce lien: " +
        window.location.href;
      window.open(url);
    }
  },
  beforeMount() {
    this.$store.dispatch("movies/getSingleMovieById");
  },
  mounted() {
    this.$store.dispatch("movies/load");
  },
  watch: {
    movie(val) {
      this.movieInfo = val;
    }
  },
  props: ["id"],
  computed: {
    ...mapGetters({
      movie: "movies/movie",
      movies: "movies/getMovies",
      dates: "movies/getDates",
      currentDate: "movies/getCurrentDate",
      loading: "loading",
      getMovies: "movies/getMovies"
    })
  },
  components: {
    MovieListing,
    DatePicker,
    MoonLoader,
    ScheduleListing,
    MovieComponent
  }
};
</script>
<style lang="scss">
.poster-deck {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #f5f7fa;

  .poster-data {
    position: relative;
    overflow: hidden;
    display: flex;
    min-height: 60vh;

    .poster-card {
      position: relative;
      width: 30%;
      display: flex;
      margin-right: 50px;

      .poster-fill {
        position: absolute;
        overflow: hidden;
        bottom: -20%;
        left: -36%;
        height: 170%;
        width: 110%;
        background-color: gainsboro;
        transform: rotate(20deg);

        img {
          filter: blur(6px);
          object-fit: cover;
          width: 100%;
          height: 100%;
          transform: scale(1.4);
        }
      }

      .poster-featured {
        position: relative;
        display: block;
        align-self: center;
        z-index: 1;
        margin: auto auto auto 45%;

        .poster-image-preview {
          display: block;
          width: 100%;
          height: 100%;
          background-size: cover;
          position: absolute;
          background-position: center center;
          left: 0;
          top: 0px;
        }

        .poster-image-preview,
        .poster-image {
          width: 200%;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3),
            0 2px 4px 0 rgba(0, 0, 0, 0.08);
        }

        .poster-desc-category-tablet,
        .movie-genre,
        .movie-year,
        .movie-category-parental,
        .tag,
        .title-tablet {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
    .poster-details {
      padding-left: $gutter;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .movie-details-title {
        color: black;
        margin: 0;
      }

      .movie-category {
        padding: 0;
        margin: 0;
        margin-top: 20px;

        li {
          margin: 0;
        }

        .tag {
          background: #eee;
          border-radius: 3px 0 0 3px;
          color: #999;
          display: inline-block;
          height: 26px;
          line-height: 26px;
          padding: 0 20px 0 23px;
          position: relative;
          margin: 0 10px 10px 0;
          text-decoration: none;
          -webkit-transition: color 0.2s;
          top: -3px;
        }

        .tag::before {
          background: #fff;
          border-radius: 10px;
          box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
          content: "";
          height: 6px;
          left: 10px;
          position: absolute;
          width: 6px;
          top: 10px;
        }

        .tag::after {
          background: #f5f7fa;
          border-bottom: 13px solid transparent;
          border-left: 10px solid #eee;
          border-top: 13px solid transparent;
          content: "";
          position: absolute;
          right: 0;
          top: 0;
        }
        // remove hover effect - red
        .tag {
          background-color: crimson;
          color: white;
        }

        .tag::after {
          border-left-color: crimson;
        }
      }

      summary li,
      .movie-category li {
        float: left;
        list-style-type: none;
        margin-right: 12px;
      }

      .movie-information {
        .info-row {
          margin-bottom: $gutter/4;

          hgroup {
            font-weight: 700;
            line-height: 27px;
          }
          summary {
            font-weight: 300;
            line-height: 27px;
          }
        }
      }
    }
  }
}

.upcoming-poster {
  width: 33%;
  margin-left: 10px;
  box-sizing: border-box;
  text-align: center;

  h2 {
    font-size: 30px;
    margin-bottom: $gutter;
    margin-top: $gutter;
    max-width: 20vw;
  }

  .upcoming__movies-listing {
    display: grid;
    height: 500px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    max-width: 20vw;

    .movie-box {
      .card-thumbnail {
        width: 110px;
        margin: 0 auto;
      }

      .card-title {
        h2 {
          font-size: 14px;
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }

    .movie-box .card-thumbnail img {
      height: 170px;
    }

    /*.movie-box .card-thumbnail .poster-placeholder {*/
    /*width: 110px;*/
    /*}*/
  }
}

.movie-info-container {
  .section-description-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $gutter/2;
  }

  .movie-info-left-wrapper {
    grid-column: 1 / 2;
  }

  .movie-info-right-wrapper {
    grid-column: 2 / 3;
    padding: 0 0 0 $gutter/2;

    .movie-trailer-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .info-row {
      margin-bottom: $gutter/4;

      hgroup {
        font-weight: 700;
        line-height: 27px;
      }
      summary {
        font-weight: 400;
        line-height: 27px;
      }
    }
  }
}

.share-social-container {
  margin-bottom: $gutter/2;
  display: flex;

  .share-social-left-wrapper {
    border-right: 1px solid $color-grey;
    margin-right: $gutter/2;
  }

  a {
    &.social-icon {
      width: 50px;
      height: 50px;
      background: $color-main;
      border-radius: 60px;
      text-align: center;
      padding: $gutter/2 - 5px;
      display: inline-block;
      margin-right: $gutter/4;

      &:focus {
        outline: none;
      }

      img {
        max-height: 100%;
      }

      &.fb {
        background: #3b5998;
      }
      &.tw {
        background: #4099ff;
      }
      &.email {
        background: $color-black;
      }
      &.google {
        background: #db3236;
      }
      &.windows {
        background: #7cbb00;
      }

      i {
        color: $color-white;
        font-size: 25px;
      }
    }
  }
}

.our-opinion-container {
  display: grid;

  .text-number-container {
    display: grid;
    grid-template-columns: calc(4 / 5 * 100%) calc(1 / 5 * 100%);
  }

  .text-wrapper {
    p {
      line-height: 27px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  .number-wrapper {
    span {
      display: block;
      min-height: 200px;
      background: $color-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $gutter/2;
      font-size: 100px;
      font-weight: 500;
    }
  }
}

@media (max-width: $desktop) {
  .movie-pt-container {
    .movie-poster-wrapper {
      img {
        min-height: 370px;
        max-height: 370px;
      }
    }
    .movie-trailer-wrapper {
    }
  }
  .our-opinion-container {
    .text-wrapper {
    }
    .number-wrapper {
      span {
        font-size: 90px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .poster-deck .poster-data .poster-card .poster-featured {
    margin: auto auto auto 30%;
  }
  .upcoming-poster h2 {
    max-width: 25vw;
  }
  .upcoming-poster .upcoming__movies-listing {
    max-width: 25vw;
  }
}

@media (max-width: 900px) {
  .movie-pt-container {
    .movie-poster-wrapper {
      img {
        min-height: 300px;
        max-height: 300px;
      }
    }
    .movie-trailer-wrapper {
      iframe {
        min-height: 300px;
        max-height: 300px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .poster-deck {
    .poster-data {
      height: auto;

      .poster-details {
        width: 48%;
        padding-left: 0%;
      }
      .upcoming-poster {
        display: none;
      }

      .poster-card {
        width: 48%;
        margin-right: 0px;

        .poster-fill {
          left: -80%;
          width: 130%;
          visibility: visible;
        }
        .poster-featured {
          margin: auto;
          width: 48%;

          img {
            height: 100%;
          }

          .poster-image-preview,
          .poster-image {
            width: 115%;
          }
        }
      }
    }
  }

  .movie-pt-container {
    .movie-poster-wrapper {
      img {
        min-height: 250px;
        max-height: 250px;
      }
    }
    .movie-trailer-wrapper {
      iframe {
        min-height: 250px;
        max-height: 250px;
      }
    }
  }
  .movie-info-container {
    .section-description-wrapper {
      grid-template-columns: 1fr;
    }
    .movie-info-left-wrapper {
      grid-column: 1 / 1;
    }
    .movie-info-right-wrapper {
      grid-column: 1 / 1;
      padding-left: 0;
      margin-top: 20px;
    }
  }
  .our-opinion-container {
    .text-number-container {
      display: flex;
      flex-wrap: wrap-reverse;
    }

    .number-wrapper {
      margin-bottom: $gutter/2;

      span {
        font-size: 70px;
        max-width: 150px;
        min-height: 150px;
        margin: 0 auto;
      }
    }

    .text-wrapper,
    .number-wrapper {
      width: 100%;
    }
  }
  .share-social-container {
    margin-bottom: $gutter/2;
  }
}

@media (max-width: $tablet-portrait) {
  .poster-deck {
    .poster-data {
      flex-direction: column;
      width: 100%;
      height: auto;
      min-height: initial;

      .poster-card {
        width: 100%;

        .poster-fill {
          visibility: hidden;
          top: -80%;
          left: -20%;
          height: 150%;
          bottom: -20%;
          width: 150%;
          transform: rotate(7deg);

          img {
            display: none;
          }
        }
        .poster-featured {
          margin-top: 20px;
          margin-left: 10px;
          // grid-template-columns: auto 1fr;
          // display: grid;
          width: 100%;
          background: none;
          box-shadow: none;

          display: flex;
          flex-direction: row;

          .poster-image-preview,
          .poster-image {
            width: 19%;
          }

          .poster-desc-category-tablet,
          .movie-genre,
          .movie-year,
          .movie-category-parental,
          .tag,
          .title-tablet,
          .tablet-content-wrapper,
          .title-tablet,
          .poster-desc-category-tablet {
            display: contents;
          }

          .tablet-content-wrapper {
            // margin: auto;
            display: flex;
            flex-direction: column;
            margin: auto 20px;

            ul {
              li {
                display: block;
                margin-right: 20px;
                float: left;
              }
            }

            .title-tablet {
              margin: auto 20px;
            }

            .poster-desc-category-tablet {
              .movie-category-tablet {
                padding: 0;

                .movie-category-parental {
                  float: left;
                  margin-right: 20px;
                  list-style-type: none;
                }

                .tag {
                  background: #eee;
                  border-radius: 3px 0 0 3px;
                  color: #999;
                  display: inline-block;
                  height: 26px;
                  line-height: 26px;
                  padding: 0 20px 0 23px;
                  position: relative;
                  margin: 0 10px 10px 0;
                  text-decoration: none;
                  -webkit-transition: color 0.2s;
                  top: -3px;
                }

                .tag::before {
                  background: #fff;
                  border-radius: 10px;
                  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
                  content: "";
                  height: 6px;
                  left: 10px;
                  position: absolute;
                  width: 6px;
                  top: 10px;
                }

                .tag::after {
                  background: #f5f7fa;
                  border-bottom: 13px solid transparent;
                  border-left: 10px solid #eee;
                  border-top: 13px solid transparent;
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 0;
                }

                .tag {
                  background-color: crimson;
                  color: white;
                }

                .tag::after {
                  border-left-color: crimson;
                }
              }
            }
          }

          img {
            width: 140px;
            vertical-align: middle;
          }
        }
      }

      .poster-details {
        width: 100%;
        padding-left: 10px;

        .movie-details-title {
          display: none;
        }
        .poster-desc-category {
          display: none;
        }
      }
      .upcoming-poster {
        width: 100%;
        background-color: rebeccapurple;
      }
    }
  }

  .movie-information {
    margin-top: 20px;
    width: 100%;
  }

  .movie-pt-container {
    .movie-poster-wrapper,
    .movie-trailer-wrapper {
    }
    .movie-poster-wrapper {
      display: none;
    }
    .movie-trailer-wrapper {
      iframe {
        min-height: auto;
        max-height: auto;
      }
    }
  }
  .share-social-container {
    margin-bottom: $gutter/2;
    display: grid;

    .share-social-left-wrapper {
      border-right: 0;
    }
  }
}

@media (max-width: $mobile) {
  .poster-desc-category {
    display: contents !important;
  }
  .title-tablet {
    font-size: 22px !important;
  }
  .poster-deck {
    .poster-data {
      .poster-card {
        .poster-fill {
          display: none;
          // background: linear-gradient(to left, #fbfbfb2e, #8e8e8efc);
        }

        .poster-featured {
          .poster-desc-category-tablet,
          .movie-genre,
          .movie-year,
          .movie-category-parental,
          .tag {
            display: none;
          }
        }
      }
    }
  }

  .poster-deck .poster-data .poster-card .poster-featured {
    // margin-bottom: 70px;
  }

  .poster-deck .poster-data .poster-card .poster-featured {
    .poster-image-preview,
    .poster-image {
      width: 35%;
    }
  }
  .movie-category li {
    float: none;
  }

  .movie-info-container {
    .movie-info-right-wrapper {
      .info-row {
        hgroup,
        summary {
          width: 100%;
        }
      }
    }
  }
  .our-opinion-container {
    .number-wrapper {
      margin-bottom: $gutter/2;

      span {
        font-size: 50px;
        max-width: 100px;
        min-height: 100px;
        margin: 0 auto;
      }
    }

    .text-wrapper,
    .number-wrapper {
      width: 100%;
    }
  }

  .movie-information,
  .movie-trailer-wrapper,
  .movie-trailer-wrapper iframe {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .poster-deck .poster-data .poster-card .poster-featured {
    .poster-image-preview,
    .poster-image {
      width: 46%;
    }
  }
}

.movie-pt-container {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-column-gap: $gutter/2;

  .movie-poster-wrapper {
    img {
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: 50% 50%;
      min-height: 500px;
    }
  }
  .movie-trailer-wrapper {
    iframe {
      background: black;
      width: 100%;

      height: 100%;
      min-height: 500px;
      max-height: 500px;
    }
  }
}

.movie-info-container {
  .section-description-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $gutter/2;
  }

  .movie-info-left-wrapper {
    grid-column: 1 / 2;
  }

  .movie-info-right-wrapper {
    grid-column: 2 / 3;
    padding: 0 0 0 $gutter/2;

    .info-row {
      margin-bottom: $gutter/4;

      hgroup {
        font-weight: 700;
        line-height: 27px;
      }
      summary {
        font-weight: 100;
        line-height: 27px;
      }
    }
  }
}

.share-social-container {
  margin-bottom: $gutter/2;
  display: flex;

  .share-social-left-wrapper {
    border-right: 1px solid $color-grey;
    margin-right: $gutter/2;
  }

  a {
    &.social-icon {
      width: 50px;
      height: 50px;
      background: $color-main;
      border-radius: 60px;
      text-align: center;
      padding: $gutter/2 - 5px;
      display: inline-block;
      margin-right: $gutter/4;

      img {
        max-height: 100%;
      }

      &.fb {
        background: #3b5998;
      }
      &.tw {
        background: #4099ff;
      }
      &.email {
        background: $color-black;
      }
      &.google {
        background: #db3236;
      }
      &.windows {
        background: #7cbb00;
      }

      i {
        color: $color-white;
        font-size: 25px;
      }
    }
  }
}

.our-opinion-container {
  display: grid;

  .text-number-container {
    display: grid;
    grid-template-columns: calc(4 / 5 * 100%) calc(1 / 5 * 100%);
  }

  .text-wrapper {
    p {
      line-height: 27px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  .number-wrapper {
    span {
      display: block;
      min-height: 200px;
      background: $color-grey;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $gutter/2;
      font-size: 100px;
      font-weight: 500;
    }
  }
}

@media (max-width: $desktop) {
  .movie-pt-container {
    .movie-poster-wrapper {
      img {
        min-height: 500px;
        max-height: 500px;
      }
    }

    .movie-trailer-wrapper {
      iframe {
        min-height: 500px;
        max-height: 500px;
      }
    }
  }
  .our-opinion-container {
    .text-wrapper {
    }
    .number-wrapper {
      span {
        font-size: 90px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .movie-pt-container {
    grid-template-columns: 1fr;

    .movie-poster-wrapper {
      display: none;
    }
    .movie-trailer-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      iframe {
        min-height: auto;
        max-height: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .movie-info-container {
    .section-description-wrapper {
      grid-template-columns: 1fr;
    }
    .movie-info-left-wrapper {
      grid-column: 1 / 1;
    }
    .movie-info-right-wrapper {
      grid-column: 1 / 1;
      padding-left: 0;
    }
  }
  .our-opinion-container {
    .text-number-container {
      display: flex;
      flex-wrap: wrap-reverse;
    }

    .number-wrapper {
      margin-bottom: $gutter/2;

      span {
        font-size: 70px;
        max-width: 150px;
        min-height: 150px;
        margin: 0 auto;
      }
    }

    .text-wrapper,
    .number-wrapper {
      width: 100%;
    }
  }
  .share-social-container {
    margin-bottom: $gutter/2;
  }
}

@media (max-width: $tablet-portrait) {
  .movie-pt-container {
    .movie-poster-wrapper,
    .movie-trailer-wrapper {
    }
    .movie-poster-wrapper {
      display: none;
    }
    .movie-trailer-wrapper {
      iframe {
        min-height: auto;
        max-height: auto;
      }
    }
  }
  .share-social-container {
    margin-bottom: $gutter/2;
    display: grid;

    .share-social-left-wrapper {
      border-right: 0;
    }
  }
}

@media (max-width: $mobile) {
  .movie-info-container {
    .movie-info-right-wrapper {
      .info-row {
        hgroup,
        summary {
          width: 100%;
        }
      }
    }
  }
  .our-opinion-container {
    .number-wrapper {
      margin-bottom: $gutter/2;

      span {
        font-size: 50px;
        max-width: 100px;
        min-height: 100px;
        margin: 0 auto;
      }
    }

    .text-wrapper,
    .number-wrapper {
      width: 100%;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--<style src="../assets/main.css"></style>-->
