<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'"
      :size="'40px'"></moon-loader>
    <div class="container-fw" v-show="!loading.status">
      <div class="title-section__wrapper">
        <h2>Bon appétit</h2>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>Bon appétit</li>
        </ul>
      </div>
      <!-- Breadcrumb -->
      <div class="content-section__wrapper">
        <div class="listing__container">
          <template v-for="recipe in recipes">
            <RecipeComponent :recipe="recipe" :isListing="true"></RecipeComponent>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import RecipeComponent from "@/components/recipe/RecipeComponent";

export default {
  components: {
    MoonLoader,
    RecipeComponent
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      recipes: "recipes/getRecipes"
    })
  },
  metaInfo() {
    return {
      title: this.loading.state ? "Loading..." : "Bon Appétit",
      // all titles will be injected into this template
      titleTemplate: "%s | LexpressCulture"
    };
  },
  beforeMount() {
    this.$store.dispatch("recipes/load");
  }
};
</script>

<style lang="scss" scoped>
.content-section__wrapper {
  .listing__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: $gutter/2;
    grid-row-gap: $gutter/2;
    margin-bottom: $gutter * 2;
  }
}

@media (max-width: $desktop) {
  .content-section__wrapper {
    .listing__container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: $tablet) {
  .content-section__wrapper {
    .listing__container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: $tablet-portrait) {
  .content-section__wrapper {
    .listing__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $mobile) {
  .content-section__wrapper {
    .listing__container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
