<template>
  <div class="container-fw" v-if="events.length">
    <div class="hm-event-wrapper">
      <div class="section-title">
        <h2>Ki bon plan?</h2>
      </div>

      <div class="event-preview-wrapper">
        <div class="event-preview-thumbnail" v-for="(event, index) in events"
          :key="index">
          <router-link :to="'evenement/' + event.getSlug()">
            <div class="more-info-slider">
              <div class="more-info-content">
                <div class="event-view-more-btn">
                  <a class="event-info-btn" href="">
                    <h3 class="more-info-btn">Plus d'info</h3>
                  </a>
                </div>
              </div>
            </div>
            <div class="event-card">
              <div class="date-wrap">
                <div class="date-count" v-if="event.getStartat()">
                  <div class="event-date" v-if="event.getStartat()">{{event.getStartat().toDate()
                    | day}}</div>
                  <div class="event-month" v-if="event.getStartat()">{{event.getStartat().toDate()
                    | month}}</div>
                </div>
              </div>
              <img class="event-thumbnail" src="@/assets/placeholder/2x1.svg"
                alt="">
              <div class="event-poster" v-if="event.getImage()" :style="{ 'background-image': 'url(' + event.getImage() + ':thumb' +')'  }"></div>
              <div class="event-title-thumbnail">
                <h3 id="event-title-label" v-if="event.getName()">{{
                  event.getName() }}</h3>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <ViewMoreButton theme="dark" url="/evenements" />
    </div>
  </div>
</template>
<script>
import ViewMoreButton from "@/components/shared/ViewMoreButton";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {};
  },
  components: {
    ViewMoreButton,
  },
  computed: {
    ...mapGetters({
      events: "homepage/getEvents",
    }),
  },
  filters: {
    date(date) {
      return moment(date)
        .locale("fr")
        .format("MMM Do");
    },
    day(date) {
      return moment(date)
        .locale("fr")
        .format("D");
    },
    month(date) {
      return moment(date)
        .locale("fr")
        .format("MMM");
    },
  },
};
</script>


<style lang="scss">
.section-title {
  h2 {
    font-size: 36px;
    margin: 0;
    padding-bottom: 10px;
  }
}

.hm-event-wrapper {
  width: 100%;
  padding: $gutter * 1.5 0;

  .event-additional-wrapper {
    text-align: end;
    a {
      color: black;
    }
  }
}

.event-preview-wrapper {
  display: grid;
  grid-template-areas:
    "l s1 s2"
    "l s3 s4";
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: $gutter/2;

  .event-preview-thumbnail {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    :hover {
      .more-info-slider {
        opacity: 1;
      }
    }

    .more-info-slider {
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      color: white;
      // top: 400px;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      .more-info-content {
        width: 100%;
        height: calc(100%-25px);
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;

        .event-view-more-btn {
          width: 100%;
          margin: auto;
          padding: 0;
          margin: 0 auto;
          text-align: center;
          width: 140px;

          .event-info-btn {
            text-decoration: none;
            color: white;

            .more-info-btn {
              margin: 0;
            }
          }
        }
      }
    }
    .date-wrap {
      position: absolute;
      background: white;
      left: 10px;
      top: 10px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      border-radius: 4px;
      color: $color-main;

      .date-count {
        color: $color-main;
        text-transform: uppercase;
        text-align: center;

        .event-date {
          font-weight: 500;
          font-size: 23px;
          line-height: 22px;
          letter-spacing: 0px;
          color: $color-main;
        }
        .event-month {
          font-size: 10px;
          color: $color-main;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }

    .event-poster {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      position: absolute;
      background-position: center center;
      left: 0;
      top: 0px;
    }

    .event-title-thumbnail {
      position: absolute;
      // padding: 0;
      margin: 0;
      z-index: 2;
      width: 100%;
      padding: 6px 0;
      color: $color-white;
      background-color: $color-event-bleu;
      bottom: 0;

      #event-title-label {
        margin: 0 10px;
        padding: 0;
        color: $color-white;
        font-size: 16px;
        font-weight: 500;
      }
    }
    &:nth-child(1) {
      grid-area: l;

      .more-info-content {
        height: 85%;
        grid-template-rows: 1fr 0.5fr;
        .event-view-more-btn {
          margin: auto;
          width: 200px;

          .more-info-btn {
            margin: 0;
          }
        }
        .event-option {
          margin: auto;
          .event-info-wrapper {
            .event-info-icon {
              padding: 30px 60px;
            }
          }
        }
      }

      .date-wrap {
        width: 80px;
        height: 80px;
        text-align: center;

        .date-count {
          color: white;

          .event-date {
            font-weight: 500;
            font-size: 30px;
            line-height: 38px;
            letter-spacing: 0;
          }
          .event-month {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.event-additional-wrapper {
  text-align: end;

  a {
    color: black;
  }
}

@media (max-width: $desktop) {
}

@media (max-width: 900px) {
  .event-preview-wrapper {
    display: grid;
    grid-template-areas:
      "l l"
      "s1 s2"
      "s3 s4";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr 1fr;
    grid-gap: 20px;

    .event-preview-thumbnail {
      &:nth-child(1) {
        grid-area: l;
      }
    }
  }
}

@media (max-width: $tablet) {
}

@media (max-width: $tablet-portrait) {
  .hm-event-wrapper {
    .event-preview-wrapper {
      display: grid;
      grid-template-areas: "l l" "s1 s2" "s3 s4";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 2fr 1fr 1fr;
      grid-gap: $gutter/2;

      .event-preview-thumbnail {
        &:nth-child(1) {
          grid-area: l;
        }

        .event-title-thumbnail {
          #event-title-label {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .event-preview-wrapper {
    .event-preview-thumbnail {
      .event-title-thumbnail {
        #event-title-label {
          width: 90%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>


