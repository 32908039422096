function setBannerItems(state, payload) {
  state.banners.items = payload.items;
}

function setBannerEvents(state, payload) {
  state.banners.events = payload.events;
}

function setBannerMovies(state, payload) {
  state.banners.movies = payload.movies;
}

function setBannerRecipes(state, payload) {
  state.banners.recipes = payload.recipes;
}

function setAds(state, payload) {
  state.ads = payload.ads;
}

function setMovies(state, payload) {
  state.movies = payload.movies;
}

function setEvents(state, payload) {
  state.events = payload.events;
}

function setRecipes(state, payload) {
  state.recipes = payload.recipes;
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

export default {
  setBannerItems,
  setBannerEvents,
  setBannerMovies,
  setBannerRecipes,
  setAds,
  setMovies,
  setEvents,
  setRecipes,
  hasFetched
};
