<template>
  <div class="view-more" :class="[size, theme]">
    <router-link class="effect-box more-text-wrapper" :to="{ path : url}">
      Voir plus
    </router-link>
  </div>
</template>
<script>
  export default {
    name: 'ViewMoreButton',
    props: {
      theme: {
        type: String,
        default: 'light'
      },
      url: {
        type: String,
        default: '/'
      },
      size: {
        type: String,
        default: 'large'
      }
    }
  }
</script>
<style lang="scss" scoped>
  .view-more {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 30px;

    &.dark {
      .more-text-wrapper {
        color: #222;
        border-bottom: 7px solid #222;
      }

      a.effect-box:after {
        // border-bottom: 1px solid;
        border-top: 8px solid #cccccc;
        box-shadow: 0 0 10px #cccccc;
      }
    }

    &.small {
      padding-top: 0;

      .more-text-wrapper {
        font-size: 15px;
        border-bottom-width: 3px;
        padding-left: 15px;
      }

      a.effect-box:after {
        border-top-width: 4px;
      }
    }

    .more-text-wrapper {
      position: relative;
      font-weight: bold;
      line-height: normal;
      font-size: 27px;
      text-transform: lowercase;

      color: #ffffff;
      border-bottom: 7px solid #C4C4C4;
      text-align: right;
      padding-bottom: 10px;
      padding-left: 30px;
      display: inline-block;
      text-decoration: none;
    }
  }


  /*effect-box*/
  a.effect-box:after,
  a.effect-box:before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    // height: 1em;
    width: 100%;
    margin-top: 9px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
  }

  // a.effect-box:before {
  //   border-left: 1px solid;
  //   border-right: 1px solid;
  //   -webkit-transform: scale(1, 0);
  //   transform: scale(1, 0);
  // }

  a.effect-box:after {
    // border-bottom: 1px solid;
    border-top: 8px solid #fff;
    box-shadow: 0 0 10px #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    // border-radius: 10px;
  }

  a.effect-box:hover:after,
  a.effect-box:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }


  @media (max-width: $tablet-portrait) {
    .view-more {
      justify-content: center;

      .more-text-wrapper {
        padding: 10px 20px;
        font-size: 22px;
      }
    }
  }

  @media (max-width: $mobile) {
    .view-more {
      .more-text-wrapper {
        padding: 10px 20px;
        font-size: 18px;
      }
    }
  }
</style>