<template>
  <header :class="online ? 'online' : 'offline'">
    <div class="container-fw">
      <div class="logo-wrapper">
        <a href="http://lexpress.mu">
          <img v-if="online" class="logo" src="../../assets/logo.png" alt="lexpress.mu culture">
          <span v-else class="offlineIndicator">You appear to be offline :( </span>
        </a>
      </div>
      <div class="menu-wrapper">
        <div class="back-to-lexp-wrapper">
          <router-link :to="{ 'path': '/'}">
            <div class="home-icon-wrapper"><i class="material-icons">home</i></div>
          </router-link>
        </div>
        <div class="menu-button-wrapper">
          <button type="button" v-on:click="toggleShow">
            <span>
              <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="16px" height="24px" viewBox="0 0 16 24" enable-background="new 0 0 16 24" xml:space="preserve">
                <g>
                  <rect x="13" y="5" width="2" height="2"/>
                  <rect x="7" y="5" width="2" height="2"/>
                  <rect x="1" y="5" width="2" height="2"/>
                  <rect x="13" y="11" width="2" height="2"/>
                  <rect x="7" y="11" width="2" height="2"/>
                  <rect x="1" y="11" width="2" height="2"/>
                  <rect x="13" y="17" width="2" height="2"/>
                  <rect x="7" y="17" width="2" height="2"/>
                  <rect x="1" y="17" width="2" height="2"/>
                </g>
              </svg>
              </span>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
  .offlineIndicator {
    line-height: 65px;
    display: block;

    font-size: 16px;
    font-weight: 500;
  }

  .offline .logo-wrapper a {
    padding-top:0;
    text-decoration: none;
  }

  header.offline {
    background: #fee066;
  }

  header.offline a {
    color:black;
  }

  #app {
    &.noScroll {
      overflow-y: hidden;
    }
  }

  header {
    background: $color-main;
    height: 65px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: map-get($z-index, top);

    .container-fw {
      display: grid;
      grid-template-columns: 1fr 0.5fr;
    }

    .logo-wrapper {
      grid-column: 1 / 2;

      a {
        display: block;
        padding-top: 13px;
        height: 65px;

        img {
          width: 100%;
          max-width: 149px;
        }
      }
    }

    .menu-wrapper {
      grid-column: 2 / 3;
      display: flex;
      justify-content: flex-end;

      .back-to-lexp-wrapper {
        width: 50px;
        height: 65px;

        a {
          color: $color-white;
          text-decoration: none;
          padding: 20px $gutter/5;
          display: block;
          text-align: center;
          height: 65px;
          transition: all 0.3s $ease-in-out-quad;

          &:hover {
            background: rgba($color-white, 0.1);
          }

          .text-wrapper, .home-icon-wrapper {
            display: block;
          }
          .home-icon-wrapper {
            i {
              font-size: 26px;
            }
          }
        }
      }
      .menu-button-wrapper {
        button {
          height: 65px;
          width: 50px;
          background: transparent;
          border: 0;
          color: $color-white;
          transition: all 0.3s $ease-in-out-quad;
          padding-top: $gutter/6;

          &:hover {
            background: rgba($color-white, 0.1);
            cursor: pointer;
          }

          &:focus {
            outline: none;
            border: none;
          }

          svg {
            g {
              rect {
                fill: $color-white;
              }
            }
          }
        }
      }

    }

  }

  .jumbo-menu-container {
    width: 100%;
    background: #222;
    z-index: map-get($z-index, very-high);
    //padding: $gutter;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //display: none;

    height: 100%;
    overflow-y: scroll;

    /** Animation **/
    //height: 0;
    //overflow: hidden;
    //animation-name: slide-menu-down;
    //animation-timing-function: $ease-in-out-expo;
    //animation-duration: 1s;
    //animation-fill-mode: forwards;
    //overflow-y: scroll;

    .utilities-wrapper, .menu-content-wrapper {
      width: 100%;
    }

    .utilities-wrapper {
      button {
        float: right;
        height: 65px;
        width: 55px;
        background: transparent;
        border: 0;
        color: $color-white;
        transition: all 0.3s $ease-in-out-quad;
        //padding-top: $gutter/6;

        &:hover {
          //background: rgba($color-white, 0.1);
          cursor: pointer;
        }

        &:focus {
          outline: none;
          border: none;
        }

        svg {
          g {
            rect {
              fill: $color-white;
            }
          }
        }
      }
    }
    .menu-content-wrapper {
      width: 100%;

      h2 {
        font-weight: 500;
        margin-bottom: $gutter/2;
        padding-bottom: $gutter/2;
        color: $color-white
        //border-bottom: 1px solid rgba($color-white, 0.4);
      }

      .links-wrapper {
        width: 100%;
        padding-top: $gutter/2;

        > ul {
          margin: 0;
          padding: 0;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: $gutter/2;
          flex-wrap: wrap;

          @each $section-color, $bgcolor in $sections {
            li {
              &[data-menu-section="#{$section-color}"]{
                > a {
                  border-bottom-color: $bgcolor;
                }

                &:hover {
                  > a {
                    color: $bgcolor;
                  }
                }
              }
            }
          }

          > li {
            margin: 0;
            padding: 0;
            list-style-type: none;
            margin-bottom: $gutter/2;
            transition: all 0.3s $ease-in-out-quint;

            > a {
              text-decoration: none;
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: $gutter/2;
              display: block;
              color: $color-white;
              border-bottom-width: 2px;
              border-bottom-color: $color-black;
              border-bottom-style: solid;
              padding: $gutter/4 0;
              text-decoration: none;
            }

            ul {
              padding: 0;
              margin: 0;

              li {
                display: block;
                width: 100%;
                padding: 0;

                a {
                  margin-bottom: 0px;
                  text-transform: initial;
                  font-weight: 300;
                  color: $color-white;
                  border-bottom: 0;
                  padding: initial;
                  font-size: 13px;
                  text-decoration: none;


                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .jumbo-menu-container {
      .menu-content-wrapper {
        .links-wrapper {
          > ul {
            grid-template-columns: repeat(4, 1fr);
          }
        }
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    .jumbo-menu-container {
      .menu-content-wrapper {
        .links-wrapper {
          > ul {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .jumbo-menu-container {
      .menu-content-wrapper {
        .links-wrapper {
          padding-top: 0;

          > ul {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active {
    transition: all .3s ease-in-out;
    transform: scale(1);
    opacity: 1;
  }
  .slide-fade-leave-active {
    transition: all .3s ease-in-out;

    //animation-name: slide-menu-up;
    //animation-timing-function: $ease-in-out-quart;
    //animation-duration: 1s;
    //animation-fill-mode: forwards;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: scale(1.1);
    opacity: 0;
  }

</style>


<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'MainHeader',
    props: [
      'toggleShow'
    ],
    computed: {
      ...mapGetters([
        'online'
      ])
    },
    methods: {
    },
    components: {
    }
  }
</script>
