<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'" :size="'40px'"></moon-loader>
    <template v-show="!loading.status">
      <HomeHero />
      <HomeIntersectionAd />
      <HomeCine />
      <HomeEvenements />
      <HomeRecettes />
    </template>
  </div>
</template>

<script>
import HomeCine from "@/components/homepage/HomeCine.vue";
import HomeEvenements from "@/components/homepage/HomeEvenements.vue";
import HomeHero from "@/components/homepage/HomeHero.vue";
import HomeIntersectionAd from "@/components/homepage/HomeIntersectionAd.vue";
import HomeRecettes from "@/components/homepage/HomeRecettes.vue";
import HomeSubscribe from "@/components/homepage/HomeSubscribe.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { mapGetters } from 'vuex'

export default {
  components: {
    HomeCine,
    HomeEvenements,
    HomeHero,
    HomeIntersectionAd,
    HomeRecettes,
    HomeSubscribe,
    MoonLoader
  },
  computed: {
    ...mapGetters(["loading"])
  },
  beforeMount() {
    this.$store.dispatch("homepage/load");
  }
};
</script>
