<template>
  <div class="date-selection-container">
    <a class="date-block-wrapper" :class="{ active: date.selected }" v-on:click="setFilter({'date': date.timestamp})" :key="index" v-for="(date, index) in dates">
      <div class="date-block-content-wrapper">
        <div class="date-block-digit">{{ date.day }}</div>
        <div class="date-block-text">{{ date.month }}</div>
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DatePicker",
  props: ["position"],
  computed: {
    ...mapGetters({
      dates: "movies/getDates",
      filterInfo: "movies/filterInfo"
    })
  },
  methods: {
    setFilter: function(message) {
      this.$store.commit({
        type: "movies/SET_FILTER",
        payload: message
      });
    }
  },
  data() {
    return {
      msg: "Date Picker"
    };
  },
  watch: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.filters-wrapper {
  display: block;
  //padding: $gutter/4 $gutter/8;
  background: $color-white;
  padding: 0 $gutter/8;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s $ease-in-out-quint;

  &.show {
    max-height: 999px;
    overflow: scroll;
  }

  .date-selection-container {
    margin-top: $gutter/8;
  }

  .theater-picker-container {
    margin-bottom: $gutter/8;
  }

  .theatre-picker {
    .theatre-item {
      margin-bottom: 5px;
    }
  }
}

.filter-container {
  display: none;

  @media (max-width: $tablet) {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: map-get($z-index, top);
    background: $color-white;
    box-shadow: 0px -1px 5px rgba($color-black, 0.3);

    .filter-menu-wrapper {
      width: 100%;
      background: rgba($color-grey, 0.3);

      .filter-text-wrapper {
        font-size: 16px;
        font-weight: 500;
        padding-top: $gutter/4 + 1px;
        padding-left: $gutter/4;
      }
      .filter-button-wrapper {
        button {
          float: right;
          background: $color-main;
          box-shadow: 0px 0px 5px rgba($color-black, 0.2);
          border: 0;
          height: 40px;
          width: 70px;

          i {
            color: $color-white;
          }
        }
      }
    }

    .reset-filter-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background: rgba($color-green, 0.6);
      margin-bottom: 5px;
      transition: all 0.2s $ease-in-out-quad;
      border: 0;
      font-family: $font-roboto;
      margin-bottom: $gutter/8;
      margin-top: $gutter/8;
      padding: $gutter/8;

      &:focus {
        outline: none;
      }

      &,
      i {
        color: $color-white;
      }

      i {
        margin-right: $gutter/4;
      }
    }
  }
}

.date-selection-container {
  //margin-bottom: $gutter/4;
  display: flex;
  width: 100%;
  justify-content: flex-start;

  .date-block-wrapper {
    //min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s $ease-in-out-quad;
    flex: 1 0 auto;
    height: auto;
    margin-right: $gutter/4;
    max-width: 100px;
    border-width: 3px;
    border-color: $color-grey;
    border-style: solid;
    background: $color-grey;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.03),
      0 5px 15px rgba(0, 0, 0, 0.06);
    user-select: none;
    //padding: 2%;

    .date-block-content-wrapper {
      width: 100%;
      text-align: center;
    }

    .date-block-digit,
    .date-block-text,
    .date-block-icon {
      &,
      i {
        transition: all 0.1s $ease-in-out-quad;
      }
    }
    .date-block-digit {
      color: rgba($color-black, 0.4);
      font-size: 37px;
      line-height: 37px;
    }
    .date-block-text {
      font-size: 20px;
      color: rgba($color-black, 0.5);
      letter-spacing: -0.5px;
    }
    .date-block-icon {
      border-color: $color-red;
      i {
        font-size: 45px;
        color: rgba($color-green, 0.5);
      }
    }

    &:before {
      content: "";
      float: left;
      padding-top: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      //background: $color-main;
      cursor: pointer;
      border-color: rgba($color-main, 0.8);

      .date-block-digit,
      .date-block-text {
        &,
        i {
          color: rgba($color-main, 0.8);
        }
      }

      .date-block-icon {
        &,
        i {
          color: $color-green;
        }
      }
    }

    &.reset {
      border-color: rgba($color-green, 0.5);
      background: $color-white;

      &:hover {
        border-color: rgba($color-green, 1);
      }
    }

    &.active {
      background: $color-white;
      border-color: $color-main;
      .date-block-digit,
      .date-block-text,
      .date-block-icon {
        &,
        i {
          color: $color-main;
        }
      }
    }
  }

  // Movie page
  &.movie {
    margin-bottom: $gutter/4;

    .date-block-wrapper {
      max-width: 80px;

      .date-block-digit {
        font-size: 30px;
        line-height: 30px;
      }
      .date-block-text {
        font-size: 16px;
        letter-spacing: -0.5px;
      }
      .date-block-icon {
        i {
          font-size: 30px;
        }
      }
    }
  }
}

@media (max-width: $desktop) {
  .date-selection-container {
    .date-block-wrapper {
      .date-block-digit {
        font-size: 32px;
        line-height: 32px;
      }
      .date-block-text {
        font-size: 18px;
      }
      .date-block-icon {
        i {
          font-size: 38px;
        }
      }
    }

    // Movie page
    &.movie {
      justify-content: flex-start;

      .date-block-wrapper {
        min-width: auto;
        min-height: 51px;
        min-width: 51px;
        max-width: 51px;
        padding: 0;

        .date-block-digit {
          font-size: 20px;
          line-height: 20px;
        }
        .date-block-text {
          font-size: 12px;
          letter-spacing: -0.5px;
        }
        .date-block-icon {
          i {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .date-selection-container {
    //margin-bottom: $gutter/4;

    .date-block-wrapper {
      max-width: 80px;
      border-width: 3px;

      .date-block-digit {
        font-size: 30px;
        line-height: 30px;
      }
      .date-block-text {
        font-size: 15px;
      }
      .date-block-icon {
        i {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .date-selection-container {
    margin-bottom: 0;

    .date-block-wrapper {
      margin-right: $gutter/8;
    }

    // Movie page
    &,
    &.movie {
      .date-block-wrapper {
        max-width: 50px;
        padding: 0;
        border-width: 2px;

        .date-block-digit {
          font-size: 18px;
          line-height: 18px;
        }
        .date-block-text {
          font-size: 12px;
          line-height: 12px;
        }
        .date-block-icon {
          i {
            font-size: 26px;
            line-height: 26px;
          }
        }
      }
    }
  }

  .theatre-picker {
    .theatre-item {
      margin-bottom: $gutter/8;
    }
  }
}

.slideFilter-enter-active {
  transition: all 0.4s $ease-in-out-quad;
  transform: translateY(0%);
}
.slideFilter-leave-active {
  transition: all 0.4s $ease-in-out-quad;
}
.slideFilter-enter,
.slideFilter-leave-to {
  transform: translateY(100%);
}

.slideFilterPanel-enter-active {
  transition: all 0.4s $ease-in-out-quad;
  transform: translateY(0%);
}
.slideFilterPanel-leave-active {
  transition: all 0.4s $ease-in-out-quad;
}
.slideFilterPanel-enter,
.slideFilterPanel-leave-to {
  transform: translateY(100%);
}
</style>
