<template>
  <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'" :size="'40px'"></moon-loader>
  <div class="content-container" v-else>
    <div class="container-fw">
      <div class="title-section__wrapper">
        <h2 v-if="venue.getName()">{{venue.getName()}}</h2>
      </div>

      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>
            <router-link to="/venues" title="Ki bon baz?">Ki bon baz?</router-link>
          </li>
          <li v-if="venue.getName()">{{venue.getName()}}</li>
        </ul>
      </div>
      <!-- Breadcrumb  -->
      <section class="generic-layout page-venue">
        <div class="generic-content">
          <div class="section-container">
            <div class="section-description-wrapper">
              <div class="slider__container" v-if="venue.getVenuegalleriesList().length > 0">
                <swiper :options="VenueSwiperTop" ref="VenueSwiperTop">
                  <swiper-slide
                    v-if="venue.getVenuegalleriesList().length > 0"
                    class="slider__item"
                    v-for="(image, index) in venue.getVenuegalleriesList()"
                  >
                    <img :src="image.getImage() + ':full'">
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                <swiper :options="VenueSwiperThumbs" class="gallery-thumbs" ref="VenueSwiperThumbs">
                  <swiper-slide
                    v-if="venue.getVenuegalleriesList().length > 0"
                    class="slider__item"
                    v-for="image in venue.getVenuegalleriesList()"
                  >
                    <img :src="image.getImage() + ':thumb'">
                  </swiper-slide>
                </swiper>
              </div>
              <div class="event-main-image" v-else>
                <figure>
                  <img
                    v-if="venue.getImage()"
                    :src="venue.getImage() + ':full'"
                    :alt="venue.getName()"
                  >
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="generic-sidebar">
          <!-- Event details -->
          <div class="section-container">
            <div class="section-description-wrapper">
              <div class="event-details-wrapper">
                <div class="event-information-wrapper">
                  <div class="info-wrapper">
                    <div class="icon-wrapper">
                      <i class="material-icons">location_on</i>
                    </div>
                    <div class="title-wrapper">
                      <h4>Location</h4>
                      <div class="info-row-wrapper">
                        <template v-if="venue.getAddr()">
                          {{venue.getAddr()}}
                          <br>
                        </template>
                        <a :href="'mailto:' + venue.getEmail() ">{{venue.getEmail()}}</a>
                        <br>
                        <a :href="venue.getWebsite()">{{venue.getWebsite()}}</a>
                        <br>
                        <a :href="'tel:' + venue.getContactnum()">{{venue.getContactnum()}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="info-wrapper" v-if="venue.getOpeninghours()">
                    <div class="icon-wrapper">
                      <i class="material-icons">access_time</i>
                    </div>
                    <div class="title-wrapper">
                      <h4>Heures d'ouverture</h4>
                      <div
                        class="info-row-wrapper"
                        v-if="venue.getOpeninghours()"
                      >{{venue.getOpeninghours()}}</div>
                    </div>
                  </div>

                  <div class="info-wrapper" v-if="venue.getFacilities()">
                    <div class="icon-wrapper">
                      <i class="material-icons">wifi</i>
                    </div>
                    <div class="title-wrapper" v-if="venue.getFacilities()">
                      <h4>Aménagements</h4>
                      <div class="info-row-wrapper">{{venue.getFacilities()}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Event details -->
        </div>
      </section>

      <section class="generic-layout page-venue">
        <div class="generic-content">
          <div class="section-container">
            <div class="section-description-wrapper">
              <article v-if="venue.getNotes()" v-html="venue.getNotes()"></article>
            </div>
          </div>
          <div class="section-container" v-if="venue.getLatlng()">
            <div class="section-title-wrapper">
              <h2>Emplacement</h2>
            </div>
            <div class="section-description-wrapper">
              <div class="locations-wrapper">
                <gmap-map
                  v-if="marker !== null"
                  :center="marker"
                  :zoom="13"
                  map-type-id="satellite"
                  style="width:100%; height: 500px;"
                >
                  <gmap-marker v-if="marker !== null" :position="marker" @click="marker=marker"></gmap-marker>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <div class="generic-sidebar"></div>
      </section>
    </div>
  </div>
</template>

<script>
// require styles
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  components: {
    swiper,
    swiperSlide,
    MoonLoader,
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      venue: "venues/getVenue",
    }),
  },
  data() {
    return {
      VenueSwiperTop: {
        slidesPerView: 1,
        grabCursor: true,
        loopedSlides: 5,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      VenueSwiperThumbs: {
        spaceBetween: 5,
        slidesPerView: 4,
        touchRatio: 0.2,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        slideToClickedSlide: true,
      },
      venueInfo: "",
      marker: null,
      currentPosition: null,
    };
  },
  beforeMount() {
    this.$store.dispatch("venues/getVenueBySlug");
  },
  updated() {
    setTimeout(() => {
      this.$nextTick(() => {
        if (
          this.$refs.hasOwnProperty("VenueSwiperTop") &&
          this.$refs.hasOwnProperty("VenueSwiperThumbs")
        ) {
          const swiperTop = this.$refs.VenueSwiperTop.swiper;
          const swiperThumbs = this.$refs.VenueSwiperThumbs.swiper;
          swiperTop.controller.control = swiperThumbs;
          swiperThumbs.controller.control = swiperTop;
        }
      });
    }, 500);
  },
  metaInfo() {
    return {
      title: this.loading.state
        ? "Loading..."
        : this.venueInfo
        ? this.venueInfo.getName()
        : "",
      titleTemplate: "%s | LexpressCulture",
    };
  },
  watch: {
    venue(val) {
      this.venueInfo = val;

      let position = this.venue
        .getLatlng()
        .split(",")
        .reverse();

      const marker = {
        lat: parseFloat(position[0]),
        lng: parseFloat(position[1]),
      };

      this.marker = marker;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-venue {
  display: flex;

  .generic-content {
    width: 70%;
    margin-right: 20px;
  }

  .generic-sidebar {
    width: 30%;
  }
}

.section-description-wrapper {
  position: relative;
}

.slider__container {
  position: relative;
  overflow: hidden;

  .slider__item {
    img {
      height: 500px;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .gallery-thumbs {
    .slider__item {
      img {
        height: 100px;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}

.event-main-image {
  position: relative;

  figure {
    padding: 0;
    margin: 0;
    line-height: 0;

    img {
      margin: 0 !important;
      width: 100%;
      border: 0;
      max-height: 500px;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

article {
  width: 100%;
  font-weight: 300;
  line-height: 26px;

  h1 {
    font-family: $font-roboto;
    color: $color-black;
  }

  a {
    color: $color-main;
  }

  figure {
    padding: 0;
    margin: 0;
    line-height: 0;

    img {
      margin: 0 !important;
      width: 100%;
    }

    figcaption {
      //background: rgba($color-black, 0.2);
      color: $color-black;
      text-align: left;
      padding: $gutter/8 $gutter/4;
      background: $color-grey;

      p {
        margin: 0;
        font-size: 14px;
      }
    }

    iframe {
      margin: 0;
      padding: 0;
    }
  }

  p,
  ul,
  ol {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-black;
    font-family: $font-roboto;
    a {
      color: $color-black;
      text-decoration: none;
    }
  }

  hr {
    border-top: 0;
    border-bottom: 1px solid rgba($color-black, 0.2);
    border-left: 0px;
    margin: $gutter/2 0;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 20px;
    margin: $gutter/3 0;
    color: $color-black;

    li {
      padding-left: $gutter/3;
    }

    @for $i from 1 through 4 {
      ol,
      ul {
        padding-left: 30px;
      }
    }
  }

  ul {
    list-style-type: none;
    position: relative;

    li {
      position: relative;

      &:before {
        content: "";
        background: $color-main;
        height: 8px;
        width: 8px;
        border-radius: 5px;
        position: absolute;
        left: -14px;
        top: 10px;
        display: block;
        //margin-left: -7px;
        font-size: 9px;
      }
    }
  }

  blockquote {
    font-family: "Georgia";
    width: 100%;
    //float: right !important;
    right: 0;
    padding: $gutter - 10px;
    margin: 0;

    p {
      padding: 0;
      font-size: 18px;
      font-style: italic;
      position: relative;

      &:before,
      &:after {
        font-family: "Georgia";
        font-size: 60px;
        display: block;
        color: $color-black;
        font-style: initial;
      }

      &:before {
        position: absolute;
        content: "\201C";
        left: -25px;
        top: -10px;
      }

      &:after {
        position: absolute;
        content: "\201D";
        right: -15px;
      }
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  u {
    text-decoration: underline;
  }

  i,
  em {
    font-style: italic;
  }

  video,
  audio,
  iframe,
  embed,
  table {
    width: 100%;
  }

  table {
    border: 1px solid rgba($color-black, 0.2);
    thead,
    tfoot {
      border-bottom: 1px solid rgba($color-black, 0.2);
      background: $color-white;

      tr {
        td,
        th {
          padding: 10px;
          font-weight: 700;
          text-align: center;
          text-transform: uppercase;
          border-right: 1px solid rgba($color-black, 0.2);

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid rgba($color-black, 0.2);

        &:last-child {
          border-bottom: 0px;
        }

        td {
          padding: 10px;
          font-weight: 300;
          text-align: left;
          text-transform: none;
          border-right: 1px solid rgba($color-black, 0.2);

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  iframe {
    margin: $gutter/2 0;
  }
}

.event-details-wrapper {
  .event-title-container {
    margin-bottom: $gutter/4;
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-column-gap: $gutter/4;

    .event-title-wrapper {
      width: 100%;

      h2 {
        margin: 0;
        font-size: 30px;
      }
    }
    .event-date-wrapper {
      width: 80px;
      height: 80px;
      background: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      .date-wrapper {
        .date-digit,
        .date-text {
          color: $color-white;
          text-align: center;
        }
        .date-digit {
          font-weight: 500;
          font-size: 38px;
          line-height: 38px;
          letter-spacing: 0px;
        }
        .date-text {
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
  }
  .event-organizer-details {
    font-size: 14px;
    color: rgba($color-black, 0.6);
    margin-bottom: $gutter/2;
  }
  .event-information-wrapper {
    .info-wrapper {
      display: grid;
      grid-template-columns: 1fr 9fr;
      margin-bottom: $gutter/3;

      .icon-wrapper {
        i {
          position: relative;
          top: 3px;
          opacity: 0.6;
        }
      }
      .title-wrapper {
        h4 {
          font-weight: 500;
          padding-top: 4px;
          margin: 0;
          text-transform: uppercase;
          margin-bottom: $gutter/3;
        }
        .info-row-wrapper {
          font-size: 15px;
          margin-bottom: $gutter/5;

          a {
            text-decoration: none;
            color: $color-main;
          }
        }
      }
    }
  }
}

.locations-wrapper {
  iframe {
    width: 100%;
  }
}

.other-events-container {
  width: 100%;

  .other-events-wrapper {
    width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    a {
      &.event-wrapper {
        width: calc(100% * 1 / 3);
        float: left;
        text-align: center;
        color: $color-white;
        text-decoration: none;
        padding: $gutter * 1.5 $gutter;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;

        h2 {
          font-size: 40px;
          font-weight: 500;
          margin: 0 0 $gutter/3 0;
        }

        .details-text-wrapper {
          opacity: 0;
          transform: translateY(30px);
          transition: all 0.4s ease-in-out;
          position: absolute;
          left: 50%;
          right: 50%;
        }

        &.first {
          background: darken($color-main, 10);
        }
        &.second {
          background: $color-main;
        }
        &.third {
          background: rgba($color-main, 0.9);
        }

        &:hover {
          .details-text-wrapper {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
  }
}

@media (max-width: $desktop) {
  .page-venue {
    display: flex;

    .generic-content {
      width: 65%;
      margin-right: 20px;
    }

    .generic-sidebar {
      width: 35%;
    }
  }
}

@media (max-width: $tablet) {
  .page-venue {
    display: flex;

    .generic-content {
      width: 63%;
      margin-right: 20px;
    }

    .generic-sidebar {
      width: 47%;
    }
  }

  .slider__container {
    .slider__item {
      img {
        height: 400px;
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .event-details-wrapper {
    .event-title-container {
      width: 100%;
      margin-bottom: $gutter/4;

      .event-title-wrapper {
        width: 100%;
        margin-right: 0;
        float: none;
        clear: both;

        h2 {
          font-size: 25px;
        }
      }
      .event-date-wrapper {
        display: none;
      }
    }
  }

  .event-main-image {
    .event-date-wrapper {
      display: flex;
    }
  }

  .page-venue {
    display: block;

    .generic-content {
      width: 100%;
      margin-right: 00px;
    }

    .generic-sidebar {
      width: 100%;
    }
  }

  .slider__container {
    .gallery-thumbs {
      .slider__item {
        img {
          height: 60px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .slider__container {
    .slider__item {
      img {
        height: 280px;
      }
    }
  }
}
</style>
