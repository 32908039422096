<template>
  <section v-if="getMovies.length">
    <div class="container-fw">
      <h2 class="section-title">Cinéma</h2>
      <swiper :options="swiperOption" ref="HomeCineSwiper">
        <swiper-slide v-for="movie in getMovies" :key="movie.getId()">
          <MovieComponent theme="light" :movie="movie"></MovieComponent>
        </swiper-slide>
      </swiper>

      <viewMoreButton url="/cinema"/>
    </div>
  </section>
</template>

<script>
  // require styles
  import "swiper/dist/css/swiper.css";

  import {swiper, swiperSlide} from "vue-awesome-swiper";
  import viewMoreButton from "@/components/shared/ViewMoreButton";
  import { mapGetters } from "vuex";
  import MovieComponent from '../movie/MovieComponent'

  export default {
    data() {
      return {
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 30,
          grabCursor: true,
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          breakpoints: {
            // when window width is <= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            // when window width is <= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            // when window width is <= 640px
            960: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },
          pagination: {
            el: ".swiper-pagination"
          }
        },
      };
    },
    computed: {
      ...mapGetters({
        getMovies: "homepage/getMovies"
      }),
      HomeCineSwiper() {
        if (this.$refs.hasOwnProperty("HomeCineSwiper")) {
          return this.$refs.HomeCineSwiper.swiper;
        }
      }
    },
    mounted() {
      setTimeout(() => {
        if (this.$refs.hasOwnProperty("HomeCineSwiper")) {
          this.HomeCineSwiper.destroy(false, false);
          this.HomeCineSwiper.init();
        }
      }, 1000)
    },
    updated () {
      if (this.$refs.hasOwnProperty("HomeCineSwiper")) {
        this.HomeCineSwiper.destroy(false, false);
        this.HomeCineSwiper.init();
      }
    },
    components: {
      swiper,
      swiperSlide,
      viewMoreButton,
      MovieComponent
    }
  };
</script>

<style lang="scss" scoped>
  section {
    background: #222;
    color: white;
    padding: $gutter 0;
  }

  .section-title {
    font-size: 36px;
    margin: 0;
    padding-bottom: 10px;
  }

  .swiper-wrapper {
    --url: "";
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $gutter;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
  }
</style>
