let getBanners = (state, getters) => {
  let items = getters.getBannerItems;
  let events = getters.getBannerEvents;
  let movies = getters.getBannerMovies;
  let recipes = getters.getBannerRecipes;
  let banners = [];

  items.map(item => {
    let type = item.getType();
    let id = item.getId();

    if (type === "Events") {
      let event = events.find(e => e.getId() === id);
      if (typeof event !== "undefined") {
        let data = {
          type: "Events",
          data: event
        };
        banners.push(data);
      }
    } else if (type === "Movies") {
      let movie = movies.find(m => m.getId() === id);
      if (typeof movie !== "undefined") {
        let data = {
          type: "Movies",
          data: movie
        };
        banners.push(data);
      }
    } else if (type === "Recipes") {
      let recipe = recipes.find(r => r.getId() === id);
      if (typeof recipe !== "undefined") {
        let data = {
          type: "Recipes",
          data: recipe
        };
        banners.push(data);
      }
    }
  });

  return banners;
};

function getBannerItems(state) {
  return state.banners.items;
}

function getBannerEvents(state) {
  return state.banners.events;
}

function getBannerMovies(state) {
  return state.banners.movies;
}

function getBannerRecipes(state) {
  return state.banners.recipes;
}

function getAds(state) {
  return state.ads;
}

function getMovies(state) {
  return state.movies;
}

function getEvents(state) {
  return state.events;
}

function getRecipes(state) {
  return state.recipes;
}

function getFetched(state) {
  return state.fetched;
}

export default {
  getBanners,
  getBannerItems,
  getBannerEvents,
  getBannerMovies,
  getBannerRecipes,
  getAds,
  getMovies,
  getEvents,
  getRecipes,
  getFetched
};
