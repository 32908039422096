import { AppClient } from "@/services/service_app_pb_service.js";
import { EmptyRequest, VenueListRequest, VenueBySlugRequest } from "@/services/service_app_pb";
import router from "@/router";

function load({ state, commit }) {
  let client = new AppClient("/api");
  let req = new EmptyRequest();

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching Towns & Venue Types data"
    },
    { root: true }
  );
  /**** Loader ****/

  if (!state.fetched) {
    client.getVenueTypes(req, (err, res) => {
      if (err == null) {
        commit("hasFetched", { value: true });
        /**** Loader ****/
        commit(
          "SET_STATUS",
          {
            status: false,
            message: "Fetched Venue Types data"
          },
          { root: true }
        );
        /**** Loader ****/

        let venueTypes = res.getVenuetypesList();

        // set data
        commit("setVenueTypes", { data: venueTypes });
      } else {
        console.log(err);
      }
    });

    client.getTowns(req, (err, res) => {
      if (err == null) {
        commit("hasFetched", { value: true });
        /**** Loader ****/
        commit(
          "SET_STATUS",
          {
            status: false,
            message: "Fetched towns data"
          },
          { root: true }
        );
        /**** Loader ****/

        let towns = res.getTownsList();

        // set data
        commit("setTowns", { data: towns });
      } else {
        console.log(err);
      }
    });
  } else {
    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Has already fetched Towns & Venue Types data"
      },
      { root: true }
    );
    /**** Loader ****/
  }
}

function search({ state, commit }) {
  let client = new AppClient("/api");
  let req = new VenueListRequest();
  req.setTitle(state.currentKeyword)
  req.setTownid(state.currentTown)
  req.setVenuetypeid(state.currentVenueType)

  commit("setSearchStatus", 1);

  client.getVenues(req, (err, res) => {
    if (err == null) {

      commit("setHasSearched", { value: true });
      /**** Loader ****/
      commit(
        "SET_STATUS",
          {
            status: false,
            message: "Searching Venues"
          },
        { root: true }
      );
      /**** Loader ****/

      let venues = res.getVenuesList();

      console.log(venues)

      // set data
      commit("setVenues", { data: venues });
      commit("setHasSearched", { data: true });
      commit("setSearchStatus", 0);
    } else {
      console.log(err);
      commit("setSearchStatus", 0);
    }
  });
}

function setCurrentTown({ state, commit }, payload) {
  commit("setCurrentTown", { data: payload.data });
}

function setCurrentVenueType({ state, commit }, payload) {
  commit("setCurrentVenueType", { data: payload.data });
}

function setCurrentKeyword({ state, commit }, payload) {
  commit("setCurrentKeyword", { data: payload.data });
}

function getVenueBySlug({ state, commit, rootState }, payload) {
  let client = new AppClient("/api");
  let reqVenue = new VenueBySlugRequest();
  let venueSlug = rootState.route.params.id;
  let venue = state.venuesStore.find(venue => venue.getSlug() === venueSlug);

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching single venue data"
    },
    { root: true }
  );
  /**** Loader ****/

  // If event is not in memory, do gRPC call
  if (typeof venue === "undefined") {
    reqVenue.setSlug(venueSlug);
    client.getVenueBySlug(reqVenue, (err, res) => {
      if (err == null) {
        /**** Loader ****/
        commit(
          "SET_STATUS",
          {
            status: false,
            message: "Fetched single venue data"
          },
          { root: true }
        );
        /**** Loader ****/

        console.log(res);
        let venue = res.getVenue();

        commit("addVenue", { data: venue });
        commit("setVenue", { data: venue });
      } else {
        console.log(err);
        router.push({ path: "/venues" });
      }
    });
  } else {
    // else set event object in state directly
    commit("setVenue", { data: venue });

    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Already have venue in memory"
      },
      { root: true }
    );
    /**** Loader ****/
  }
}


export default {
  load,
  search,
  setCurrentTown,
  setCurrentVenueType,
  setCurrentKeyword,
  getVenueBySlug
};
