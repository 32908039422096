function setRecipes(state, payload) {
  state.recipes = payload;
}

function addRecipe(state, payload) {
  state.recipes.push(payload.recipe);
}

function setRecipe(state, payload) {
  state.recipe = payload.data;
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

export default {
  setRecipes,
  addRecipe,
  setRecipe,
  hasFetched
};
