<template>
  <a :href="review.getUrl()" target="_blank" class="review__wrapper">
    <div class="image">
      <img v-if="review.getImage()" :src="review.getImage() + ':thumb'" alt="">
    </div>
    <div class="description">
      <h3>{{ review.getTitle() }}</h3>
    </div>
    <div class="icon">
      <i class="material-icons">open_in_new</i>
    </div>
  </a>
</template>

<script>
 export default {
   data () {
     return  { }
   },
   props: {
     'review': {
       type: Object
     }
   }
 }
</script>

<style lang="scss" scoped>
  .review__wrapper {
    max-height: 180px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    color: var(--color-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    line-height: 0;

    .image {

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
                to top,
                rgba($color-black, 0.54),
                transparent 70%
        );
        transition: all 0.3s $ease-in-out-quad;
        z-index: 1;
      }

      img {
        width: 100%;
        max-height: 180px;
        object-position: 50% 50%;
        object-fit: cover;
      }
    }

    .description {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 10;

      h3 {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
        margin: 0;
        width: 100%;
        text-align: left;
        padding: 15px;
        font-family: $font-noto-serif;
        font-size: 18px;
        line-height: 25px;
      }
    }

    .icon {
      position: absolute;
      width: 100%;
      top: 10px;
      right: 10px;
      text-align: right;

      i {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      }
    }

    &:hover {
      transform: translateY(-5px);
      filter: brightness(110%);
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
  }

  @media (max-width: $tablet-portrait) {
    .review__wrapper {
      max-height: 100px;

      .image {
        img {
          max-height: 100px;
        }
      }
    }
  }

  @media (max-width: $mobile) {
  }
</style>