function setMovies(state, payload) {
  state.movies = payload;
}

function addMovie(state, payload) {
  state.movies.push(payload.movie);
}

function setMovie(state, payload) {
  state.movie = payload.data;
  state.showtimes = payload.showtimes;
  state.venues = payload.venues;
}

function setShowtimes(state, payload) {
  state.showtimes = payload.data;
}

function hasFetched(state, payload) {
  state.fetched = payload.value;
}

function SET_FILTER(state, payload) {
  state.currentDate = payload.payload.date
}

function setTheatreDate(state, payload) {
  state.currentTheatre = payload.payload.theatre
  if (payload.payload.date === null) {
    state.currentDate = null
  }
}

function setTheatres(state, payload) {
  state.theatres = payload.data
}

function setMoviesGroupedByTheatre(state, payload) {
  state.moviesGroupedByTheatre = payload.data
}

function setMoviesGroupedByDate(state, payload) {
  state.moviesGroupedByDate = payload.data
}

export default {
  setMovies,
  addMovie,
  setMovie,
  hasFetched,
  SET_FILTER,
  setTheatreDate,
  setTheatres,
  setMoviesGroupedByTheatre,
  setShowtimes,
  setMoviesGroupedByDate
};
