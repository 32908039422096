import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import homepage from './homepage/index'
import events from './events/index'
import movies from './movies/index'
import recipes from './recipes/index'
import reviews from './reviews/index'
import venues from './venues/index'
Vue.use(Vuex)

const state = {
  metadata: {
    title: 'Cinema & Evenements | lexpress.mu ',
    description: '',
    ogImage: ''
  },
  loading: {
    status: true,
    message: ['loading']
  },
  menuStatus: false
}

export default new Vuex.Store({
  state,
  modules: {
    homepage,
    events,
    movies,
    recipes,
    reviews,
    venues
  },
  getters,
  mutations,
  actions,
  strict: true
})



