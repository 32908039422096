<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'"
      :size="'40px'"></moon-loader>
    <div class="container-fw events-view" v-show="!loading.status">

      <div class="title-section__wrapper">
        <h2>Ki bon plan?</h2>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>Ki bon plan?</li>
        </ul>
      </div>
      <!-- Breadcrumb -->

      <div class="generic-layout listing">
        <div class="section-container">
          <!-- <div class="section-title-wrapper">
            <h2>Evenements clé</h2>
          </div> -->
          <div class="section-description-wrapper">
            <!-- Events listing -->
            <EventListing :featured="true"></EventListing>
            <!-- Events listing -->
          </div>
        </div>
      </div>

      <div class="generic-layout listing">
        <div class="section-container">
          <div class="section-title-wrapper">
            <h2>Autres événements</h2>
          </div>
          <div class="section-description-wrapper">
            <EventListing :featured="false"></EventListing>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventListing from "@/components/events/EventListing.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  name: "Evenements",
  computed: {
    ...mapGetters({
      loading: "loading",
      events: "events/getEvents"
    })
  },
  components: {
    EventListing,
    MoonLoader
  },
  metaInfo() {
    return {
      title: "Ki bon plan?",
      titleTemplate: "%s | LexpressCulture"
    };
  },
  methods: {},
  beforeMount() {
    this.$store.dispatch("events/load");
  }
};
</script>

<style lang="scss">
.events-view {
  margin-bottom: 100px !important;
}

[data-deck="main-events-listing"] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gutter / 2;

  .single-content-card {
    margin-bottom: $gutter/2;
    width: 100%;

    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: $color-white;

    &:hover {
      box-shadow: 0 7px 15px rgba($color-black, 0.4);
    }

    .card-thumbnail {
      background: $color-main;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      img {
        //filter: blur(10px);
        width: 120%;
        left: -10px;
        top: -10px;
        position: relative;
      }
    }

    .card-description {
      display: grid;
      padding: $gutter/3;
      z-index: 10;
      grid-template-columns: calc(9 / 12 * 100%) calc(3 / 12 * 100%);
      grid-column-gap: $gutter/4;

      .title-wrapper {
        a,
        h2 {
          text-decoration: none;
          display: inline-block;
          margin: 0;
          margin-bottom: $gutter/3;
          text-align: left;
          color: $color-white;
          font-weight: 500;
          font-size: 24px;
          text-shadow: 0 0 10px rgba(109, 109, 109, 0.9);
        }

        .event-details-wrapper {
          margin-bottom: $gutter/5;
          color: $color-white;
          font-size: 14px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .description-wrapper {
        color: $color-white;
        text-align: center;
        font-weight: 500;

        .digit-wrapper {
          font-size: 50px;
          letter-spacing: -1px;
          width: 100%;
          text-shadow: 0 0 10px rgba(109, 109, 109, 0.9);
        }
        .month-wrapper {
          font-size: 16px;
          text-align: center;
          width: 100%;
          text-shadow: 0 0 10px rgba(109, 109, 109, 0.9);
        }
      }
    }
  }

  @media (max-width: $desktop) {
    .single-content-card {
      width: 100%;
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);

    .single-content-card {
      margin-bottom: $gutter/4;
    }
  }

  @media (max-width: $tablet-portrait) {
    grid-template-columns: 1fr;

    .single-content-card {
      width: 100%;
    }
  }
}

[data-deck="featured-events-listing"] {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gutter / 2;

  .single-content-card {
    min-height: 200px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: $color-white;
    display: grid;

    &:hover {
      box-shadow: 0 7px 15px rgba($color-black, 0.4);
    }

    .card-thumbnail {
      background: $color-main;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to top,
          rgba($color-black, 0.9),
          transparent 75%
        );
        transition: all 0.3s $ease-in-out-quad;
        z-index: map_get($z-index, top);
      }

      img {
        //filter: blur(4px);
        width: 120%;
        left: -10px;
        top: -10px;
        position: relative;
      }
    }

    .card-description {
      width: 100%;
      padding: $gutter/3;
      z-index: 10;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 70px;

      .title-wrapper {
        align-self: end;

        a,
        h2 {
          text-align: left;
          margin: 0;
          color: $color-white;
          font-weight: 600;
          font-size: 24px;
          text-decoration: none;
          display: inline-block;
          margin-bottom: $gutter/3;
        }

        .event-details-wrapper {
          width: 100%;
          margin-bottom: $gutter/5;
          color: $color-white;
          font-size: 13px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .description-wrapper {
        color: $color-white;
        text-align: center;
        font-weight: 500;

        .digit-wrapper {
          font-size: 50px;
          letter-spacing: -1px;
          width: 100%;
        }
        .month-wrapper {
          font-size: 16px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);

    .single-content-card {
      min-height: 150px;
    }
  }

  @media (max-width: $tablet-portrait) {
    grid-template-columns: 1fr;

    .single-content-card {
      width: 100%;
    }
  }

  @media (max-width: $mobile) {
    .single-content-card {
      width: 100%;
    }
  }
}
</style>
