<template>
  <router-link :to="{ path : 'recette/' + recipe.getSlug() }">
    <div class="recipe-block__wrapper" :class="{ 'small': isListing }">
      <div class="image__wrapper">
        <img :src="recipe.getImage() + ':thumb'" alt>
      </div>
      <div class="content-overlay__wrapper">
        <div class="upper__wrapper">
          <div class="title">
            <h2>{{trimText(recipe.getName())}}</h2>
          </div>
          <div class="content-details__wrapper">
            <div class="level" v-if="recipe.getDifficulty()">
              <h4>Niveau</h4>
              <div class="value">{{recipe.getDifficulty()}}</div>
            </div>
            <!-- <div class="preparation" v-if="recipe.getDuration()">
              <h4>Temps de préparation</h4>
              <div class="value">{{recipe.getDuration()}} minutes</div>
            </div>-->
            <div class="calories" v-if="recipe.getServings()">
              <h4>Portion(s)</h4>
              <div class="value">{{recipe.getServings()}}</div>
            </div>
          </div>
        </div>
        <div class="button__wrapper" :class="{ 'small': isListing }">
          <button>Comment faire? →</button>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "RecipeComponent",
  props: {
    recipe: {
      type: Object,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    trimText(val) {
      return val.length <= 40 ? val : val.substring(0, 40) + "...";
    },
  },
};
</script>
<style lang="scss" scoped>
.recipe-box {
}
.recipe-block__wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 160%;
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0) 75%
    );
    z-index: 10;
  }

  .image__wrapper {
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      margin: 0 auto;
      object-fit: cover;
      object-position: center center;
    }
  }

  .content-overlay__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    z-index: 12;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
    width: 100%;

    .upper__wrapper {
      padding: $gutter / 2;
    }

    .title {
      margin-bottom: $gutter/2;

      h2 {
        color: white;
        text-decoration: none;
        font-family: $font-noto-serif;
        font-size: 35px;
        font-weight: 700;
        margin: 0;
      }
    }

    .content-details__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 12px;
      align-self: flex-start;

      .level,
      .preparation,
      .calories {
        width: 100%;
        margin-bottom: $gutter / 2;
        opacity: 0;
        transition: all 0.2s ease-out;
        margin-left: -15px;

        h4 {
          text-transform: uppercase;
          margin: 0;
          margin-bottom: $gutter/8;
        }

        .value {
          font-family: $font-noto-serif;
          font-weight: 400;
          font-size: 15px;
        }
      }

      .calories {
        margin-right: 0;
      }
    }

    &:hover {
      .level,
      .preparation,
      .calories {
        opacity: 1;
        transition: all 0.2s ease-in;
        margin-left: 0px;
      }

      .level {
        transition-delay: 0s;
      }
      .preparation {
        transition-delay: 0.1s;
      }
      .calories {
        transition-delay: 0.2s;
      }

      .button__wrapper {
        button {
          box-shadow: 0 0 27px rgba(0, 0, 0, 0.5);
          transform: translateY(-5px);
        }
      }
    }

    .button__wrapper {
      display: flex;
      justify-content: center;
      //padding: 0 $gutter / 2 $gutter / 2 $gutter / 2;
      position: absolute;
      bottom: 15px;
      width: 90%;
      left: 0;
      right: 0;
      margin: auto;

      &.small {
        button {
          width: 95%;
          height: 45px;
        }
      }

      button {
        background: white;
        border-radius: 40px;
        border: 0;
        height: 50px;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        text-decoration: none;
        font-family: $font-noto-serif;
        font-weight: 400;
        font-size: 16px;
        text-shadow: none;
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.4);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media (max-width: $desktop) {
  .recipe-block__wrapper {
    .content-overlay__wrapper {
      .title {
        h2 {
          font-size: 35px;
        }
      }
      .button__wrapper {
        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .recipe-block__wrapper {
    .content-overlay__wrapper {
      .upper__wrapper {
        padding: $gutter / 3;
      }

      .title {
        h2 {
          font-size: 30px;
        }
      }

      .content-details__wrapper {
        .level,
        .preparation,
        .calories {
          opacity: 1;
          margin-left: 0px;
        }
      }

      .button__wrapper {
        //padding: 0 $gutter/3 $gutter/3 $gutter/3;
        button {
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .recipe-block__wrapper {
    .content-overlay__wrapper {
      .title {
        h2 {
          font-size: 40px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .recipe-block__wrapper {
    padding-top: 100%;

    .content-overlay__wrapper {
      .title {
        h2 {
          font-size: 30px;
        }
      }

      .content-details__wrapper {
        .level,
        .preparation,
        .calories {
          margin-right: $gutter / 4;
          margin-bottom: $gutter / 4;

          .value {
            font-size: 14px;
          }
        }
      }

      .button__wrapper {
        button {
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .recipe-block__wrapper {
    .content-overlay__wrapper {
      .title {
        h2 {
          font-size: 30px;
        }
      }

      .content-details__wrapper {
        .level,
        .preparation,
        .calories {
          margin-right: $gutter / 4;
          margin-bottom: $gutter / 4;

          .value {
            font-size: 17px;
          }
        }
      }

      .button__wrapper {
        button {
          font-size: 17px;
        }
      }
    }
  }
}
</style>