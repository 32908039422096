<template>
  <div class="featured__slider-container" v-touch:swipe.right="movePrev"
    v-touch:swipe.left="moveNext" v-if="getBanners.length">
    <div class="navigations">
      <div class="previous-button">
        <button @click="overrideAutoplay(); movePrev()">
          <span class="arrow"></span>
        </button>
      </div>
      <div class="next-button">
        <button @click="overrideAutoplay(); moveNext()">
          <span class="arrow"></span>
        </button>
      </div>
    </div>
    <transition :name="slideAnimation" mode="out-in">
      <div class="featured__slider-item" :data-type="banner.type" :key="index"
        v-for="(banner, index) in getBanners" v-if="index === currentSlide">
        <div class="image-wrapper">
          <img v-if="banner.type === 'Movies'" :src="banner.data.getImage() + ':thumb'"
            :alt="banner.data.getTitle()" :title="banner.data.getTitle()">
          <img v-if="banner.type === 'Events'" :src="banner.data.getImage() + ':banner'"
            :alt="banner.data.getName()" :title="banner.data.getName()">
          <img v-if="banner.type === 'Recipes'" :src="banner.data.getImage() + ':banner'"
            :alt="banner.data.getName()" :title="banner.data.getName()">
        </div>
        <div class="description-wrapper">
          <div class="text">
            <template v-if="banner.type === 'Movies'">Cinéma</template>
            <template v-if="banner.type === 'Events'">Ki bon plan?</template>
            <template v-if="banner.type === 'Recipes'">Bon appetit!</template>
          </div>


          <router-link v-if="banner.type == 'Movies'" :to="'/cinema/' + banner.data.getSlug()" class="title">
            <h2 v-if="banner.type === 'Movies'">{{banner.data.getTitle()}}</h2>
          </router-link>

          <router-link v-if="banner.type == 'Events'" :to="'/evenement/' + banner.data.getSlug()" class="title">
            <h2 v-if="banner.type === 'Events'">{{banner.data.getName()}}</h2>
          </router-link>

          <router-link v-if="banner.type == 'Recipes'" :to="'/recette/' + banner.data.getSlug()" class="title">
            <h2 v-if="banner.type === 'Recipes'">{{banner.data.getName()}}</h2>
          </router-link>

          
          <p v-if="banner.type === 'Movies' || banner.type === 'Events'" v-html="trimText(banner.data.getSummary())"></p>
          <p v-if="banner.type === 'Recipes'" v-html="trimText(banner.data.getNotes())"></p>
          <div class="button-wrapper">
            <router-link v-if="banner.type == 'Movies'" :to="'/cinema/' + banner.data.getSlug()">Plus
              d'info</router-link>
            <router-link v-if="banner.type == 'Events'" :to="'/evenement/' + banner.data.getSlug()">Plus
              d'info</router-link>
            <router-link v-if="banner.type == 'Recipes'" :to="'/recette/' + banner.data.getSlug()">Plus
              d'info</router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentSlide: 0,
      slideAnimation: "fade-prev",
      overrideAutoplayStatus: false
    };
  },
  computed: {
    ...mapGetters({
      getBanners: "homepage/getBanners"
    }),
    slides() {
      return this.getBanners.length;
    }
  },
  methods: {
    movePrev() {
      this.slideAnimation = "fade-prev";

      if (this.currentSlide === 0) {
        this.currentSlide = this.slides - 1;
      } else {
        this.currentSlide = this.currentSlide - 1;
      }
    },
    moveNext() {
      this.slideAnimation = "fade-next";

      if (this.currentSlide === this.slides - 1) {
        this.currentSlide = 0;
      } else {
        this.currentSlide = this.currentSlide + 1;
      }
    },
    autoplay() {
      setInterval(
        function() {
          if (this.overrideAutoplayStatus === false) {
            this.moveNext();
          }
        }.bind(this),
        3000
      );
    },
    overrideAutoplay() {
      this.overrideAutoplayStatus = true;
    },
    trimText(val) {
      if (val && val.length > 200) {
        return val.substring(0, 170) + " ...";
      } else {
        return val;
      }
    }
  },
  beforeMount() {},
  mounted() {
    this.autoplay();
  }
};
</script>
<style lang="scss" scoped>
.fade-prev-enter-active,
.fade-prev-leave-active,
.fade-next-enter-active,
.fade-next-leave-active {
  transition: all 0.3s;
}

.fade-prev-enter,
.fade-prev-leave-active,
.fade-next-enter,
.fade-next-leave-active {
  opacity: 0;
}

.fade-prev-enter {
  transform: translateX(-31px);
}

.fade-prev-leave-active {
  transform: translateX(31px);
}

.fade-next-enter {
  transform: translateX(31px);
}

.fade-next-leave-active {
  transform: translateX(-31px);
}

.featured__slider-container {
  --slider-height: 600px;
  --navigations-height: var(--slider-height);
  --navigations-button-width: 100px;
  --navigations-button-arrow-height: 50px;

  width: 100%;
  overflow: hidden;
  height: var(--slider-height);
  position: relative;
  margin: 0;

  .navigations {
    width: 100%;

    .previous-button {
      left: 0;
    }

    .next-button {
      right: 0;
    }

    .next-button,
    .previous-button {
      height: var(--navigations-height);
      position: absolute;
      height: var(--navigations-height);
      margin: auto 0;
      top: 50%;
      bottom: 50%;

      &:hover {
        cursor: pointer;

        button {
          .arrow {
            opacity: 1;
          }
        }
      }

      button {
        background: none;
        border: 0;
        height: var(--navigations-height);
        width: var(--navigations-button-width);

        .arrow {
          content: "";
          background: url("../../assets/images/chevron.svg");
          height: var(--navigations-button-arrow-height);
          background-repeat: no-repeat;
          width: var(--navigations-button-width);
          position: absolute;
          left: 0;
          margin: auto 0;
          top: 50%;
          bottom: 50%;
          background-position: center center;
          opacity: 0.3;
          transition: all 0.3s ease-in-out;
        }

        &:focus {
          outline: none;
        }

        i {
          font-size: 40px;
          color: #cccccc;
        }
      }
    }

    .next-button {
      button {
        .arrow {
          transform: scaleX(-1);
        }
      }
    }
  }

  .featured__slider-item {
    height: var(--slider-height);
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    grid-column-gap: $gutter;
    align-items: center;

    &[data-type="Recipes"] {
      .image-wrapper {
        img {
          height: var(--slider-height);
          max-height: var(--slider-height);
          width: 100%;
          display: block;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }

    &[data-type="Events"] {
      .image-wrapper {
        img {
          height: var(--slider-height);
          max-height: var(--slider-height);
          width: 100%;
          display: block;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }

    .image-wrapper {
      display: flex;
      justify-content: flex-end;

      img {
        height: 100%;
        max-height: calc(var(--slider-height) * 0.8);
      }
    }
    .description-wrapper {
      .text {
        text-transform: uppercase;
        font-size: 18px;
      }

      a {
        &.title {
          color: black;
          text-decoration: none;
        }
      }

      h2 {
        font-size: 50px;
        max-width: 90%;
        margin: 0;
      }
      p {
        max-width: 90%;
        font-size: 17px;
        line-height: 25px;
        margin-top: 10px;
        margin-bottom: $gutter;
      }
    }
    .button-wrapper {
      a {
        display: inline-block;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid $color-main;
        padding: 10px 70px;
      }
    }
  }
}

@media (max-width: $desktop) {
  .featured__slider-container {
    --slider-height: 500px;

    .featured__slider-item {
      .description-wrapper {
        h2 {
          font-size: 44px;
        }
        p {
          font-size: 16px;
        }
      }
      .button-wrapper {
        a {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .featured__slider-container {
    --slider-height: 500px;

    .featured__slider-item {
      .description-wrapper {
        h2 {
          font-size: 38px;
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .featured__slider-container {
    --slider-height: auto;
    --navigations-height: 100%;
    //margin: $gutter 0;

    .featured__slider-item {
      grid-template-columns: 1fr;
      padding-bottom: 5px;

      &[data-type="Movies"] {
        //padding-top: $gutter/1.5;

        .image-wrapper {
          img {
            padding-top: 30px;
            max-width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
          }
        }
      }

      &[data-type="Events"] {
        //padding-top: $gutter/1.5;

        .image-wrapper {
          img {
            max-width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
          }
        }
      }

      .image-wrapper {
        justify-content: center;

        img {
          height: 100% !important;
          min-height: 300px !important;
          max-height: 300px !important;
          //width: 100%;
        }
      }

      .description-wrapper {
        padding: 20px 10px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        h2 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }

        p {
          margin: 0 auto;
          margin-bottom: $gutter/2;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .featured__slider-container {
    --slider-height: auto;
    --navigations-height: 100%;
    --navigations-button-width: 50px;
    --navigations-button-arrow-height: 30px;

    .featured__slider-item {
      .image-wrapper {
        img {
          max-height: 300px;
        }
      }

      .description-wrapper {
        .text {
          font-size: 13px;
          margin-bottom: $gutter/4;
        }
        h2 {
          font-size: 30px;
          margin-bottom: 15px;
        }
        p {
          font-size: 15px;
          line-height: 23px;
          display: none;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .featured__slider-container {
    .featured__slider-item {
      .description-wrapper {
        h2 {
          font-size: 25px;
        }

        .button-wrapper {
          a {
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>
