<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'"
      :size="'40px'"></moon-loader>
    <div class="container-fw" v-show="!loading.status">
      <div class="title-section__wrapper">
        <h2>Coups de coeur</h2>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>Coups de coeur</li>
        </ul>
      </div>
      <!-- Breadcrumb -->

      <div class="reviews__container">
        <template v-for="review in getReviews">
          <ReviewComponent :review="review" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewComponent from "@/components/reviews/ReviewComponent";
import { mapGetters } from "vuex";

export default {
  components: {
    ReviewComponent
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      getReviews: "reviews/getReviews"
    })
  },
  metaInfo() {
    return {
      title: this.loading.state ? "Loading..." : "Coups de coeur",
      titleTemplate: "%s | LexpressCulture"
    };
  },
  beforeMount() {
    this.$store.dispatch("reviews/load");
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.reviews__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gutter/2;
  margin-bottom: $gutter;
}

@media (max-width: $tablet-portrait) {
  .reviews__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $mobile) {
  .reviews__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
