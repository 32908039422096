<template>
  <div>
    <div class="title-section__wrapper light__version" id="results__container">
      <h2>Résultats</h2>
    </div>

    <div class="results__container" v-if="getVenues.length > 0">
      <div class="result__card" v-for="item in getVenues">
        <div class="image">
          <router-link :to="{ path: '/venue/' + item.getSlug()}">
            <img v-if="item.getImage()" :src="item.getImage() + ':thumb'" alt="">
            <img v-else src="@/assets/images/eventplaceholder.svg" alt="">
          </router-link>
        </div>
        <div class="description">
          <h3>
            <router-link to="/">
              {{ item.getName() }}
            </router-link>
          </h3>
        </div>
      </div>
    </div>

    <div class="no-results__container" v-else>
      <h2>Aucun résultats basé sur les critères</h2>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters({
        loading: "loading",
        getVenues: "venues/getVenues"
      })
    },
  }
</script>

<style lang="scss" scoped>
  .results__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $gutter/2;
    margin-top: $gutter/2;
    margin-bottom: $gutter*3;
    transition: all 0.3s ease-in-out;

    .result__card {
      overflow: hidden;
      position: relative;
      color: var(--color-white);

      .image {
        margin-bottom: $gutter/5;

        svg, img {
          width: 100%;
          max-height: 160px;
          object-position: 50% 50%;
          object-fit: cover;
          border-radius: 2px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          transition: all 0.3s ease-in-out;
        }
      }

      .description {
        h3 {
          &, a {
            margin: 0;
            width: 100%;
            font-size: 18px;
            color: black;
            text-decoration: none;
          }
        }
      }

      .venue__type {
        position: absolute;
        background: white;
        width: 40px;
        height: 40px;
        top: 5px;
        right: 5px;
        text-align: right;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        border-radius: 3px;

        i {
          text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          color: $color-main;
        }
      }

      &:hover {
        filter: brightness(110%);
      }
    }
  }

  .no-results__container {
    margin-bottom: 100px;

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #cccccc;
      font-size: 45px;
      margin-top: 50px;
      text-align: center;
    }
  }

  @media (max-width: $tablet-portrait) {
    .results__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .no-results__container {
      h2 {
        font-size: 20px;
      }
    }
  }

  @media (max-width: $mobile) {
    .results__container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>