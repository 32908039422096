import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import { sync } from "vuex-router-sync";
import Meta from "vue-meta";
import Vue2TouchEvents from "vue2-touch-events";
import VueDisqus from "vue-disqus";
import VueObserveVisibility from "vue-observe-visibility";
import VueAnalytics from "vue-analytics";
import * as VueGoogleMaps from "vue2-google-maps";
import { checkCacheVersion } from "@/util/cache-manager.js";

let VueScrollTo = require("vue-scrollto");

import "./registerServiceWorker";

sync(store, router);
Vue.use(Meta);
Vue.use(Vue2TouchEvents);
Vue.use(VueDisqus);
Vue.use(VueObserveVisibility);
Vue.use(VueScrollTo);
Vue.use(VueAnalytics, {
  id: "UA-1662399-15",
  router
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAxib5u8Np5GMeWYrihqOW0MCYUhtoOr2Q",
    libraries: "places" // This is required if you use the Autocomplete plugin
  }
});

Vue.config.productionTip = false;

checkCacheVersion().then(_ => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
