import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  banners: {
    items: [],
    events: [],
    movies: [],
    recipes: []
  },
  ads: [],
  movies: [],
  events: [],
  recipes: [],
  fetched: false
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations
};
