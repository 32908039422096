import { EmptyRequest } from "@/services/service_app_pb";
import { HomepageResponse } from "../../services/service_app_pb";
import { grpcFetch } from "@/util/cache-manager.js";

function load({ state, commit }) {
  let emptyRequest = new EmptyRequest();
  const cacheName = "homepage";
  const methodName = "getHomepage";

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Looking up homepage",
    },
    { root: true },
  );
  /**** Loader ****/

  grpcFetch(methodName, emptyRequest, cacheName, HomepageResponse, commit).then(
    (res) => {
      // Banners
      let bannerItems = res.getBanners().getItemsList();
      let bannerEvents = res.getBanners().getEventsList();
      let bannerMovies = res.getBanners().getMoviesList();
      let bannerRecipes = res.getBanners().getRecipesList();

      // other homepage components
      let ads = res.getAdsList();
      let events = res.getEventsList();
      let movies = res.getMoviesList();
      let recipes = res.getRecipesList();

      // set data
      commit("setBannerItems", { items: bannerItems });
      commit("setBannerEvents", { events: bannerEvents });
      commit("setBannerMovies", { movies: bannerMovies });
      commit("setBannerRecipes", { recipes: bannerRecipes });
      commit("setAds", { ads: ads });
      commit("setMovies", { movies: movies });
      commit("setEvents", { events: events });
      commit("setRecipes", { recipes: recipes });

      /**** Loader ****/
      commit(
        "SET_STATUS",
        {
          status: false,
          message: "Lookup of homepage done",
        },
        { root: true },
      );
      /**** Loader ****/
    },
  );
}

export default {
  load,
};
