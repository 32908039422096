<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'" :size="'40px'"></moon-loader>
    <div class="recipe" v-if="!loading.status && recipe">
      <div class="recipe__upper-container">
        <div class="recipe__poster" v-if="recipe.getImage()">
          <img :src="recipe.getImage() + ':full'" alt>
        </div>
        <div class="recipe__basic-details">
          <div class="basic-details__title">
            <h1>{{recipe.getName()}}</h1>
          </div>
          <div class="basic-details__content">
            <div class="title__underlined">DÉTAILS</div>
            <div class="listing">
              <div class="detail__cell" v-if="recipe.getCuisine()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/cuisine.svg" alt>
                </div>
                <div class="data">{{recipe.getCuisine()}}</div>
              </div>
              <div class="detail__cell" v-if="recipe.getDiet()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/food_type.svg" alt>
                </div>
                <div class="data">{{recipe.getDiet()}}</div>
              </div>
              <div class="detail__cell" v-if="recipe.getCookingmethod()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/utensils.svg" alt>
                </div>
                <div class="data">{{recipe.getCookingmethod()}}</div>
              </div>
              <div class="detail__cell" v-if="recipe.getCategory()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/veg-or-non.svg" alt>
                </div>
                <div class="data">{{recipe.getCategory()}}</div>
              </div>
              <div class="detail__cell" v-if="recipe.getServings()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/servings.svg" alt>
                </div>
                <div class="data">{{recipe.getServings()}} portion(s)</div>
              </div>
              <div
                class="detail__cell"
                v-if="(recipe.getSpicelevel() && recipe.getSpicelevel() !== null && recipe.getSpicelevel().trim() !== 'none')"
              >
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/spicy-level.svg" alt>
                </div>
                <div class="data">
                  <template v-if="recipe.getSpicelevel() === 'mild'">Doux</template>
                  <template v-else-if="recipe.getSpicelevel() === 'moderate'">Moyen</template>
                  <template v-else-if="recipe.getSpicelevel() === 'hot'">épicé</template>
                </div>
              </div>
              <div class="detail__cell" v-if="recipe.getPreparationtime()">
                <div class="icon">
                  <img src="@/assets/images/icons/recipe/preparationtime.svg" alt>
                </div>
                <div class="data">{{recipe.getPreparationtime()}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recipe__lower-container">
        <div class="container-fw">
          <div class="container">
            <div class="lower-container__left-wrapper">
              <div class="title__underlined" v-if="recipe.getNotes()">Infos</div>
              <div class="intro__container">
                <p v-html="recipe.getNotes()" v-if="recipe.getNotes()"></p>
              </div>
              <div class="ingredients__container" v-if="recipe.getIngredients()">
                <div class="title__underlined" v-if="recipe.getIngredients()">Ingrédients</div>
                <div class="content">
                  <div
                    class="ingredients__list"
                    v-if="recipe.getIngredients()"
                    v-html="recipe.getIngredients()"
                  ></div>
                </div>
              </div>
              <div
                class="instructions__container"
                v-if="recipe.getRecipeinstructionsList().length > 0"
              >
                <div class="title__underlined">Préparation</div>
                <div
                  class="content"
                  v-html
                  v-if="recipe.getRecipeinstructionsList() && recipe.getRecipeinstructionsList().length > 0"
                >
                  <p
                    :key="index"
                    v-for="(instruction, index) in sortPreparation(recipe.getRecipeinstructionsList())"
                    v-html="instruction.getDescription()"
                  ></p>
                </div>
              </div>
            </div>
            <div class="lower-container__right-wrapper">
              <div class="recipe__video" v-if="recipe.getVideo()">
                <div class="title__underlined" v-if="recipe.getVideo()">Vidéo de préparation</div>
                <div class="video__wrapper" v-if="recipe.getVideo()">
                  <iframe
                    width="560"
                    height="315"
                    :src="'https://youtube.com/embed/' + getYoutubeId(recipe.getVideo())"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div
                class="sponsored__area"
                v-if="recipe.getSponsorsList().length > 0 && (recipe.getSponsorsList()[0] !== null || typeof recipe.getSponsorsList()[0] !== 'undefined')"
              >
                <div class="sponsored__text">
                  <router-link to="/sponsored">
                    <div class="icon">i</div>
                    <div class="small-text">Sponsorisé par</div>
                  </router-link>
                </div>
                <div class="sponsored__content">
                  <a
                    target="_blank"
                    v-if="recipe.getSponsorsList().length > 0  && recipe.getSponsorsList()[0].getWebsite()"
                    :href="recipe.getSponsorsList()[0].getWebsite()"
                  >
                    <img :src="recipe.getSponsorsList()[0].getImage() + ':thumb'" alt>
                  </a>
                </div>
              </div>
              <div
                class="products-used__list"
                v-if="recipe.getProductsList() && recipe.getProductsList().length > 0"
              >
                <div class="title__underlined">Produits utilisés</div>
                <div class="content">
                  <div
                    class="product__block"
                    v-if="recipe.getProductsList()"
                    v-for="(product, index) in recipe.getProductsList()"
                    :index="index"
                  >
                    <div class="image" v-if="product">
                      <a
                        v-if="product.getWebsite() && product.getWebsite() !== null"
                        :href="product.getWebsite()"
                        target="_blank"
                      >
                        <img :src="product.getImage() + ':thumb'" alt>
                      </a>
                    </div>
                    <div class="details">
                      <div class="title" v-if="product.getName()">{{product.getName()}}</div>
                      <a
                        class="more-info"
                        v-if="product.getWebsite() && product.getWebsite() !== null"
                        :href="product.getWebsite()"
                        target="_blank"
                      >
                        En savoir plus
                        <i class="material-icons">open_in_new</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  components: {
    MoonLoader,
  },
  data() {
    return {
      recipeInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      recipe: "recipes/getRecipe",
    }),
  },
  beforeMount() {
    this.$store.dispatch("recipes/getSingleRecipeById");
  },
  metaInfo() {
    return {
      title: this.loading.state
        ? "Loading..."
        : this.recipeInfo
        ? this.recipeInfo.getName()
        : "",
      titleTemplate: "%s | LexpressCulture",
    };
  },
  methods: {
    sortPreparation(array) {
      return _.sortBy(array, (instruction) => {
        return instruction.getStep();
      });
    },
    getYoutubeId(url) {
      let ID = "";
      url = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

      if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_\-]/i);
        ID = ID[0];
      } else {
        ID = url;
      }

      return ID;
    },
  },
  watch: {
    recipe(val) {
      this.recipeInfo = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.recipe {
  .recipe__upper-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $gutter;
    margin-bottom: $gutter;
    --upper-container-height: 550px;
    background: #f5f7fa;

    .recipe__poster {
      img {
        height: var(--upper-container-height);
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
      }
    }
    .recipe__basic-details {
      .basic-details__title {
        max-width: 80%;
        margin-bottom: $gutter;

        h1 {
          font-family: $font-noto-serif;
          font-weight: 700;
          font-size: 40px;
          color: $color-event-bleu;
          margin-top: 0;
        }
      }
    }

    .listing {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $gutter/2;
      grid-row-gap: $gutter/4;
      width: 60%;
      margin-bottom: 20px;

      .detail__cell {
        display: flex;
        align-items: center;

        .icon {
          margin-right: $gutter/2;

          img {
            width: 50px;
          }
        }
      }
    }
  }

  .recipe__lower-container {
    .container {
      width: 100%;
      display: grid;
      grid-column-gap: $gutter;
      grid-template-columns: 1fr 1fr;

      .lower-container__left-wrapper {
        .intro__container {
          p {
            font-family: $font-noto-serif;
            font-weight: 400;
            font-style: italic;
            font-size: 19px;
            line-height: 35px;
            color: #484848;
            margin-top: 0;
          }
        }
        .ingredients__container {
          .content {
            .ingredients__list {
              margin: 0;
              padding: 0;
              list-style-type: none;
              margin-bottom: $gutter;

              span,
              p {
                &,
                p {
                  font-family: $font-roboto !important;
                  font-size: 16px !important;
                }
              }

              li {
                padding-left: $gutter / 2;
                position: relative;
                margin-bottom: $gutter / 3;

                &:before {
                  content: "-";
                  position: absolute;
                  left: 0;
                  top: 0px;
                }
              }
            }
          }
        }
        .instructions__container {
          margin-bottom: 40px;
          .content {
            line-height: 20px;
          }
        }
      }
      .lower-container__right-wrapper {
        .recipe__video {
          .video__wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
            margin-bottom: 30px;

            iframe {
              min-height: auto;
              max-height: auto;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        .sponsored__area {
          background: #e5e5e5;
          padding: $gutter/3;
          margin-bottom: $gutter;

          .sponsored__text {
            opacity: 0.6;
            margin-bottom: $gutter/2;
            padding-bottom: $gutter/4;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);

            a {
              display: flex;
              text-decoration: none;
              align-items: center;
              line-height: 0;

              .icon {
                height: 20px;
                width: 20px;
                background: black;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-weight: 500;
                font-size: 13px;
                margin-right: $gutter/4;
                line-height: 0;
              }

              .small-text {
                font-size: 13px;
                text-transform: uppercase;
                color: black;
              }
            }
          }
          .sponsored__content {
            a {
              display: block;
              text-align: center;
              img {
                width: 100%;
                max-width: 200px;
              }
            }
          }
        }
        .products-used__list {
          .content {
            .product__block {
              display: grid;
              grid-template-columns: 110px 1fr;
              grid-column-gap: $gutter / 2;
              align-items: center;
              margin-bottom: $gutter / 2;
              padding-bottom: $gutter / 2;
              border-bottom: 1px solid #c4c4c4;

              &:last-child {
                border-bottom: 0;
              }

              .image {
                a {
                  img {
                    width: 90%;
                  }
                }
              }

              .details {
                .title {
                  font-weight: 700;
                  font-size: 17px;
                }
                .provider {
                  text-transform: uppercase;
                  color: #777777;
                  font-weight: 700;
                  font-size: 14px;
                  margin-bottom: $gutter / 3;
                }
                .more-info {
                  text-transform: uppercase;
                  text-decoration: none;
                  color: #777777;
                  border-bottom: 1px solid #777777;
                  padding-bottom: $gutter / 8;
                  font-size: 13px;

                  i {
                    position: relative;
                    top: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .title__underlined {
    font-weight: 700;
    display: inline-block;
    margin-bottom: $gutter/2;
    text-transform: uppercase;
    color: black;
    font-size: 18px;
    padding-bottom: $gutter/8;
    border-bottom: 3px solid #cccccc;
  }
}

@media (max-width: $tablet) {
  .recipe {
    .recipe__upper-container {
      grid-template-columns: 1fr;
      --upper-container-height: 400px;

      .recipe__basic-details {
        padding: 10px;

        .basic-details__title {
          max-width: 100%;
          text-align: center;

          h1 {
            font-size: 40px;
          }
        }

        .basic-details__content {
          text-align: center;

          .listing {
            width: 80%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-portrait) {
  .recipe {
    .recipe__upper-container {
      --upper-container-height: 300px;
      //margin-bottom: 0;

      .recipe__basic-details {
        .basic-details__title {
          h1 {
            font-size: 30px;
          }
        }

        .basic-details__content {
          .listing {
            width: 90%;

            .detail__cell {
              .icon {
                img {
                  width: 42px;
                }
              }
              .data {
                font-size: 14px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .recipe__lower-container {
      .container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);

        .lower-container__left-wrapper {
          grid-row: 2 / 3;
        }
        .lower-container__right-wrapper {
          grid-row: 1 / 2;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .recipe {
    .recipe__upper-container {
      --upper-container-height: 200px;
      //margin-bottom: 0;

      .recipe__basic-details {
        .basic-details__title {
          margin-bottom: $gutter / 2;
          h1 {
            font-size: 25px;
          }
        }

        .basic-details__content {
          .listing {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            .detail__cell {
              .icon {
                img {
                  width: 35px;
                }
              }
            }
          }
        }
      }
    }

    .recipe__lower-container {
      .container {
        .lower-container__left-wrapper {
          .intro__container {
            p {
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .recipe {
    .recipe__upper-container {
      .recipe__basic-details {
        .basic-details__content {
          .listing {
            .detail__cell {
              .icon {
                img {
                  width: 30px;
                }
              }
              .data {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .recipe__lower-container {
      .container {
        .lower-container__left-wrapper {
          .intro__container {
            p {
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}

.content {
  p {
    margin: 0;
    padding: 10px 0;
  }
}

.ingredients__list {
  p {
    margin: 0;
    padding: 5px 0;
  }
}
</style>
