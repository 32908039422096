<template>
  <div id="app" :class>
    <div class="app-container">
      <SiteHeader/>

      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"/>
      </transition>
    </div>
    <footer>
      <div class="container-fw">
        <div class="links-wrapper">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.lexpress.mu/page/regles-communautaires"
              >Règles communautaires</a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.lexpress.mu/conditions-generales"
              >Conditions d’utilisation</a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.lexpress.mu/code-de-d%C3%A9ontologie"
              >Code de déontologie</a>
            </li>
            <li>
              <a target="_blank" href="https://www.lexpress.mu/contact">Contactez-nous</a>
            </li>
          </ul>
        </div>
        <div class="copyright-wrapper">
          <p>&copy; 1963 - 2019 La Sentinelle Ltd.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MainHeader from "@/components/header/MainHeader";
import SiteHeader from "@/components/shared/SiteHeader";

import JumboMenu from "@/components/header/JumboMenu";
import { mapGetters } from "vuex";
import "normalize-css";

export default {
  name: "Culture",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getMenuStatus: "getMenuStatus",
    }),
  },
  methods: {},
  components: {
    SiteHeader,
    MainHeader,
    JumboMenu,
  },
  metaInfo: {
    titleTemplate: "L'express Culture | Cinéma, Événements, Cuisine",
  },
  watch: {
    getMenuStatus(val) {
      if (val === true) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};
</script>

<style lang="scss">
body {
  height: 100%;

  &.no-scroll {
    overflow: hidden;
  }
}

#app {
  &.no-scroll {
    overflow: hidden;
  }
}

.v-spinner .v-moon1 {
  margin: 5vh auto 0;
}

.no-result {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fade-results-enter-active {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.fade-results-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-results-enter, .fade-results-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fade-no-results-enter-active {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.fade-no-results-leave-active {
  transition: none;
}

.fade-no-results-enter, .fade-no-results-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.reset-button-slide-enter-active,
.reset-button-slide-leave-active {
  transition: all 0.3s;
}

.reset-button-slide-enter,
.reset-button-slide-leave-active {
  opacity: 0;
}

.reset-button-slide-enter {
  transform: translateX(31px);
}

.reset-button-slide-leave-active {
  transform: translateX(31px);
}

html,
body {
  font-family: $font-roboto;
  font-weight: 300;
  height: 100%;
  box-sizing: border-box;
}

a {
  transition: all 0.3s $ease-in-out-quad;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overflow-y: scroll;
}

body,
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-container {
  flex: 1 0 auto;
}

.status-box {
  background: black;
  color: greenyellow;
  position: fixed;
  bottom: 113px;
  left: 0;
  padding: 0 10px;
  font-size: 12px;
  text-transform: uppercase;

  ul {
    list-style: none;
    padding: 0;
    max-height: 30vh;
    overflow-y: auto;
    li {
      list-style: none;
      font-family: Monospace;
      &:before {
        content: "$ ";
      }
    }
  }
}

footer {
  padding: $gutter/1.5;
  background: #2b2b2b;
  width: 100%;
  flex-shrink: 0;

  .links-wrapper {
    margin-bottom: $gutter/3;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin: 0;
        display: inline-block;
        margin-right: $gutter/3;

        a {
          color: rgba($color-white, 0.5);
          text-decoration: none;
          font-size: 12px;
          padding: 0;
          margin: 0;

          &:hover {
            color: $color-white;
          }
        }
      }
    }
  }

  .copyright-wrapper {
    border-top: 1px solid rgba($color-white, 0.2);
    padding-top: $gutter/3;
    position: relative;

    &:before {
      position: absolute;
      top: -1px;
      right: 0;
      content: "";
      width: 100px;
      height: 5px;
      background: linear-gradient(
        90deg,
        #db0000 0,
        #db0000 25%,
        #0057af 0,
        #0057af 50%,
        #d3c900 0,
        #d3c900 74%,
        #007241 0,
        #007241
      );
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 10px;
      color: $color-white;
      text-align: left;
      padding-top: 3px;
      text-transform: uppercase;
    }
  }
}
@media (max-width: $tablet) {
  footer {
    .links-wrapper {
      width: 100%;
      margin-bottom: $gutter/2;
      ul {
        text-align: center;
      }
    }
    .copyright-wrapper {
      width: 100%;
      p {
        text-align: center;
      }
    }
  }
}
@media (max-width: $tablet-portrait) {
  footer {
    padding-bottom: $gutter * 1.5;
    .links-wrapper {
      ul {
        li {
          display: block;
          margin-bottom: $gutter/4;
        }
      }
    }
  }
}
</style>
