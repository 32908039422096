<template>
  <div class="content-container">
    <moon-loader v-if="loading.status" :loading="loading.status" :color="'#004a8f'"
      :size="'40px'"></moon-loader>
    <div class="container-fw" v-show="!loading.status">
      <div class="title-section__wrapper with-reset">
        <h2>Cinéma</h2>
        <div class="reset-button-wrapper">
          <transition name="reset-button-slide" mode="out-in">
            <button v-if="currentTheatre !== null || currentDate !== null"
              @click="setFilter({ 'theatre' : null, 'date' : null })" title="Reset">
              <i class="material-icons">refresh</i>
            </button>
          </transition>
        </div>
      </div>
      <!-- Breadcrumb -->
      <div class="breadcrumb__wrapper">
        <ul>
          <li>
            <router-link to="/" title="Accueil">Accueil</router-link>
          </li>
          <li>Cinéma</li>
        </ul>
      </div>
      <!-- Breadcrumb -->
      <div class="date__picker">
        <DatePicker />
      </div>
      <TheatrePicker />
      <MovieListing :filtered="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import MovieListing from "@/components/movie/MovieListing.vue";
import TheatrePicker from "@/components/shared/TheatrePicker";
import DatePicker from "@/components/shared/DatePicker";

export default {
  components: {
    MoonLoader,
    MovieListing,
    TheatrePicker,
    DatePicker
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      currentTheatre: "movies/getCurrentTheatre",
      currentDate: "movies/getCurrentDate"
    })
  },
  methods: {
    setFilter(message) {
      this.$store.commit({
        type: "movies/setTheatreDate",
        payload: message
      });
    }
  },
  metaInfo() {
    return {
      title: "Cinéma",
      titleTemplate: "%s | LexpressCulture"
    };
  }
};
</script>

<style lang="scss">
.date__picker {
  margin-bottom: 20px;
}
</style>
