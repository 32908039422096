const CACHE = "culture.lexpress.mu";
const CACHE_VERSION = 2;
const EXPIRY_DELAY = 3600000; // 1 hour in milliseconds
// const EXPIRY_DELAY = 60000; // 1 minute in milliseconds

import { AppClient } from "@/services/service_app_pb_service.js";

const checkCacheVersion = function() {
	return new Promise((resolve, reject) => {
		let version = localStorage.getItem("cache_version");

		if (!version) {
			version = 0;
		} else {
			version = parseInt(version);
		}

		if (version < CACHE_VERSION) {
			caches.delete(CACHE).then(res => {
				localStorage.setItem("cache_version", CACHE_VERSION);
				resolve();
			});
		}
		resolve();
	});
};

const loadFromCache = function(name, entityResponseName) {
	return new Promise((resolve, reject) => {
		let timeCached = localStorage.getItem("cache_time_" + name);
		let timeNow = new Date().getTime();

		let expired =
			timeCached === null || timeNow - parseInt(timeCached) > EXPIRY_DELAY;

		if (expired && navigator.onLine) {
			console.log(`💥 Cache expired for ${name}`);
			reject();
			return;
		}
		// proceed to load from Cache API
		caches.open(CACHE).then(cache => {
			cache
				.match(name)
				.then(cache_response => {
					if (cache_response) {
						return cache_response.body;
					}
					throw new Error("Not in cache");
				})
				.then(body => body.getReader().read())
				.then(({ done, value }) => {
					try {
						let res = entityResponseName.deserializeBinary(value);
						resolve(res);
					} catch (err) {
						throw err;
					}
				})
				.catch(err => reject(err));
		});
	});
};

const saveToCache = function(name, data) {
	return new Promise((resolve, reject) => {
		const RequestForCache = new Request(name);
		const ResponseForCache = new Response(data.serializeBinary());

		caches
			.open(CACHE)
			.then(currentCache => {
				currentCache.put(RequestForCache, ResponseForCache);

				localStorage.setItem("cache_time_" + name, new Date().getTime());
			})
			.catch(err => reject(err));

		// resolve(response); WHY?
	});
};

const loadFromNetwork = function(method, request) {
	return new Promise((resolve, reject) => {
		let client = new AppClient("/api");

		console.log(`🔌 Loading ${method} from network`);

		client[method](request, (err, response) => {
			if (err) {
				console.log(err);
				reject(err);
			} else {
				resolve(response);
			}
		});
	});
};

const loadSingleEntityFromStore = function(
	store,
	fieldName,
	fieldValue,
	commit
) {
	return new Promise((resolve, reject) => {
		let results = store.filter(item => {
			return item[fieldName]() === fieldValue;
		});
		if (results.length === 0) {
			reject(new Error("Not found in store"));
		} else {
			console.log(`💎 Found ${fieldValue} in VueX store`);
			resolve(results[0]);
		}
	});
};

const grpcFetch = function(method, req, cacheName, entityResponseName, commit) {
	return new Promise((resolve, reject) => {
		loadFromCache(cacheName, entityResponseName)
			.then(res => {
				console.log(`📦 Loading ${cacheName} from cache`);
				resolve(res);
			})
			.catch(err => {
				console.log(`🔎 Will load ${cacheName} from network`);
				loadFromNetwork(method, req)
					.then(res => {
						saveToCache(cacheName, res);
						return res;
					})
					.then(res => resolve(res))
					.catch(err => {
						console.log(err);
						reject(err);
					});
			});
	});
};

export { grpcFetch, loadSingleEntityFromStore, checkCacheVersion };
