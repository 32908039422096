//@ts-check
import {
  EventListRequest,
  EventBySlugRequest,
} from "@/services/service_app_pb";
import { EventListResponse } from "../../services/service_app_pb";
import { EventResponse } from "../../services/service_app_pb";
import { grpcFetch, loadSingleEntityFromStore } from "@/util/cache-manager.js";

function load({ state, commit }) {
  let reqEventList = new EventListRequest();
  const cacheName = "evenements";
  const methodName = "getEvents";

  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Looking up events",
    },
    { root: true },
  );
  /**** Loader ****/

  grpcFetch(
    methodName,
    reqEventList,
    cacheName,
    EventListResponse,
    commit,
  ).then((res) => {
    let entities = res.getEventsList();
    commit("setAllEvents", entities);
    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Done Looking up events",
      },
      { root: true },
    );
    /**** Loader ****/
  });
}

function getSingleEventById({ state, commit, rootState }, payload) {
  let entitySlug = rootState.route.params.id;
  let reqEvent = new EventBySlugRequest();
  const methodName = "getEventBySlug";
  const cacheName = entitySlug;

  reqEvent.setSlug(entitySlug);

  loadSingleEntityFromStore(state.allEvents, "getSlug", entitySlug, commit)
    .then((entity) => {
      commit("setCurrentEvent", { data: entity });
      commit(
        "SET_STATUS",
        { status: false, message: "Found " + entitySlug + " in store" },
        { root: true },
      );
    })
    .catch((err) => {
      /**** Loader ****/
      commit(
        "SET_STATUS",
        {
          status: true,
          message: "Looking up homepage",
        },
        { root: true },
      );
      /**** Loader ****/

      grpcFetch(methodName, reqEvent, cacheName, EventResponse, commit).then(
        (res) => {
          let event = res.getEvent();

          commit("addEvent", { data: event });
          commit("setCurrentEvent", { data: event });

          /**** Loader ****/
          commit(
            "SET_STATUS",
            {
              status: false,
              message: "Lookup of eventment done",
            },
            { root: true },
          );
        },
      );
    });
}

export default {
  load,
  getSingleEventById,
};
