<template>
  <div class="theatres__listing">
    <ul>
      <li class="theatre-item" :class="{ 'active' : theatre.selected }" @click="setFilter({ 'theatre' : theatre })" v-for="theatre in theatres">{{theatre.name}}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheatrePicker",
  computed: {
    ...mapGetters({
      theatres: "movies/getThreatresData",
    })
  },
  beforeMount() {
    this.$store.dispatch("movies/getTheatresShowtimes");
  },
  methods: {
    setFilter: function(message) {
      this.$store.commit({
        type: "movies/setTheatreDate",
        payload: message
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .theatres__listing {
    margin-bottom: 10px;

    ul {
      display: inline-block;
      margin: 0;
      padding: 0;

      .theatre-item {
        background: $color-grey;
        float: left;
        list-style: none;
        padding: 10px;
        margin-right: 10px;
        border-radius: 3px;
        box-sizing: border-box;
        border-width: 2px;
        border-color: $color-grey;
        border-style: solid;
        cursor: pointer;
        margin-bottom: $gutter/4;
        min-width: 150px;
        max-width: 150px;
        text-align: center;
        font-size: 14px;
        position: relative;
        color: rgba($color-black, 0.6);
        font-weight: 500;
        user-select: none;

        // Overflowing text

        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition: all 0.2s $ease-in-out-quad;

        &:hover {
          border-color: rgba($color-main, 0.8);
          color: rgba($color-main, 0.8);
        }

        &.active {
          color: $color-main;
          border: 2px solid $color-main;
          border-radius: 3px;
          background: $color-white;
        }

        a {
          transition: all 0.2s $ease-in-out-quad;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .theatres__listing {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .theatre-item {
          min-width: initial;
          max-width: initial;
          padding: $gutter/8 $gutter/4;
        }
      }
    }
  }

  @media (max-width: $tablet-portrait) {
    .theatres__listing {
      ul {
        grid-template-columns: 1fr 1fr;

        .theatre-item {
        }
      }
    }
  }
</style>
