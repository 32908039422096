import Vue from "vue";
import Router from "vue-router";

import Event from "./views/event/index.vue";
import Events from "./views/events/index.vue";
import Home from "./views/home/index.vue";
import Movie from "./views/movie/index.vue";
import Movies from "./views/movies/index.vue";
import Recipes from "./views/recipes/index.vue";
import Recipe from "./views/recipe/index.vue";
import Venue from "./views/venue/index.vue";
import Venues from "./views/venues/index.vue";
import Reviews from "./views/reviews/index.vue";
import NotFoundComponent from "./components/shared/NotFoundComponent";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/evenements",
      name: "Evenements",
      // component: Events
      component: () => import(/* webpackChunkName: "about" */ './views/events/index.vue'),
    },
    {
      path: "/cinema/:id",
      name: "Cinema",
      // component: Movie,
      component: () => import(/* webpackChunkName: "about" */ './views/movie/index.vue'),
      props: true
    },
    {
      path: "/evenement/:id",
      name: "Evenement",
      // component: Event
      component: () => import('./views/event/index.vue')
    },
    {
      path: "/recette/:id",
      name: "Recette",
      component: () => import(/* webpackChunkName: "about" */ './views/recipe/index.vue')
    },
    {
      path: "/venue/:id",
      name: "Venue",
      component: () => import(/* webpackChunkName: "about" */ './views/venue/index.vue')
    },
    {
      path: "/recettes",
      name: "Recettes",
      // component: Recipes
      component: () => import(/* webpackChunkName: "about" */ './views/recipes/index.vue')
    },
    {
      path: "/venues",
      name: "Venues",
      // component: Venues
      component: () => import(/* webpackChunkName: "about" */ './views/venues/index.vue')
    },
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/cinema",
      name: "cinema",
      // component: Movies
      component: () => import(/* webpackChunkName: "about" */ './views/movies/index.vue')
    },
    {
      path: "/coups-de-coeur",
      name: "revues",
      // component: Reviews
      component: () => import(/* webpackChunkName: "about" */ './views/reviews/index.vue')
    },
    {
      path: "*",
      component: NotFoundComponent
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  }
});
