import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	events: {
		featured: [],
		main: []
	},
	allEvents: [],
	event: null,
	fetched: false
};

const namespaced = true;

export default {
	namespaced,
	state,
	actions,
	getters,
	mutations
};
