import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  venues: [],
  venuesStore: [],
  venueTypes: [],
  venue: null,
  towns: [],

  currentTown: null,
  currentVenueType: null,
  currentKeyword: null,

  hasSearched: false,
  fetched: false,
  searchStatus: 0,
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
